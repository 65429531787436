<template>

  <div id="app">

    <!-- This is nav bar. -->

    <!-- Only display Navbar if route name is not Login.vue or Signup.vue and user and notiDoc are true. -->

    <!-- added 'isHome' at 4 occurneces on 9/20 -->

    <Navbar v-if="!isLogin && user && notiDoc || isAbout || isTerms || isHome || isDisplayPost" 
      :user="user" :noti-doc="notiDoc" :users-doc="usersDoc"
      @updateUsersDoc="updateUsersDoc"
      @loadProfile="loadProfile"
    />

    <!-- This is notice (error display) bar. -->

    <!-- Only display noticeBar if notice is true -->

    <div v-if="notice" id="noticeBar" role="alert">

      <h2>{{ notice }}</h2>

    </div>

    <!-- This is router view. -->

    <!-- Only display router view if route name is Login.vue or Signup.vue, or if user and userDoc are true. -->

    <!-- removed on 4/21: :profileDisplayOn="profileDisplayOn" -->
    
    <router-view v-if="user && usersDoc || isLogin || isAbout || isTerms || isHome || isDisplayPost"
      :user="user" :users-doc="usersDoc" :notice="notice" :postModalOn="postModalOn" :profilePostModalOn="profilePostModalOn" :profileInfo="profileInfo"
      @updateUser="updateUser" @updateUsersDoc="updateUsersDoc"  @notice="displayNotice" @loadProfile="loadProfile"
    />    <!-- removed: @getNotiDoc="getNotiDoc" -->

    <!-- This is footer. -->

    <!-- Only display footer when route name is not Login.vue, Signup.vue, or Upload.vue -->

    <Footer v-if="user && usersDoc && !isLogin && !isUpload || isAbout || isTerms || isHome || isDisplayPost"/> <!-- added: user && usersDoc on 4/7/21-->

  </div>

</template>

<script>

import { projectAuth } from '@/firebase/config'
import { projectFirestore } from '@/firebase/config'

import Navbar from '@/components/layout/Navbar'
import Footer from '@/components/layout/Footer'

export default {

  name: 'App',
  components: {
    Navbar,
    Footer
  },

  data() {
    return {
      notice: null,
      user: null,    
      usersDoc: null,
      notifDocRef: null,
      notiDoc: null,
      postModalOn: false,
      profilePostModalOn: false,
      profileInfo: null,
      // profileDisplayOn: false
    }
  },

  computed: {
    isLogin() {

      return this.$route.name === 'Login' || this.$route.name === 'Signup' || this.$route.name === 'VerifyEmail' || this.$route.name === 'NotFound'

    },
    isUpload() {

      return this.$route.name === 'Upload'

    },
    isAbout() {

      return this.$route.name === 'About'

    },
    isTerms() {

      return this.$route.name === 'Terms' || this.$route.name === 'Privacy'

    },
    isHome() {

      return this.$route.name === 'MGallery'

    },
    isDisplayPost() {

      return this.$route.name === 'DisplayPost'

    }
  },

  methods: {
    displayNotice (notice) {

      console.log('This is the notice to display: ', notice)

      this.notice = notice

    },
    updateUser() {

      console.log("Start updateUser in root component.")

      // user should only be null until login is complete
      // signup/login emits intitial updateUser event to root, 
      // root gets the user from auth and passes as prop to children

      if(this.user === null){

        console.log("This.user is null.")

        this.user = projectAuth.currentUser

        console.log('This is the user: ', this.user)
        
      } else {

        console.log("This.user is not null: ", this.user)

        this.user = projectAuth.currentUser.reload()

        .then(() => {

          this.user = projectAuth.currentUser

        }).catch(error => {

          console.log('There was an error reloading the current user from auth: ', error)

        })

      }

    },
    updateUsersDoc () {

      console.log("Start updateUsersDoc.")
    
      // this.user should never be null when this code runs
      // if(this.user === null){
      //   // get current user
      //   this.user = projectAuth.currentUser;
      // }

      console.log('This is the current user: ', this.user)

      if(this.user !== null){

        console.log('Signed in as: ', this.user)

        // make ref to doc and get doc
        let usersDocRef = projectFirestore.collection('users').doc(this.user.uid)
          usersDocRef.get().then(doc => {

            if(doc.exists){

              console.log('The users doc does exist.')

              this.usersDoc = doc.data()

              console.log('This is the users doc: ', this.usersDoc)

            } else {

              // ISSUE: what action to take if doc does not exist...
              // that means user is logged in but has no user doc...
              // how could this happen in real world? sign up failure?

              // route to signup/login pages
              // because if we cant access the users records we cant track/control their activity (reads/writes)

            }

          }).catch(error => {

            console.log('There was an error getting the users doc: ', error)

            // ISSUE: handle this error... 
            // try once more to get doc, then route to signup/login pages
            // because if we cant access the users records we cant track/control their activity (reads/writes)

          })

      } else {

        console.log('The user is not signed in.')

        // NEED route unauthorized user to signup/login pages... no this is done by vue router... otherwise user would bypass client code to access site

      }

    },
    loadProfile (userIdAndAlias) {

      console.log('Start loadProfile() at root component.')

      console.log('This is the userId and alias used in loadProfile at root: ', userIdAndAlias)

      if(this.user === null) {

        this.user = { readOnly: true }

      }

      // check if we have received id and alias (route from MGallery)
      // or just the alias (route from URL)

      // check if request to load profile is for current user
      // we compare id when visit is from navi menu // we compare alias when visit is from notification link
      if(userIdAndAlias.userId === this.user.uid || userIdAndAlias.userAlias === this.user.displayName){

        console.log('This is a self profile view.')

        // this request to load profile if for the current user, so we do not need to get any docs from firestore
        this.profileInfo = {

            id: this.user.uid,
            alias: this.user.displayName,
            name: this.usersDoc.name,
            bio: this.usersDoc.bio,
            location: this.usersDoc.location

          }

          console.log('This is the profileInfo: ', this.profileInfo)

      } else if(!userIdAndAlias.userId){

        console.log('We received the user alias only, this is a direct url visit.')

        // we do not have the id yet so run query for user doc

        let userDocRef = projectFirestore.collection('users').where("displayNameSlug", "==", userIdAndAlias.userAlias)

        userDocRef.get().then(userDoc => {

          //check if doc is undefined, if so route to 404 page

          if(userDoc.docs[0] !== undefined){

            console.log('This is the userDoc retreived inside loadProfile for URL visitor: ', userDoc.docs[0].data())

            let docData = userDoc.docs[0].data()

            this.profileInfo = {

              id: docData.user_id,
              alias: userIdAndAlias.userAlias,
              name: docData.name,
              bio: docData.bio,
              location: docData.location

            }

            console.log('This is the profileInfo: ', this.profileInfo)

          } else {

            this.$router.push({ name: 'NotFound', params: { catchAll: '404' }});

          }

        }).catch(error => {

          console.log('There was an error getting the userDoc: ', error)

        })

      } else {

        console.log('We received the user id and alias only, this is a click from MGallery.')

        // we have received the user id and alias so use in ref (not query)

        let userDocRef = projectFirestore.collection('users').doc(userIdAndAlias.userId)

        userDocRef.get().then(userDoc => {

          console.log('Here is the userDoc.data(): ', userDoc.data())

          let docData = userDoc.data()

          this.profileInfo = {

            id: userIdAndAlias.userId,
            alias: userIdAndAlias.userAlias,
            name: docData.name,
            bio: docData.bio,
            location: docData.location

          }

          console.log('This is the profileInfo object to send as prop to child component: ', this.profileInfo)

        }).catch(error => {

          // console.log('There was an error getting the user doc for profile: ', error)

          if(error.message === "Missing or insufficient permissions."){

            this.displayNotice('You must sign in to view profiles.')

          }

        })

      }

    }
  },
  created() {

    console.log('Start created hook in root component.')

    // use authFlag to run code on 1st auth state change only (stops repeat calls)
    // set authFlag to false on logout, so new login will retreive new notiDoc

    let authFlag = true

    projectAuth.onAuthStateChanged((user) => {

      console.log('There has been an auth state change.')

      if(user){

        console.log('The user has signed in as: ', user.uid)

        this.user = user                    
          
          if(user && authFlag){

            authFlag = false

            console.log('Setup listener for notifications.')

            // unsubscribe to this listener when user logsout (executed in Navbar.vue)

            // this.notifDocRef = projectFirestore.collection('users').doc(this.user.displayName).collection('myNotifications').doc('notifications') // changed on 2/6/21
            
            this.notifDocRef = projectFirestore.collection('users').doc(this.user.uid).collection('myNotifications').doc('notifications')
            .onSnapshot(notiDoc => {

              // console.log('This is the display name being used to query for notifications: ', this.user.displayName)
              console.log('This is the uid being used to query for notifications: ', this.user.uid)
              console.log('This is the notiDoc data: ', notiDoc.data())

              if(notiDoc.exists){

                console.log('The notifications doc does exist.')

                this.notiDoc = notiDoc.data()
                this.notiDoc.notifications = this.notiDoc.notifications.slice().reverse()

              } else {

                console.log('The notifications doc does not exist.')

                this.notiDoc = { new: false, notifications: []}

              }
            })
          }

      } else {

        if(authFlag){

          console.log('Initial auth trigger, ignore.')

        }

        if(!authFlag){

          console.log('The user has signed out: ', user)

          console.log('Or the user never signed in...')

          this.user = null       
          
          this.user = "bug"   // added 9/21

          this.notiDoc = { new: false, notifications: []}

          authFlag = true

          // ISSUE route user to sign in page

        }
        
      }

    })

  },
  unmounted() {

    console.log('Start unmounted hook from root component.')
  
  },
  watch: {
    $route (to, from){

      console.log('Root route watch: We came from: ', from)

      console.log('Root route watch: We are going to: ', to)

      // erase all notices upon routing to MGallery unless from Settings or DisplayPost

      if(from.name === 'Settings' && to.name === 'Signup' || from.name === 'DisplayPost' && to.name === 'MGallery'){

        // keep notice displayed for settings or for feedback time limits

      } else {

        // remove displayed notice

        this.notice = null

      } 
      
      // toggle this.postModalOn depending on routes from and to values

      // if(to.name === 'Profile'){

      //   console.log('we are going to a profile page now...')

      //   if(this.user === null) {

      //     this.user = { readOnly: true }

      //   }

      // }

      if(to.name === 'DisplayPost'){

        // this is an opening from MGallery

        console.log('Routing to a post, set this.postModalOn prop to true.')

        // turn modal on

        

        // if user is null, they are browsing while not signed in
        // this functionality is required so that potential users can see what everything looks like
        // we must set user to value other than null to allow DisplayPost.vue to work
        // user is a prop and its expected to be an object

        // it would be better to set this.user to this object at created loop of MGallery or below when route goes to profile??

        if(this.user === null) {

          this.user = { readOnly: true }

        }

        // ISSUE this prop 'postModalOn' is not being reactive

        this.postModalOn = true

        console.log('This is this.postModalOn: ', this.postModalOn)

      } else if(from.name === 'DisplayPost'){ // ISSUE maybe this just need to be any time from.name === 'DisplayPost' <!-- removed && to.name === 'MGallery' -->

        // this is post closed

        console.log('Closed post, turn post modal off now...')

        // turn modal on
        this.postModalOn = false

        console.log('This is this.postModalOn: ', this.postModalOn)

      }

      // if route is to profile, then turn on profileDisplayOn

      if(from.name === 'Profile' && to.name !== 'DisplayProfilePost'){

        console.log('Routing from a profile, set this.profileInfo to null.')

        this.profileInfo = null

      //   // turn profile display on
      //   this.profileDisplayOn = true

      //   console.log('This is this.profileDisplayOn: ', this.profileDisplayOn)

      }

      if(to.name === 'DisplayProfilePost'){

        // this is an opening from MGallery

        console.log('Routing to a profile post, set this.profilePostModalOn prop to true.')

        // turn modal on

        this.profilePostModalOn = true

        console.log('This is this.profilePostModalOn: ', this.profilePostModalOn)

      }

      if(from.name === 'DisplayProfilePost'){

        // this is an opening from MGallery

        console.log('Routing to a profile post, set this.profilePostModalOn prop to true.')

        // turn modal on

        this.profilePostModalOn = false

        console.log('This is this.profilePostModalOn: ', this.profilePostModalOn)

      }

      // turn off profileDisplayOn when route is from profile to any other page except DisplayPost

      // if(from.name === 'Profile' && to.name !== 'DisplayPost'){

      //   console.log('Routing from a profile, set this.profileDisplayOn prop to false.')

      //   // turn profile display off
      //   this.profileDisplayOn = false

      //   console.log('This is this.profileDisplayOn: ', this.profileDisplayOn)

      // }

      // ISSUE watch if route is from profile to mgallery
      // if so need to reload the post docs

    }

  }

}
</script>

<style>

  *, :after, :before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  *, *:before, *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  a {
    text-decoration: none;
  }

  article, aside, footer, header, nav, section {
    display: block;
  }

  body {
    margin: 0;
  }

  button, input {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    line-height: 1.15;
    overflow: visible;
  }

  button {
    margin: 0;
    text-transform: uppercase;
    -webkit-appearance: button;
  }

  button, input, optgroup, select, textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
  button, input, optgroup, select, textarea {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  }

  ::-webkit-input-placeholder { /* Edge */
    color: #d1d1d1;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:#d1d1d1;
  }

  ::placeholder {
    color: #d1d1d1;
  }

  .bold {
    font-weight: 500;
  }

  .btn {
    background-color: #9B7EDE;
    border: none;
    border-radius: 2px;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2); */
    /* box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2); */
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    height: 36px;
    letter-spacing: .5px;
    line-height: 36px;
    outline: 0;
    padding: 0 16px;
    text-align: center;
    text-transform: capitalize;
    transition: background-color .2s ease-out;
    -webkit-transition: background-color .2s ease-out;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
  }

  .btn.disabled {
    pointer-events: none;
    background-color: #ddd !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #939598 !important;
    cursor: default;
  }

  .btn:focus {
    background-color: #9B7EDE;
  }

  .btn:hover {
    background-color: #bba4ef;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .gray.btn {
    background-color: #ddd;
    color: #939598;
    width: auto;
  }

  .gray.btn:hover {
    background-color: #cdcdcd;
  }

  .close-modal {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: rgba(0,0,0,0.67);
    z-index: 4444;
    margin-top: 15px;
    margin-right: 15px;
  }

  .container {
    margin: 0 auto;
    max-width: 1280px;
    width: 90%;
  }

  fieldset {
    border: none;
    padding: 0;
  }

  h1, h2 {
    line-height: 110%;
  }

  h3 {
    color: rgba(0,0,0,0.67);
    /* font-size: 1.5em; change to larger font at larger viewport*/
    font-size: 1.4em;
    font-weight: 600;
    line-height: 1.1;
    margin: 0 0 5px 0;
  }

  html {
    line-height: 1.5;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    font-weight: normal;
    color: rgba(0,0,0,0.87);
    box-sizing: border-box;
  }

  img {
    border-style: none;
    width: 100%;
  }

  .index {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1em;
    /* margin-top: 1em; */
    margin-bottom: 2em;
  }

.inputField {
  margin-top: 1em;
  margin-bottom: 1em;
}

  input:not([type]), 
  input[type=text]:not(.browser-default), 
  input[type=password]:not(.browser-default), 
  input[type=email]:not(.browser-default), 
  input[type=url]:not(.browser-default), 
  input[type=time]:not(.browser-default), 
  input[type=date]:not(.browser-default), 
  input[type=datetime]:not(.browser-default), 
  input[type=datetime-local]:not(.browser-default), 
  input[type=tel]:not(.browser-default), 
  input[type=number]:not(.browser-default), 
  input[type=search]:not(.browser-default),
  textarea {
    /* background-color: transparent; */
    background-color: #f3f3f4;
    border: 1px solid transparent;
    /* border: none; */
    /* border-bottom: 1px solid #9e9e9e; */
    /* border-radius: 0; */
    border-radius: 6px;
    outline: none;
    height: 3rem;
    width: 100%;
    font-size: 16px;
    margin: 0 0 8px 0;
    /* padding: 0; */
    padding: 0 16px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    box-sizing: border-box;
    -webkit-transition: border .3s, -webkit-box-shadow .3s;
    transition: border .3s, -webkit-box-shadow .3s;
    transition: box-shadow .3s, border .3s;
    transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;
  }

  input:not([type]):focus:not([readonly]), 
  input[type=text]:not(.browser-default):focus:not([readonly]), 
  input[type=password]:not(.browser-default):focus:not([readonly]), 
  input[type=email]:not(.browser-default):focus:not([readonly]), 
  input[type=url]:not(.browser-default):focus:not([readonly]), 
  input[type=time]:not(.browser-default):focus:not([readonly]), 
  input[type=date]:not(.browser-default):focus:not([readonly]), 
  input[type=datetime]:not(.browser-default):focus:not([readonly]), 
  input[type=datetime-local]:not(.browser-default):focus:not([readonly]), 
  input[type=tel]:not(.browser-default):focus:not([readonly]), 
  input[type=number]:not(.browser-default):focus:not([readonly]), 
  input[type=search]:not(.browser-default):focus:not([readonly]),
  textarea:focus:not([readonly]) {
    box-shadow: none; /* delete after done with materialize */
    border-color: #9B7EDE;;
  }

  label {
    /* color: #9e9e9e; */
    color: #0d0c22;
    /* font-size: .8rem; */
    font-size: 14px;
    font-weight: 500;
    /* left: 0; */
    display: block;
    margin-bottom: 10px;
  }
  label.btn {
    margin-bottom: 0;
  }

  .left {
    float: left !important;
  }
  
.noscroll {
  overflow: hidden;
}


/* ---------- START NOTICE BAR STYLING ---------- */
#noticeBar {
  color: #fff;
  background-color: #D97AEB;
  padding: 12px;
}
#noticeBar h2 {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 auto;
  text-align: center;
}
/* ---------- END NOTICE BAR STYLING ---------- */


/* ---------- START FILTER BAR STYLING ---------- */
#filterBar {
  font-size: 1.1rem;
  color: rgba(0,0,0,.7);
}
#filterBar ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 1em 0;
}
#filterBar ul li {
  padding: 10px 16px;
  cursor: pointer;
  text-align: center;
}
#filterBar ul li:after {
  /* allows weight change on selection without shifting layout */
  display: block;
  content: attr(title);
  font-weight: 500;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
#filterBar ul li:hover {
  background: rgba(13,12,34,0.05);
  border-radius: 8px;
  color: rgba(0,0,0,1);
  font-weight: 500;
}
#filterBar ul li.selected {
  background: rgba(13,12,34,0.05);
  border-radius: 8px;
  color: rgba(0,0,0,1);
  font-weight: 500;
}

@media only screen and (min-width: 601px){
  #filterBar ul {
    margin: 2em 0;
  }
}
/* ---------- END FILTER BAR STYLING ---------- */


/* ---------- START POST CARD STYLING ---------- */
/* ------- used on MGallery and MyPosts -------- */
.postCard {
  margin: 0;
}
.postCard .postCardContent {
  position: relative;
}
.postCard .postCardContent .postCardImageContainer {
  height: 0;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 66%;
  width: 100%;
}
.postCard .postCardContent .postCardOverlay {
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 2222;
  background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,0,0,0), rgba(0,0,0,.33));
  border-radius: 8px;
  opacity: 0;
  overflow: hidden;
  transition: opacity .33s;
}
.postCard .postCardContent .postCardOverlay:hover {
  opacity: 1;
}
.postCard .postCardContent .postCardOverlay .postTitle {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  padding: 0 0 10px 10px;
  font-size: 1.5em;
  font-weight: 600;
}
.postCard .postCardInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: .4em;
}
.postCard .postCardInfo .author {
  align-items: center;
  color: #aaa;
  display: flex;
  cursor: pointer;
}
.postCard .postCardInfo .author img {
  position: absolute;
  clip-path: circle(42%);
  height: 24px;
  width: 24px;
  z-index: 1111;
}
.postCard .postCardInfo .author .authorAlias {
  align-items: center;
  color: rgba(0,0,0,1);
  display: flex;
  font-weight: 500;
  margin-left: 10px;
}
.postCard .postCardInfo .data {
  align-items: center;
  color: #aaa;
  display: flex;
}
.postCard .postCardInfo .data span {
  align-items: center;
  color: #aaa;
  display: flex;
  margin-left: 10px;
}
.postCard .postCardInfo .data span i {
  font-size: 18px;
}
/* ------- used on MGallery and MyPosts -------- */
/* ---------- END POST CARD STYLING ---------- */


  .post-modal.modalOn, .post-modal.dealModalOn {
    display: flex;
    /* removed/ or set to 0 if images need to load first, then set to 1 after load complete */
    opacity: 1;
  }

  .post-modal {
    overflow-y: scroll;
    position: fixed;
    display: none;
    opacity: 0;
    /* background-color: rgba(23, 28, 71, .95); */
    background: rgba(0,0,0,0.8);
    top: 0;
    /* bottom: 0; */
    left: 0;
    /* right: 0; */
    height: 100%;
    width: 100%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3333;
    transition: .3s ease-out;
  }

  .post-modal-content {
    position: absolute;
    /* top: 64px; */
    /* width: 70%; */
    /* margin-bottom: 64px; */
    /* padding: 30px; */
    /* border-radius: 8px; */
    background-color: #fff;
    transition: .3s ease-out;
    border-radius: 0;
    margin-bottom: 0;
    padding: 1em 0;
    top: 0;
    width: 100%;
  }

  .right {
    float: right !important;
  }

  .saveButton {
    width: 100%;
  }

  select {
    background-color: #f3f3f4;
    border: 1px solid #ddd;
    border-radius: 6px;
    display: block;
    height: 3em;
    margin-bottom: 8px;
    padding: 0 12px;
    width: 100%;
  }

  select:focus {
    border-color: #9B7EDE;
    outline: 0;
  }
  #spinner-wrapper {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* position: absolute; */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
  }
  svg:not(:root) {
    overflow: hidden;
  }

  textarea {
    /* background-color: #f3f3f4;
    border: 1px solid transparent; */
    /* border: none; */
    /* border-bottom: 1px solid #9e9e9e; */
    /* border-radius: 0; */
    /* border-radius: 6px;
    outline: none;
    width: 100%;
    font-size: 16px;
    margin: 0 0 8px 0;*/
    min-height: 100px;
    /* padding: 0; */
    padding: 12px 16px;
    /* -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    box-sizing: border-box;
    -webkit-transition: border .3s, -webkit-box-shadow .3s;
    transition: border .3s, -webkit-box-shadow .3s;
    transition: box-shadow .3s, border .3s;
    transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s; */
  }

  ul:not(.browser-default) {
    padding-left: 0;
    list-style-type: none;
  }

  ul:not(.browser-default)>li {
    list-style-type: none;
  }

  @media only screen and (min-width: 0){
    html {
      font-size: 14px;
    }
  }

  @media only screen and (min-width: 601px){
    .container {
      width: 85%;
    }
  }

  @media screen and (min-width: 632px) {
    .index {
      grid-gap: 2em;
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (min-width: 901px){
    .post-modal-content {
      /* width: 80%; */
      border-radius: 8px;
      margin-bottom: 64px;
      padding: 3em;
      top: 64px;
      width: 840px;
    }
    .close-modal {
      color: #f3f3f4;
      margin-top: 30px;
      margin-right: 30px;
    }
    .saveButton {
      width: auto;
    }
  }

  @media screen and (min-width: 962px) {
    .index {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }  

  @media only screen and (min-width: 992px){
    html {
      font-size: 14.5px;
    }
  }

  @media only screen and (min-width: 1200px){
    html {
      font-size: 15px;
    }
  }

</style>

