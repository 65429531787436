<template>

  <div id="login" class="container">
    
    <!-- <div id="loginLogo"> -->
    
    <router-link :to="{ name: 'About' }" id="loginLogo">
      Crittool
    </router-link>

    <!-- </div> -->

    <form @submit.prevent="login" class="card-panel">

      <h2>Sign in to Crittool</h2>

      <div class="field">

        <label for="email">Email</label>
        <input type="email" name="email" v-model="email" v-on:keyup="validateDuringInput" v-on:blur="validateAfterInput">

      </div>

      <div class="field">
        
        <label for="password">Password</label>
        <input type="password" name="password" v-model="password" v-on:keyup="validateDuringInput" v-on:blur="validateAfterInput">

      </div>

      <div class="field center">

        <button v-bind:class="{ disabled: this.unvalidated }" class="btn">Sign In</button>

      </div>

      <div class="field">

        <p>Forgot your password?
          <a @click="sendResetEmail">
            Send reset email.
          </a>
        </p>

        <p>Not a member yet?
          <router-link :to="{ name: 'Signup' }">
            Sign up now.
          </router-link>
        </p>
        
      </div>

    </form>

  </div>

</template>

<script>

import { projectAuth } from '@/firebase/config'

export default {
  name: 'Login',
  props: {
    notice: String
  },
  data(){
    return {
      email: '',
      password: '',
      unvalidated: true
    }
  },
    methods: {
      login(){

          if(this.email && this.password){

              projectAuth.signInWithEmailAndPassword(this.email, this.password)

              .then(() => {

                console.log('Route user to MGallery.')

                this.$router.push({ name: 'MGallery' })

              }).catch(error => {

                this.$emit('notice', error.message)

              })
              
          } else {

            this.$emit('notice', 'Please enter your email and password.')

          }

      },
      sendResetEmail() {

        if(this.email.length >= 6){

          // the email is entered now

          // ISSUE add validation of email format here... make sure they have enetered a valid email
          // see validation code below...

          if(this.notice === 'Please enter your email first.'){

            this.$emit('notice', '')

          }

          var actionCodeSettings = {
            // After password reset, the user will be give the ability to go back
            // to this page.
            url: 'https://crittool.com/login', // ISSUE this domain must be whitelisted with project 1st, need to setup FB hosting with my domain first
            handleCodeInApp: false
          };



          projectAuth.sendPasswordResetEmail(this.email, actionCodeSettings)
          .then(() => {

            console.log('The reset email has been sent.');

            this.$emit('notice', 'The password reset email has been sent. Please check your inbox.')

          }).catch(error => {

            console.log('There was an error sending the reset email: ', error);

            if(error.code === 'auth/user-not-found'){

              this.$emit('notice', 'There is no user record for this email.');

            } else {

              this.$emit('notice', 'There was an error sending the reset email. Please try again later.');

            };
            
          });

        } else {

          // the email is not entered yet

          this.$emit('notice', 'Please enter your email first.');

        };

        
      },
      validateDuringInput() {

        console.log('Start validateDuringInput() in Login.')

        console.log('This is the current notice: ', this.notice)

        // validate input characters

        if(this.email.match(/^[A-Z0-9@._%+-]+$/gi)){

          console.log('The email chars are valid.')

          if(this.notice === 'Email may not contain special characters.'){

            this.$emit('notice', '')

          }

          // validate input lengths and/or format

          if(
            this.email.length >= 6 && 
            this.email.length <= 254 && 
            this.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gi)
          ){

            console.log('The email length/format is valid.')

            // U R HERE! instead of this.notice... send notice from parent to child as prop

            if(
              this.notice === 'Please enter your email first.' || 
              this.notice === 'Email must be longer.' || 
              this.notice === 'Email must be shorter.' ||
              this.notice === 'Email is not formatted correctly.'
            ){

              this.$emit('notice', '')

            }

            if(this.password.length >= 6){

              console.log('The password is valid.')

              if(
                this.notice === 'Password must be longer.' ||
                this.notice === '' ||
                this.notice === null ||
                this.notice === 'The password is invalid or the user does not have a password.' ||
                this.notice === 'There is no user record corresponding to this identifier. The user may have been deleted.' ||
                this.notice === 'The password reset email has been sent. Please check your inbox.'
              ){

                this.$emit('notice', '')

                this.unvalidated = false

              }

              if(this.email && this.password){

                  console.log('All fields have been filled out.')

                  if(
                    this.notice === 'Please enter your email and password.' ||
                    this.notice === '' ||
                    this.notice === null
                  ){

                    this.$emit('notice', '')
    
                    this.unvalidated = false

                  }

              } else {

                console.log('The fields are not all filled out.')

                this.unvalidated = true  

              }

            } else {

              console.log('The password length is invalid.')

              this.unvalidated = true

            }

          } else {

            console.log('The email length or format is invalid.')

            this.unvalidated = true

          }

        } else {

          this.unvalidated = true

          if(this.email !== null && this.email !== ''){

            console.log('The email chars are invalid.')

            this.$emit('notice', 'Email may not contain special characters.')

          } else {

            if(this.password.length >= 6){

              console.log('The password is valid.')

              if(
                this.notice === 'Password must be longer.' ||
                this.notice === '' ||
                this.notice === null
              ){

                this.$emit('notice', '')

                // this.unvalidated = false

              }

            } else {

              console.log('The password length is invalid.')

              this.unvalidated = true

            }

          }

        }

      },
      validateAfterInput() {

        console.log('Start validationAfterInput() in Login.')

        console.log('This is the notice: ', this.notice)

        if(this.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gi)){

          if(this.email.length < 6){

            console.log('The email is too short.')

            this.$emit('notice', 'Email must be longer.')

          } else if(this.email.length > 254){

            console.log('The email is too long.')

            this.$emit('notice', 'Email must be shorter.')

          } else if(this.password !== '' && this.password.length < 6){

            console.log('The password is too short.')

            this.$emit('notice', 'Password must be longer.')

          }

        } else {

          this.unvalidated = true

          if(this.email !== null && this.email !== ''){

            console.log('The email chars are invalid.')

            this.$emit('notice', 'Email is not formatted correctly.')

          } else {

            if(this.password !== '' && this.password.length < 6){

              console.log('The password is too short.')

              this.$emit('notice', 'Password must be longer.')

            }

          }

        }

      }
    }
}
</script>

<style>
#login {
    max-width: 400px;
    margin-top: 60px;
}
#login a {
  color: #D97AEB;
  cursor: pointer;
}
#login .btn {
    width: 100%;
}
#login h2 {
    font-size: 1.5rem;
}
#login .field {
    margin-bottom: 16px;
}
#login p {
  text-align: center;
}

/* ---------- START LOGO STYLING ---------- */
#loginLogo {
  color: rgba(66, 54, 94, 1);
  display: inline-block;
  font-size: 2.1rem;
  font-weight: 700;
  font-style: normal;
  padding: 0;
}
#loginLogo:hover {
  color: rgba(66, 54, 94, .8);
}
/* ---------- END LOGO STYLING ---------- */
</style>