<template>

  <div id="navBar">

    <nav class="container">

        <!-- this is logo / homepage link -->
        <!-- if route was a profile page when this was clicked, reload the user posts-->
        <router-link :to="{ name: 'MGallery' }" id="logo">
          Crittool
        </router-link>

        <!-- this is folded menu -->
        <ul id="foldedNav">

          <!-- this is folded notification icon -->
          <li v-if="user !== null && user.uid" id="foldedNotificationsIcon" @mouseover="notifUnfolded = true" @mouseleave="notifUnfolded = false" class="foldedNavItem">
            <a><i class="material-icons">poll</i></a>
          </li>

          <!-- this is folded notification list -->
          <ul v-if="notiDoc" id="foldedNotificationsList" @mouseover="notifUnfolded = true" @mouseleave="notifUnfolded = false" v-bind:class="{ notifUnfolded: notifUnfolded }">
            <li v-for="(noti, index) in notiDoc.notifications" :key="index">
              <i class="material-icons">check_box</i>
              <p><span>{{ noti.commentorAlias }} </span> critiqued <a @click.prevent="openPostNotification" :data-postID="noti.postID">{{ noti.postTitle }}</a>. </p>
            </li>
          </ul>

          <!-- this is folded menu/hamburger icon -->
          <li id="foldedMenuIcon" @mouseover="menuUnfolded = true" @mouseleave="menuUnfolded = false" class="foldedNavItem" >
            <a><i class="material-icons">menu</i></a>
          </li>
            
          <!-- this is folded menu list -->
          <ul id="foldedMenuContent" @mouseover="menuUnfolded = true" @mouseleave="menuUnfolded = false" v-bind:class="{ menuUnfolded: menuUnfolded }">
            <li v-if="user !== null && user.uid" @click="menuUnfolded = false"><router-link :to="{ name: 'Upload' }">Upload</router-link></li>
            <li @click="menuUnfolded = false"><router-link :to="{ name: 'MGallery' }">Explore</router-link></li>
            <li v-if="!user || user !== null && user.readOnly === true" @click="menuUnfolded = false"><router-link :to="{ name: 'Signup' }">Signup</router-link></li>
            <li v-if="!user || user !== null && user.readOnly === true" @click="menuUnfolded = false"><router-link :to="{ name: 'Login' }">Login</router-link></li>
            <li v-if="user !== null && user.uid" @click="menuUnfolded = false, $emit('loadProfile', {userId: user.uid})"><router-link :to="{ name: 'Profile', params: { displayName: user.displayName } }">Profile</router-link></li>
            <!-- <li v-if="user" @click="menuUnfolded = false"><router-link :to="{ name: 'Profile', params: { displayName: user.displayName } }">Profile</router-link></li> -->
            <li v-if="user !== null && user.uid" @click="menuUnfolded = false"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>                          
            <li v-if="user !== null && user.uid" @click="menuUnfolded = false"><a @click="logout">Sign Out</a></li>
          </ul>

        </ul>

        <!-- this is extended menu -->
        <ul id="extendedNav">

          <li v-if="user !== null && user.uid" class="extendedNavItem"><router-link :to="{ name: 'Upload' }" class="extendedNavItemLink">Upload</router-link></li>
          <li class="extendedNavItem"><router-link :to="{ name: 'MGallery' }" class="extendedNavItemLink">Explore</router-link></li><!-- removed on 9/22: v-if="user !== null && user.uid" -->
          <li v-if="!user || user !== null && user.readOnly === true" class="extendedNavItem"><router-link :to="{ name: 'Signup' }" class="extendedNavItemLink">Signup</router-link></li>
          <li v-if="!user || user !== null && user.readOnly === true" class="extendedNavItem"><router-link :to="{ name: 'Login' }" class="extendedNavItemLink">Login</router-link></li>
          
          <!-- this is notification icon -->
          <li v-if="user && this.notiDoc" id="notificationIcon" @mouseover="notifDropped = true" @mouseleave="notifDropped = false" v-bind:class="{ newNoti: this.notiDoc.new }" class="extendedNavItem">

            <a class="extendedNavItemLink"><i class="material-icons">poll</i></a>
            
            <!-- this is notification list -->
            <ul v-if="notiDoc.notifications.length >= 1" id="notificationsList" v-bind:class="{ notifDropped: notifDropped }">
              <li v-for="(noti, index) in notiDoc.notifications" :key="index">
                <i class="material-icons">check_box</i>
                <p><span>{{ noti.commentorAlias }} </span> critiqued <a @click.prevent="openPostNotification" :data-postID="noti.postID">{{ noti.postTitle }}</a>. </p>
              </li>
            </ul>

          </li>

          <!-- this is dropDownMenu -->
          <li v-if="user !== null && user.uid" id="dropDownMenuIcon" @mouseover="menuDropped = true" @mouseleave="menuDropped = false" class="extendedNavItem">
            
            <a class="extendedNavItemLink">
              <img v-if="this.user.photoURL" :src="this.user.photoURL"/>
              <i v-if="!this.user.photoURL" class="material-icons">account_circle</i>
            </a>

            <!-- this is dropDownMenu items -->
            <ul id="dropDownMenu" v-bind:class="{ menuDropped: menuDropped }" >
              <li v-if="user" class="dropDownMenuItem" @click="menuDropped = false, $emit('loadProfile', {userId: user.uid})"><router-link :to="{ name: 'Profile', params: { displayName: user.displayName } }">Profile</router-link></li>
              <li v-if="user" class="dropDownMenuItem" @click="menuDropped = false"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>                          
              <li v-if="user" class="dropDownMenuItem" @click="menuDropped = false"><a @click="logout">Sign Out</a></li>
            </ul>

          </li>
          
        </ul>
      
    </nav>

    <!-- display selected post from notifications in OpenPost component (modal) -->
    <!-- <div v-if="modalOn" id="post-modal" v-bind:class="{ modalOn: modalOn }" class="post-modal" @click.self="closeModal">
      <i @click.self="closeModal" class="material-icons close-modal">close</i>
      <div class="post-modal-content">
        <OpenPost :user="user"/>
      </div>
    </div> -->
    
  </div>
</template>

<script>

import { projectAuth } from '@/firebase/config'
import { projectFunctions } from '@/firebase/config'

import Spinner from '@/components/layout/Spinner'

export default {
  name: 'Navbar',
  components: {
    Spinner
    // OpenPost
  },
  props: {
    user: Object,
    // usersDoc: Object,
    notiDoc: Object,
    // profileInfo: Object
  },

  // ISSUE 2. setup watcher on profileInfo prop

  // watch: {

  //   // when 'profileInfo' changes, route to 'Profile.vue' using 'alias' from 'profileInfo' as route param

  //   usersDoc: {

  //     handler: function(newVal, oldVal) {

  //       console.log('The usersDoc prop value was: ', oldVal)

  //       console.log('The usersDoc prop value has changed to: ', newVal)

  //       // route to profile (which will have access to the profileInfo prop

  //       // this.$router.push({ name: 'Profile', params: { displayName: newVal.alias}});
        
  //     },

  //     deep: true

  //   }

  // },

  data(){
    return {
      // authorAlias: this.user.displayName,         // used by OpenPost.vue to display author at 'by line'

      menuDropped: false,                         // controls display of drop down menu in extended menu
      notifDropped: false,                        // controls display of drop down notifications in extended menu
      menuUnfolded: false,                        // controls display of drop dowm menu in folded menu
      notifUnfolded: false,                       // controls display of drop down notifications in folded menu 

      notifDocRef: null,                          // ref to users notification doc ***call as function to unsubscribe to listener on this doc***
    }
  },
  methods: {
   
    logout(){
      projectAuth.signOut().then(() => {
        console.log('You have signed out.')
        this.$router.push({ name: 'Login' })
        .then(() => {
        })
        .catch(error => {
          console.log('There was an error routing to login after sign out: ', error)
        })
      }).catch(error => {
        console.log('There was an error during sign out: ', error)
      })
    },
    openPostNotification(e){
      console.log('Start openPostNotification.')

      // turn body scrolling off while OpenPost modal is open
      // const body = document.getElementsByTagName("BODY")[0]
      // body.classList.add("noscroll")
      // get post id and turn modal on
      // this.openPostId = e.target.getAttribute('data-postID')
      // this.modalOn = true

      // update notification status

      if(this.notiDoc.new){

        this.notiDoc.new = false;

        console.log('Call cloud function to update notification status.');

        let updateNotificationsStatus = projectFunctions.httpsCallable('updateNotificationsStatus');

        updateNotificationsStatus({
        }).then(() => {

          console.log('The notification doc has been updated, new is now false.');

        }).catch(error => {

          console.log('There was an error updating the notification doc: ', error);

        });

      };

      // remove css that styles notification icon to indicate 'new' status is true 

      let notifIcon = document.getElementById('notificationIcon');

      if(notifIcon.classList.contains('newNoti')){

        notifIcon.classList.remove('newNoti');

      };

      // route to post

      let openPostId = e.target.getAttribute('data-postID')//.slice(0, -7);

      console.log('This is the post id to open: ', openPostId)

      // ISSUE emit loadProfile here... only if we are not displaying current users profile info...
      
      console.log('This is the current route at Navbar.vue openPostNotification(): ', this.$route)

      if(this.$route.name === 'Profile' && this.$route.params.displayName !== this.user.displayName){

        console.log('The current route is Profile.vue but profile is not for current user, emit loadProfile event to root.')

        this.$emit('loadProfile', {userId: this.user.uid})

      } else {

        console.log('The current route is not Profile or is Profile but is self profile.')

      }
      // this.$emit('loadProfile', {userId: this.user.uid}) // ISSUE do we want to emit the alias or the id? id when possible

      this.$router.push({ name: 'DisplayProfilePost', params: { displayName: this.user.displayName.toLowerCase(), displayProfilePostId: openPostId}})

    },
    // closeModal(){

    //   // turn body scrolling back on when OpenPost modal is closed
    //   const body = document.getElementsByTagName("BODY")[0]
    //   body.classList.remove("noscroll")

    //   // turn modal off
    //   // this.modalOn = false
    //   // reset doc data
    //   // this.openPostId = null

    // }
  },
  created(){

    console.log('This is the notiDoc data passes as prop from root component: ', this.notiDoc)
    console.log('This is the user data passed as prop from root component: ', this.user)

    // NEED will not ever be null, because when root component gets user, it checks for null, and signs out if so
    // so can get rid of the if check below

    // NEED actually it will be null if user refreshes page...
    // because event to updateUser is only emitted from signup/login

    // so if we have null here, we know a refresh happen...
    // because if user is null when updateUser gets it, it signs current user out and reroutes to main


    if(this.user !== null){ // NEED to determine if we need this if check... 
                            // in other words... will this code even run if user is null

      console.log('Emit updateUsersDoc event from Navbar.vue created hook.')

      this.$emit('updateUsersDoc')

    } else {

      console.log('The user is not signed in.')

      // NEED route unauthorized user to signup/login pages

    }

  },
  unmounted() {

    console.log('Start unmounted hook from Navbar.')

    // unsubscribe listener on notifications doc
    if(this.$parent.notifDocRef){
      this.$parent.notifDocRef()
    }
    // this.$parent.notifDocRef()

  }

}
</script>

<style>


/* ---------- START NAVBAR STYLING ---------- */
#navBar {
  border-bottom: 1px solid #ddd;
}
#navBar nav {
  align-items: center;
  background-color: rgba(0,0,0,0) !important;
  display: flex;
  height: 56px;
  justify-content: space-between;
  line-height: 56px;
}
#navBar nav i.material-icons {
  height: 56px;
  line-height: 56px;
}
/* ---------- END NAVBAR STYLING ---------- */


/* ---------- START LOGO STYLING ---------- */
#navBar nav #logo {
  color: rgba(66, 54, 94, 1);
  display: inline-block;
  font-size: 2.1rem;
  font-weight: 700;
  font-style: normal;
  padding: 0;
}
#navBar nav #logo:hover {
  color: rgba(66, 54, 94, .8);
}
/* ---------- END LOGO STYLING ---------- */


/* ---------- START EXTENDED NAV STYLING ---------- */
#navBar nav i {
  color: #6e6d7a;
  cursor: pointer;
}
#navBar nav #extendedNav {
  display: none;
  margin: 0;
}
#navBar nav #extendedNav li.extendedNavItem {
  position: relative;
  display: flex;
  justify-content: center;
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  float: left;
  padding: 0;
}
#navBar nav #extendedNav li.extendedNavItem a.extendedNavItemLink {
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  font-size: 1.2rem;
  color: rgba(0,0,0,.7);
  padding: 0 15px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#navBar nav #extendedNav li.extendedNavItem a.extendedNavItemLink:hover {
  background: rgba(13,12,34,0.05);
  color: rgba(0,0,0,1);
}
#navBar nav #extendedNav li.extendedNavItem a.extendedNavItemLink img {
  clip-path: circle(42%);
  height: 24px;
  width: auto;
}
/* ---------- END EXTENDED NAV STYLING ---------- */


/* ---------- START FOLDED NAV STYLING ---------- */
#navBar nav #foldedNav {
  margin: 0;
}
#navBar nav #foldedNav li.foldedNavItem {
  position: relative;
  display: flex;
  justify-content: center;
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  float: left;
  padding: 0;
}
#navBar nav #foldedNav li.foldedNavItem a {
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  font-size: 1.2rem;
  color: rgba(0,0,0,.7);
  padding: 0 15px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#navBar nav #foldedNav li.foldedNavItem a:hover {
  background: rgba(13,12,34,0.05);
  color: rgba(0,0,0,1);
}
#navBar nav #foldedNav li.foldedNavItem a i {
  color: #6e6d7a;
  cursor: pointer;
}
#navBar nav #foldedMenuContent {
  position: absolute;
  z-index: 2244;
  background-color: #fff;
  width:200px;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  top: 56px;
  right: 20px;
  opacity: 0;
  -webkit-box-shadow: 0px 10px 50px rgba(0,0,0,0.3);
  box-shadow: 0px 10px 50px rgba(0,0,0,0.3);
  visibility: hidden;
}
#navBar nav #foldedMenuContent.menuUnfolded {
  opacity: 1;
  visibility: visible; 
}
#navBar nav #foldedMenuContent li {
  position: relative;
  display: flex;
  justify-content: center;
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  float: left;
  padding: 0;
  float: none;
  border-bottom: 1px solid #ddd;
}
#navBar nav #foldedMenuContent li:last-of-type {
  border-bottom: none
}
#navBar nav #foldedMenuContent li a {
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  font-size: 1.2rem;
  color: rgba(0,0,0,.7);
  padding: 0 15px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#navBar nav #foldedMenuContent li a:hover {
  background: rgba(13,12,34,0.05);
  color: rgba(0,0,0,1);
}
/* ---------- END FOLDED NAV STYLING ---------- */


/* ---------- START NOTIFICATION LIST STYLING ---------- */
#navBar nav #notificationIcon.newNoti i {
  color:#9B7EDE;
}
#navBar nav #notificationsList,
#navBar nav #foldedNotificationsList {
  position: absolute;
  z-index: 2244;
  background-color: #fff;
  width:280px;
  border-radius: 8px;
  padding: 16px;
  top: 56px;
  right: 0;
  opacity: 0;
  -webkit-box-shadow: 0px 10px 50px rgba(0,0,0,0.3);
  box-shadow: 0px 10px 50px rgba(0,0,0,0.3);
  visibility: hidden;
  transition: .3s ease-out;
}
#navBar nav #foldedNotificationsList {
  right: 20px;
}
#navBar nav #notificationsList.notifDropped, 
#navBar nav #foldedNotificationsList.notifUnfolded {
  opacity: 1;
  visibility: visible;
  transition: .3s ease-out;
}
#navBar nav #notificationsList li,
#navBar nav #foldedNotificationsList li {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
  color: rgba(0,0,0,.7);
  padding: 0 .5em;
  position: relative;
  transition: background-color .3s;
  float: left;
  font-size: 14px;
}
#navBar nav #notificationsList li:hover, 
#navBar nav #foldedNotificationsList li:hover{
  background: rgba(13,12,34,0.05);
  color: rgba(0,0,0,1);
}
#navBar nav #notificationsList li:last-of-type,
#navBar nav #foldedNotificationsList li:last-of-type {
  border-bottom: none
}
#navBar nav #notificationsList li i,
#navBar nav #foldedNotificationsList li i {
  margin-right: 10px;
  color:#9B7EDE;
  font-size: 18px;
  cursor: pointer;
}
#navBar nav #notificationsList li p,
#navBar nav #foldedNotificationsList li p {
  line-height: 22px;
  margin: 0;
}
#navBar nav #notificationsList li p a,
#navBar nav #foldedNotificationsList li p a {
  cursor: pointer;
  font-weight: 500;
}
/* ---------- END NOTIFICATION LIST STYLING ---------- */


/* ---------- START DROPDOWN MENU STYLING ---------- */
#navBar nav #dropDownMenu {
  position: absolute;
  z-index: 5555;
  background-color: #fff;
  width:200px;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  top: 56px;
  right: 0;
  opacity: 0;
  -webkit-box-shadow: 0px 10px 50px rgba(0,0,0,0.3);
  box-shadow: 0px 10px 50px rgba(0,0,0,0.3);
  visibility: hidden;
  transition: .3s ease-out;
  border: 1px solid #f3f3f4;
}
#navBar nav #dropDownMenu.menuDropped {
  opacity: 1;
  visibility: visible;
  transition: .3s ease-out;
}
#navBar nav #dropDownMenu li.dropDownMenuItem {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  color: rgba(0,0,0,.7);
  transition: background-color .3s;
  float: left;
  padding: 0;
  text-align: center;
}
#navBar nav #dropDownMenu li.dropDownMenuItem:last-of-type {
  border-bottom: none
}
#navBar nav #dropDownMenu li.dropDownMenuItem a {
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  font-size: 1.2rem;
  color: rgba(0,0,0,.7);
  padding: 0 15px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#navBar nav #dropDownMenu li.dropDownMenuItem a:hover {
  background: rgba(13,12,34,0.05);
  color: rgba(0,0,0,1);
}


  @media only screen and (min-width: 601px){
    #navBar nav {
        height: 64px;
        line-height: 64px;
    }
    #navBar nav ul#extendedNav {
      display: flex;
    }
    #navBar nav ul#foldedNav {
      display: none;
    }
    #navBar nav #notificationsList, #navBar nav #foldedNotificationsList, #navBar nav #dropDownMenu {
      top: 64px;
    }
  }
  

</style>