<template>
  <!-- this is upload container -->
  <div id="upload" class="container">
    
    <div id="uploadContent">

      <!-- this is drop zone -->
      <div id="dropZone"
      v-on:dragenter.prevent.stop="highlight"
      v-on:dragover.prevent.stop="highlight"
      v-on:dragleave.prevent.stop="unhighlight"
      v-on:drop.prevent.stop="handleDrop">
        
        <!-- drop zone instructions / file browse link -->
        <div id="dropZoneInstructions">

          <i class="material-icons">cloud_upload</i>
          <span class="bold">Drag and drop up to 2 files</span>
          <span>or <label for="fileUpload" id="fileBrowseLink">Browse</label> to upload</span>

          <span class="directions"><span class="modeBadge">solo</span> Upload one version of your work for critique.</span>
          <span class="directions"><span class="modeBadge">versus</span> Upload two versions of your work for comparison.</span>

          <div id="uploadTotals">
            <span class="directions">{{ dailyUploads }} of 4 uploads available today.</span>
            <span class="directions">{{ monthlyUploads }} of 20 uploads available this month.</span>
          </div>
          
        </div>

        <!-- file input form -->
        <form>
          <div class="field ">
            <input id="fileUpload" type="file"  @change="getFilesToPost" multiple accept=".jpg,.png">     <!-- ISSUE add code on server side to accept only jpg or png -->
          </div>
        </form>

        <!-- this is error feedback -->
        <!-- ISSUE need to review code to see if this is still in use and for what cases? -->
        <p v-if="feedback" class="error-feedback red-text">{{ feedback }}</p>

        <!-- thumbnail previews display here -->
        <div id="imageStage">
          <output id="thumbList"></output>
        </div>

      </div>

      <!-- this is post form -->
      <div id="postFormContainer">
          <form id="postForm">

            <!-- these are post inputs -->
            <div id="postInputs">

              <!-- this is post title -->
              <div id="postTitleInput">
                <div class="inputField">
                  <fieldset>
                    <label for="enterPostTitle">Enter a Title</label>
                    <input v-on:keyup="validateDuringInput" v-on:blur="validateAfterInput" v-model="postTitle" id="enterPostTitle" placeholder="Add a descriptive title." type="text">
                  </fieldset>
                </div>
              </div>

              <!-- this is post description -->
              <div id="postDescInput">
                <div class="inputField">
                  <fieldset>
                    <label for="enterPostBrief">Enter a Critique Brief</label>
                    <textarea v-on:keyup="validateDuringInput" v-on:blur="validateAfterInput" v-model="postDesc" id="enterPostBrief" placeholder="Tell us about this work and let us know if you're looking for feedback on anything specific."></textarea>
                  </fieldset>
                </div>
              </div>

              <!-- this is post catagory -->
              <div id="postCatInput">
                <div class="inputField">
                  <fieldset>
                    <label for="postCatagories">Choose a Category</label>
                    <select name="catagories" id="postCatagories" v-model="postCat">
                      <option value="art">Art</option>
                      <option value="grd">Graphic Design</option>
                      <option value="ill">Illustration</option>
                      <option value="uix">UI/UX</option>
                      <option value="web">Web Design</option>
                    </select>
                  </fieldset>
                </div>
              </div>

            </div>

            <!-- this is concept / critique mode selection -->
            <div id="conceptSelectionContainer"> 
                                              
              <div id="conceptSelection">
                <fieldset>

                  <!-- this is critique mode selection -->
                  <label for="critiqueModeSelection">Critique Mode</label>
                  <div id="critiqueModeSelection">
                    
                    <div class="badgeContainer">
                      <div v-bind:class="{ enabled: !focusedFeedback }" class="modeBadge">broad</div>
                      <div class="toolTip"><p>All elements and principles available for critique.</p></div>
                    </div>

                    <label class="switch">
                      <input type="checkbox" checked @click="feedbackSwitch">
                      <span class="slider"></span>
                    </label>

                    <div class="badgeContainer">
                      <div v-bind:class="{ enabled: focusedFeedback }" class="modeBadge">focused</div>
                      <div class="toolTip"><p>Limited elements and principles available for critique.</p></div>
                    </div>
                  </div>

                  <!-- this is critique mode directions -->
                  <span v-if="!focusedFeedback" class="directions">Allow broad feedback on all principles and elements.</span>
                  <span v-bind:class="{ focusedFeedbackEnabled: focusedFeedback }" class="directions focusedFeedbackDisabled">Limit feedback by selecting up to 3 principles or elements to focus on.</span>
                  
                  <!-- these are design principle concepts -->
                  <div v-bind:class="{ focusedFeedbackEnabled: focusedFeedback }" class="concepts focusedFeedbackDisabled">
                    <label for="designPrinciples" class="active">Design Principles</label>
                    <div id="designPrinciples">
                      <div v-for="(tag, index) in feedbackIcons.slice(0, 7)" :key="index" :id="tag.name" class="conceptSelectionButton" @click="selectTags">
                        <div class="conceptIcon" v-html="tag.svg"></div>
                        <span class="conceptName">{{ tag.name }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- these are art element concepts -->
                  <div v-bind:class="{ focusedFeedbackEnabled: focusedFeedback }" class="concepts focusedFeedbackDisabled">
                    <label for="artElements" class="active">Art Elements</label>
                    <div id="artElements">
                      <div v-for="(tag, index) in feedbackIcons.slice(7)" :key="index" :id="tag.name" class="conceptSelectionButton" @click="selectTags">
                        <div class="conceptIcon" v-html="tag.svg"></div>
                        <span class="conceptName">{{ tag.name }}</span>
                      </div>
                    </div>
                  </div>

                </fieldset>
              </div>
            </div>

          </form>
      </div>

    </div>

    <!-- this is the upload footer -->
    <footer>
      <!-- this is progress bar -->
      <progress v-bind:class="{ progressOn: uploading }" value="0" max="100" id="uploadProgress" class="progressBar">0%</progress>
      <!-- these are upload buttons -->
      <div class="container">
        <router-link :to="{ name: 'MGallery' }" tag="button" class="gray btn">Cancel</router-link>
        <button class="btn" v-bind:class="{ disabled: unvalidated }" @click="uploadPost">Upload Post</button>
      </div>
    </footer>

    <!-- this is dealed post modal -->
    <div v-if="modalOn" id="dealedPostModal" v-bind:class="{ modalOn: modalOn }" class="post-modal" @click.self="closeModal">
      
      <i @click.self="closeModal" class="material-icons close-modal">close</i>
      
      <div class="post-modal-content">

        <!-- dealed post is displayed here -->      
        <DealPost :dealedPost="dealedPost" :user="user" @notice="emitNotice"/>
      
      </div>
    
    </div>

    <!-- this is deal notice modal -->
    <div v-if="dealModalOn" id="dealModal" v-bind:class="{ dealModalOn: dealModalVisible }" class="post-modal">
      <div id="dealModalContent">
        <div id="dealModalHeader">
          <!-- <h1>All you need is love.</h1> -->
          <i class="material-icons">favorite_border</i>
        </div>
        <div id="dealModalBody">          
          <p>Please critique this work to complete your upload.</p>
          <button class="btn" @click="closeDealModal">okay</button>
        </div>
        
      </div>
    </div>

    <!-- this is the loader animation -->
    <div v-if="showSpinner" id="spinner-wrapper">
      <Spinner />  
    </div> 

  </div>
  
</template>

<script>

import { projectFunctions } from '@/firebase/config'
import { projectFirestore } from '@/firebase/config'
import { projectStorage } from '@/firebase/config'

import Spinner from '@/components/layout/Spinner'
import DealPost from '@/components/posts/DealPost'

export default {
  name: 'Upload',
  components: {
    Spinner,
    DealPost
  },
  props: {
    user: Object,
    usersDoc: Object,
    notice: String
  },
  data(){
    return {
      dealModalVisible: false,
      dealModalOn: false,
      // dealedPost: true,
      dealedPost: null,           // sent to DisplayPost as a prop (used to display the dealed doc at DisplayPost.vue)
      // openPostId: null,
      // dealedPostAuthorImage: null,
      modalOn: false,
      showSpinner: false,
      unvalidated: true,
      uploading: false,
      // paused: false,
      // uploadsComplete: 0,
      droppedFiles: [],
      files: [],
      fileNames: [],
      // alias: null,
      postCat: 'art',
      postId: null,
      postTitle: null,
      focusedFeedback: true,
      narrowedTags: [],
      postDesc: null,
      feedback: null,
      feedbackIcons: [
        { name: 'balance', svg: '<svg class="feedbackIcons" id="icon-balance" data-name="Balance" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Balance</title><rect class="cls-1" width="32" height="32" rx="4" ry="4"/><polygon class="cls-2" points="24 32 8 32 16 24 24 32"/><circle class="cls-3" cx="16" cy="15" r="9"/></svg>' },
        { name: 'contrast', svg: '<svg class="feedbackIcons" id="icon-contrast" data-name="Contrast" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Contrast</title><rect class="cls-1" width="32" height="32" rx="4" ry="4"/><path class="cls-2" d="M4,0H16a0,0,0,0,1,0,0V32a0,0,0,0,1,0,0H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z"/><circle class="cls-3" cx="16" cy="16" r="9"/><path class="cls-2" d="M25,16a9,9,0,0,1-9,9V7A9,9,0,0,1,25,16Z"/></svg>' },
        { name: 'emphasis', svg: '<svg class="feedbackIcons" id="icon-emphasis" data-name="Emphasis" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Emphasis</title><rect class="cls-1" width="32" height="32" rx="4" ry="4"/><circle class="cls-2" cx="16" cy="14" r="8"/><rect class="cls-3" x="8" y="24" width="16" height="4"/></svg>' },
        { name: 'movement', svg: '<svg class="feedbackIcons" id="icon-movement" data-name="Movement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Movement</title><rect class="cls-1" width="32" height="32" rx="4" ry="4"/><path class="cls-2" d="M23.2,18.16a1.22,1.22,0,0,0-.59,1.42c.37,1.22.32,2.22-.25,2.79s-1.57.63-2.79.25a1.22,1.22,0,0,0-1.42.59C17.56,24.33,16.81,25,16,25s-1.56-.67-2.16-1.8a1.22,1.22,0,0,0-1.42-.59c-1.22.37-2.22.32-2.79-.25S9,20.8,9.38,19.57a1.22,1.22,0,0,0-.59-1.42C7.67,17.56,7,16.81,7,16s.67-1.56,1.8-2.16a1.22,1.22,0,0,0,.59-1.42c-.37-1.22-.32-2.22.25-2.79S11.2,9,12.43,9.38a1.22,1.22,0,0,0,1.42-.59C14.44,7.67,15.19,7,16,7s1.56.67,2.16,1.8a1.22,1.22,0,0,0,1.42.59c1.22-.37,2.22-.32,2.79.25s.63,1.57.25,2.79a1.22,1.22,0,0,0,.59,1.42c1.13.6,1.8,1.35,1.8,2.16S24.33,17.56,23.2,18.16Z"/></svg>' },
        { name: 'pattern', svg: '<svg class="feedbackIcons" id="icon-pattern" data-name="Patterns" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Pattern</title><rect class="cls-1" width="32" height="32" rx="4" ry="4"/><circle class="cls-2" cx="8" cy="25" r="2"/><circle class="cls-2" cx="16" cy="25" r="2"/><circle class="cls-2" cx="24" cy="25" r="2"/><circle class="cls-2" cx="8" cy="7" r="2"/><circle class="cls-2" cx="16" cy="7" r="2"/><circle class="cls-2" cx="24" cy="7" r="2"/><circle class="cls-2" cx="8" cy="16" r="2"/><circle class="cls-2" cx="16" cy="16" r="2"/><circle class="cls-2" cx="24" cy="16" r="2"/></svg>' },
        { name: 'rhythm', svg: '<svg class="feedbackIcons" id="icon-rhythm" data-name="Rhythm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Rhythm</title><rect class="cls-1" width="32" height="32" rx="4" ry="4"/><circle class="cls-2" cx="16" cy="16" r="11"/><circle class="cls-3" cx="16" cy="16" r="9"/><circle class="cls-4" cx="16" cy="16" r="7"/><circle class="cls-3" cx="16" cy="16" r="5"/><circle class="cls-2" cx="16" cy="16" r="3"/></svg>' },
        { name: 'unity', svg: '<svg class="feedbackIcons" id="icon-unity" data-name="Unity" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Unity</title><rect class="cls-1" width="32" height="32" rx="4" ry="4"/><circle class="cls-2" cx="20" cy="16" r="7"/><circle class="cls-2" cx="12" cy="16" r="7"/><path class="cls-3" d="M19,16a7,7,0,0,1-3,5.74,7,7,0,0,1,0-11.48A7,7,0,0,1,19,16Z"/><path class="cls-3" d="M19,16a7,7,0,0,1-3,5.74,7,7,0,0,1,0-11.48A7,7,0,0,1,19,16Z"/></svg>' },
        { name: 'line', svg: '<svg class="feedbackIcons" id="icon-line" data-name="Line" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Line</title><rect class="cls-1" width="32" height="32" rx="4"/><line class="cls-2" x1="8.63" y1="23.38" x2="23.38" y2="8.63"/></svg>' },
        { name: 'shape', svg: '<svg class="feedbackIcons" id="icon-shape" data-name="Shape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Shape</title><rect class="cls-1" width="32" height="32" rx="4"/><circle class="cls-2" cx="16" cy="16" r="9"/><polygon class="cls-1" points="16 6.1 7.34 21.1 24.66 21.1 16 6.1"/></svg>' },
        { name: 'form', svg: '<svg class="feedbackIcons" id="icon-form" data-name="Form" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Form</title><rect class="cls-1" width="32" height="32" rx="4"/><circle class="cls-2" cx="16" cy="16" r="9"/><path class="cls-1" d="M20.88,11.35a2.33,2.33,0,0,1-.11.31,31.88,31.88,0,0,1-2.6,5.59c-.35.61-.71,1.2-1.07,1.76a28.66,28.66,0,0,1-2.69,3.55c-1.09,1.18-1.95,1.78-2.61,1.8a1.64,1.64,0,0,1-.06-1l0,0c.42.08,2-1.25,4-4.1.5-.73,1-1.57,1.58-2.52a30.52,30.52,0,0,0,2.57-5.51c.48-1.46.57-2.39.38-2.6h0a.71.71,0,0,0-.3.09,4.47,4.47,0,0,1,1.25-.5C21.45,8.83,21.35,9.86,20.88,11.35Z"/><path class="cls-3" d="M17.25,13.83a31.64,31.64,0,0,0-5.9-2.71c-2-.63-3.16-.59-3.58.13a1,1,0,0,0-.1.74c.24,1.24,2.17,2.92,4.21,4.35a2.48,2.48,0,0,0,.44-.91c-2.58-1.83-3.78-3.28-3.69-3.67l.06,0a.86.86,0,0,1,.37-.06c1.06,0,3.87.84,7.69,3A26.25,26.25,0,0,1,22.59,19c.56.59.81,1,.77,1.22-.32.28-2.22-.05-5.14-1.4a3.1,3.1,0,0,0-.74.75,25.67,25.67,0,0,0,3.17,1.28,7.68,7.68,0,0,0,2.26.44,2.11,2.11,0,0,0,.7-.1,1.07,1.07,0,0,0,.62-.47C25.21,19,20.31,15.6,17.25,13.83Z"/><path class="cls-1" d="M18.22,18.85a3.1,3.1,0,0,0-.74.75c-.89-.42-1.81-.9-2.73-1.43S12.87,17,11.88,16.34a2.48,2.48,0,0,0,.44-.91c.83.59,1.81,1.22,2.93,1.87S17.32,18.44,18.22,18.85Z"/><path class="cls-3" d="M20.75,7.77C20,7.36,19,7.91,17.59,9.44a31.54,31.54,0,0,0-3.76,5.31,31.64,31.64,0,0,0-2.71,5.9c-.63,2-.59,3.16.13,3.58a1,1,0,0,0,.51.13h0a1.64,1.64,0,0,1-.06-1c-.33-.5.37-3.6,3-8.09,2.08-3.61,4-5.71,5-6.37l.23-.15a4.47,4.47,0,0,1,1.25-.5A1,1,0,0,0,20.75,7.77Z"/><path class="cls-1" d="M12.32,15.43a2.48,2.48,0,0,1-.44.91c-2-1.43-4-3.11-4.21-4.35a3.41,3.41,0,0,1,1-.27l-.06,0C8.54,12.15,9.74,13.6,12.32,15.43Z"/><path class="cls-1" d="M23.61,21.19v0a2.11,2.11,0,0,1-.7.1,7.68,7.68,0,0,1-2.26-.44,25.67,25.67,0,0,1-3.17-1.28,3.1,3.1,0,0,1,.74-.75c2.92,1.35,4.82,1.68,5.14,1.4,0-.2-.21-.63-.77-1.22A2.86,2.86,0,0,1,23.61,21.19Z"/></svg>' },
        { name: 'space', svg: '<svg class="feedbackIcons" id="icon-space" data-name="Space" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Space</title><rect class="cls-1" width="32" height="32" rx="4"/><circle class="cls-2" cx="10.38" cy="11.08" r="3.38"/><circle class="cls-2" cx="21.62" cy="11.08" r="3.38"/><circle class="cls-2" cx="16" cy="20.92" r="3.38"/></svg>' },
        { name: 'texture', svg: '<svg class="feedbackIcons" id="icon-texture" data-name="Texture" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Texture</title><rect class="cls-1" width="32" height="32" rx="4"/><circle class="cls-2" cx="10.38" cy="11.08" r="3.38"/><circle class="cls-2" cx="21.62" cy="11.08" r="3.38"/><circle class="cls-2" cx="16" cy="20.92" r="3.38"/></svg>' },
        { name: 'value', svg: '<svg class="feedbackIcons" id="icon-value" data-name="Value" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32"><defs><clipPath id="clip-path"><rect class="cls-1" width="32" height="32" rx="4"/></clipPath></defs><title>Value</title><rect class="cls-1" width="32" height="32" rx="4"/><g class="cls-2"><rect class="cls-3" y="-10.5" width="8" height="48"/><rect class="cls-4" x="8" y="-8.5" width="8" height="48"/><rect class="cls-5" x="16" y="-10.5" width="8" height="48"/><rect class="cls-6" x="24" y="-9.5" width="8" height="48"/></g></svg>' },
        { name: 'color', svg: '<svg class="feedbackIcons" id="icon-color" data-name="Color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Color</title><rect class="cls-1" width="32" height="32" rx="4"/><circle class="cls-2" cx="11" cy="12.67" r="6.67"/><circle class="cls-3" cx="21" cy="12.67" r="6.67"/><circle class="cls-4" cx="16" cy="19.33" r="6.67"/></svg>' },
      ],
      dailyUploadTotal: 0,
      monthlyUploadTotal: 0
    }
  },
  computed: {
    dailyUploads: function (){
      return 4 - this.dailyUploadTotal
    },
    monthlyUploads: function (){
      return 20 - this.monthlyUploadTotal
    }
  },
  methods: {
    emitNotice (notice) {

      console.log('this is the notice emitted from child component openpost:', notice)

      this.$emit('notice', notice)

    },
    checkUploadRates() {

      console.log('Start checkUploadRates().')

      // get current time in 'epoch'/10 digit format

      let currentTime = Math.floor(new Date().getTime() / 1000)

      // check for upload timestamp on doc

      if(this.usersDoc.uploadTimestamp){

        // user has uploaded before

        let dailyUploadTimestamp = this.usersDoc.dailyUploadTimestamp

        let monthlyUploadTimestamp = this.usersDoc.monthlyUploadTimestamp

        this.dailyUploadTotal = this.usersDoc.dailyUploadTotal

        this.monthlyUploadTotal = this.usersDoc.monthlyUploadTotal

        // check age of timestamps and reset if needed

        let recordedDailyDate = new Date(dailyUploadTimestamp * 1000)

        console.log('This is the recorded daily date: ', recordedDailyDate)

        let recordedDay = recordedDailyDate.getDay()

        console.log('This is the recorded day: ', recordedDay)

        let currentDate = new Date()

        console.log('This is the current date: ', currentDate)

        let currentDay = currentDate.getDay()

        console.log('This is the current day: ', currentDay)

        // check if day has changed or
        // if 24 hours have passed (needed incase user returns on same day of week in the future)

        if(recordedDay != currentDay || currentTime - dailyUploadTimestamp > 86400){

          console.log('The day has changed, or 24 hours have passed.')

          // the day has changed

          this.dailyUploadTotal = 0

        } else {

          console.log('The day has not changed.')

          // 24 hours have not elapsed since last upload

          if(this.dailyUploadTotal === 4){

            // user has reached daily limit, disable upload button

            this.unvalidated = true

            this.$emit('notice', 'You have reached your daily upload limit.')

          }
        }

        let recordedDate = new Date(monthlyUploadTimestamp * 1000)

        console.log('This is the recorded monthly date: ', recordedDate)

        let recordedMonth = recordedDate.getMonth()

        console.log('This is the recorded month: ', recordedMonth)

        let currentMonth = currentDate.getMonth()

        console.log('This is the current month: ', currentMonth)

        // check if month has changed
        // or if 30 days have passed (needed incase user returns in same month in the future)

        if(recordedMonth !=  currentMonth || currentTime - monthlyUploadTimestamp > 2592000){

          // the month has changed since last monthly timestamp

          console.log('The month has changed, or 30 days have passed.')

          this.monthlyUploadTotal = 0

        } else {

          // the month has not changed, check post limit

          console.log('The month has not changed.')

          if(this.monthlyUploadTotal === 20){

            // user has reached monthly limit, disable upload button

            this.unvalidated = true

            this.$emit('notice', 'You have reached your monthly upload limit.')

          } 

        }

      } else {

        // user has not uploaded before

        this.dailyUploadTotal = 0
        this.monthlyUploadTotal = 0

      }

    },
    feedbackSwitch() {

      // toggle feedback mode
      this.focusedFeedback = !this.focusedFeedback

      if(!this.focusedFeedback){

        // clear narrow tags
        this.narrowedTags = []

        // clear tag button selection css
        let conceptButtons = document.getElementsByClassName('conceptSelectionButton')

        for (var i = 0; i <  conceptButtons.length; i++) {

          if(conceptButtons[i].classList.contains('selected')){

            conceptButtons[i].classList.remove('selected')

          }

          if(conceptButtons[i].classList.contains('conceptSelectionButtonDisabled')){

            conceptButtons[i].classList.remove('conceptSelectionButtonDisabled')
          }

        }
        
      }

      // validate post
      this.validateDuringInput()
      
    },
    selectTags(e) {

        let selectedTag = e.currentTarget.id

        // check if clicked tag does not have 'selected' class
        console.log('clicked on: ', selectedTag)

        // if does not contain selected class and less than 3 tags are selected
        if(!e.currentTarget.classList.contains('selected') && this.narrowedTags.length < 3) {
          this.feedback = null
          e.currentTarget.classList.add('selected');
          // add tag to narrowedTags array
          
          console.log('adding this principle to array: ', selectedTag)
          this.narrowedTags.push(selectedTag);
          console.log('this is the array; ', this.narrowedTags)

          // toggle 'conceptSelectionButtonDisabled' class to style cursor from pointer to arrow
          if(this.narrowedTags.length === 3){
            console.log('there are 3 tags now, so add disabled class to buttons')
            let conceptButtons = document.getElementsByClassName('conceptSelectionButton')
            for (var i = 0; i <  conceptButtons.length; i++) {
              if(!conceptButtons[i].classList.contains('selected')){
                conceptButtons[i].classList.add('conceptSelectionButtonDisabled')
              }
            }
          }

          // if does not contain selected class and 3 tags have been selected
        } else if(!e.currentTarget.classList.contains('selected') && this.narrowedTags.length >= 3) {
          this.feedback = 'Please select no more than 3 tags'
          setTimeout(() => { this.feedback = null }, 2000)

          // if does contain seleced class
        } else {
          console.log('removing this principle to array: ', selectedTag)
          this.feedback = null
          e.currentTarget.classList.remove('selected');
          
          // toggle 'conceptSelectionButtonDisabled' class to style cursor from pointer to arrow
          if(this.narrowedTags.length === 3){
            console.log('removing this tag, so remove disabled class from buttons')
            let conceptButtons = document.getElementsByClassName('conceptSelectionButton')
            for (var i = 0; i <  conceptButtons.length; i++) {
                if(conceptButtons[i].classList.contains('conceptSelectionButtonDisabled')){
                  conceptButtons[i].classList.remove('conceptSelectionButtonDisabled')
                }
              }
          }

          // use filter to remove principle from narrowedTags array
          this.narrowedTags = this.narrowedTags.filter(principle => { //change principe to tag/item/etc

            return principle != selectedTag;

          })

          console.log('this is the array; ', this.narrowedTags)

        }
        
        // validate post

        this.validateDuringInput()

        // if(this.postTitle && this.narrowedTags.length >= 1 && this.postDesc && this.files.length > 0) {
          
        //   if(this.dailyUploadTotal < 4 && this.monthlyUploadTotal < 20){

        //     this.unvalidated = false

        //   }

        // } else if(this.postTitle && !this.focusedFeedback && this.postDesc && this.files.length > 0) {
            
        //   if(this.dailyUploadTotal < 4 && this.monthlyUploadTotal < 20){

        //     this.unvalidated = false

        //   }

        // } else {

        //     this.unvalidated = true;

        // }
     
    },
    validateDuringInput(e) {

      // this allows control of the 'upload' button during input, so button is enabled or disabled on the fly as user inputs data

      console.log('Start validateDuringInput().');

      // ISSUE we error once user starts to  input a title, 
      //because desc is null and match gets error when ron on null
      // so add if check to make sure they are not null before moving on to other checks

      // validate input characters

      if(this.postTitle !== null && this.postTitle.match(/^[a-zA-Z0-9 ]+$/g)){

        console.log('The title chars are valild.');

        if(this.notice === 'Title may not contain special characters.'){

          this.$emit('notice', '');

        };

        if(this.postDesc !== null && this.postDesc.match(/^[a-zA-Z0-9 \?!,.%-]+$/g)){

          console.log('The desc chars are valid.');

          if(this.notice === 'Description may not contain special characters.'){

            this.$emit('notice', '');

          };

        } else {

          this.unvalidated = true;

          if(this.postDesc !== null && this.postDesc !== ''){

            console.log('The desc chars are invalid.');

            this.$emit('notice', 'Description may not contain special characters.');

          };

        };

        // validate input lengths

        if(this.postTitle !== null && this.postTitle.length >= 3 && this.postTitle.length <= 64){

          console.log('The title length is valid.');

          if(this.notice === 'Title must be longer.' || this.notice === 'Title must be shorter.'){

            this.$emit('notice', '');

          };

          if(this.postDesc !== null && this.postDesc.length >= 3 && this.postDesc.length <= 600){

            console.log('The description length is valid.');

            if(this.notice === 'Description must be longer.' || this.notice === 'Description must be shorter.'){

              this.$emit('notice', '');

            };

            // validation for entire form

            if(this.narrowedTags.length >= 1 && this.narrowedTags.length <= 3 &&           // tag qty/length must be min 1 max 3
            this.files.length >= 1 && this.files.length <= 2){

              console.log('During: The user inputs are valid, but check rate.');

              if(this.dailyUploadTotal < 4 && this.monthlyUploadTotal < 20){

                console.log('The user has not reached any limits.');

                this.unvalidated = false;

              } else {

                console.log('The user has reached their limits.');

                this.unvalidated = true;

              };

            } else if(!this.focusedFeedback && this.files.length >= 1 && this.files.length <= 2 ){

              console.log('During: The user inputs are valid, but check rate.');

              // ISSUE do we need to add code here to handle/erase displayed notice?

              if(this.dailyUploadTotal < 4 && this.monthlyUploadTotal < 20){

                console.log('The user has not reached any limits.');

                this.unvalidated = false;

              } else {

                this.unvalidated = true;

              };

            } else {

              // the tags are not valid

              console.log('The tags are not valid.');

              this.unvalidated = true;

            };

          } else {

            console.log('The description length is invalid.');

            this.unvalidated = true;

          };

        } else {

          console.log('The title length is invalid.');

          this.unvalidated = true;

        };

      } else {

        this.unvalidated = true;

        if(this.postTitle !== null && this.postTitle !== ''){

          console.log('The title chars are invalid.');

          this.$emit('notice', 'Title may not contain special characters.');

        };

      };

    },
    validateAfterInput(e) {

      console.log('Start validateAfterInput().');

      if(this.postTitle !== null && this.postTitle.length < 3){

        console.log('The title is too short.')

        this.$emit('notice', 'Title must be longer.')

      } else if(this.postTitle !== null && this.postTitle.length > 64){

        console.log('The title is too long.')

        this.$emit('notice', 'Title must be shorter.')

      } else if(this.postDesc !== null && this.postDesc.length < 3){

        console.log('The description is too short.')

        this.$emit('notice', 'Description must be longer.')

      } else if( this.postDesc !== null && this.postDesc.length > 600){

        console.log('The description is too long.')

        this.$emit('notice', 'Description must be shorter.')

      }



      // console.log('Start validateAfterInput().')

      // const validateForm = () => {

      //   console.log('After: Start validateForm().')

      //   if(this.postTitle !== null && this.postDesc !== null){

      //     console.log('After: The title and desc are entered now.')

      //     if(this.postTitle.length >= 3 && this.postTitle.length <= 64 &&                           // validate title length
      //       typeof this.postTitle === 'string' && this.postTitle.match(/^[a-zA-Z0-9 ]+$/g) &&       // validate no spec chars here
      //       typeof this.postDesc === 'string' && this.postDesc.match(/^[a-zA-Z0-9 \?,.%-]+$/g) &&  // validate no spec chars here
      //       this.postDesc.length >= 3 && this.postDesc.length <= 600 &&                             // validate desc length
      //       this.narrowedTags.length >= 1 && this.narrowedTags.length <= 3 &&                       // validate tag qty
      //       this.files.length >= 1 && this.files.length <= 2) {                                     // validate file qty
          
      //       console.log('After: The user input is valid, but check rate.')

      //       this.$emit('notice', null)

      //       if(this.dailyUploadTotal < 4 && this.monthlyUploadTotal < 20){

      //           this.unvalidated = false

      //         } else {

      //           this.$emit('notice', 'You have reached your upload limit.')

      //         }

      //     } else if(this.postTitle.length >= 3 && this.postTitle.length <= 64 &&                    // validate title length
      //       typeof this.postTitle === 'string' && this.postTitle.match(/^[a-zA-Z0-9 ]+$/g) &&       // validate no spec chars here
      //       typeof this.postDesc === 'string' && this.postDesc.match(/^[a-zA-Z0-9 \?,.%-]+$/g) &&  // validate no spec chars here
      //       this.postDesc.length >= 3 && this.postDesc.length <= 600 &&                             // validate desc length
      //       !this.focusedFeedback &&                                                                // validate FFb is off
      //       this.files.length >= 1 && this.files.length <= 2) {                                     // validate file qty
              
      //       console.log('After: The user input is valid, but check rate.')

      //       this.$emit('notice', null)

      //       if(this.dailyUploadTotal < 4 && this.monthlyUploadTotal < 20){

      //           this.unvalidated = false

      //         } else {

      //           this.$emit('notice', 'You have reached your upload limit.')

      //         }

      //     } else {

      //         this.unvalidated = true

      //         console.log('After: The form is not valid, yet.')

      //     }

      //   } else {

      //     this.unvalidated = true

      //     console.log('After: The title or desc is not entered yet.')

      //   }

      // }

      // // validate title                                   
      // if(e.currentTarget.id === 'enterPostTitle'){

      //   if(this.postTitle !== null && this.postTitle !== ''){

      //     if(typeof this.postTitle === 'string' && this.postTitle.match(/^[a-zA-Z0-9 ]+$/g)){

      //       console.log('After: The post title is a string and contains no special characters: ', this.postTitle)

      //       if(this.postTitle.length >= 3 && this.postTitle.length <= 64){    // check this when focus leaves input only
                                                                      
      //         console.log('After: Title data length is valid.')

      //         validateForm()

      //         this.$emit('notice', '')

      //       } else {

      //         console.log('After: The post title length is invalid')

      //         this.$emit('notice', 'Title must be between 3 - 64 characters.')

      //         this.unvalidated = true;

      //       }

      //     } else {

      //       console.log('After: The post title is not a string or contains special characters: ', this.postTitle)

      //       this.$emit('notice', 'Title may not contain any special characters.')

      //       this.unvalidated = true;

      //     }
          
      //   } else {

      //     console.log('After: The post title is null or empty string')

      //   }

      // // validate desc

      // } else if(e.currentTarget.id === 'enterPostBrief'){

      //   if(this.postDesc !== null && this.postDesc !== ''){

      //     if(typeof this.postDesc === 'string' && this.postDesc.match(/^[a-zA-Z0-9 \?,.%-]+$/g)){ // add period, comma, dash, etc...

      //       console.log('After: The post description is a string and contains no special characters.')

      //       if(this.postDesc.length >= 3 && this.postDesc.length <= 600){     // check this when focus leaves input only  
                                                                        
      //         console.log('After: Desc data length is valid.')
              
      //         validateForm()

      //         this.$emit('notice', '')

      //       } else {

      //         console.log('After: The post desc length is invalid.')

      //         this.$emit('notice', 'Description must be between 3 - 600 characters.')

      //         this.unvalidated = true;

      //       }

      //     } else {

      //       console.log('After: The post desc is not a string or contains special characters.')

      //       this.$emit('notice', 'Description may not contain any special characters.')

      //       this.unvalidated = true;

      //     }

      //   } else {

      //     console.log('After: The post desc is null or empty string')

      //   }

      // }      

    },
    highlight(e) {
      let dropZone = document.getElementById('dropZone');
      dropZone.classList.add('highlight')
      
    },
    unhighlight(e) {
      let dropZone = document.getElementById('dropZone');
      dropZone.classList.remove('highlight')
    },
    getFilesToPost(e) {
      this.droppedFiles = []
      if(e.dataTransfer){
        console.log(e.dataTransfer)
        let dt = e.dataTransfer
        this.droppedFiles = dt.files
      }
      // reset form input on for repeat input events
      this.files.length = 0
      this.fileNames.length = 0
      console.log('this is the droppedFiles before if statement: ', this.droppedFiles, this.droppedFiles.length)
      if(this.droppedFiles.length === 0){ 
        this.droppedFiles = e.target.files // files aren't changindg on 2nd attempt becuase .dropped length is not 0 anymore
      }
      console.log('this is droppedFiles after if statement: ', this.droppedFiles, this.droppedFiles.length)
      console.log('file names after user selects file but before they are pushed to array ' + this.files + '. And length: ' + this.files.length + '.')
      // check if input contained 2 or less files but more than 0
      if(this.droppedFiles.length <= 2 && this.droppedFiles.length > 0){
        // get file list from input area
        const fileList = this.droppedFiles
        // make array from file list and add each file to this.file array with forEach
        Array.from(fileList).forEach((file) => {
          this.files.push(file)
          this.fileNames.push(file.name)
          console.log('This file was added to the files array: ' + file.name)
          console.log('Now the files array contains: ' + this.files)
        })

        // validate post to enable upload button
        
        if(this.postTitle && this.postDesc){

          if(
            this.postTitle.length >= 3 && this.postTitle.length <= 64 &&                            // validate title length
            typeof this.postTitle === 'string' && this.postTitle.match(/^[a-zA-Z0-9 ]+$/g) &&       // validate no spec chars here
            typeof this.postDesc === 'string' && this.postDesc.match(/^[a-zA-Z0-9 \?!,.%-]+$/g) &&  // validate no spec chars here
            this.postDesc.length >= 3 && this.postDesc.length <= 600 &&                             // validate desc length
            this.narrowedTags.length >= 1 && this.narrowedTags.length <= 3 &&                       // validate tag qty
            this.files.length >= 1 && this.files.length <= 2
          ){

            if(this.dailyUploadTotal < 4 && this.monthlyUploadTotal < 20){

              this.unvalidated = false

            }

          } else if(
            this.postTitle.length >= 3 && this.postTitle.length <= 64 &&                            // validate title length
            typeof this.postTitle === 'string' && this.postTitle.match(/^[a-zA-Z0-9 ]+$/g) &&       // validate no spec chars here
            typeof this.postDesc === 'string' && this.postDesc.match(/^[a-zA-Z0-9 \?!,.%-]+$/g) &&  // validate no spec chars here
            this.postDesc.length >= 3 && this.postDesc.length <= 600 &&                             // validate desc length
            !this.focusedFeedback &&                                                                // validate FFb is off
            this.files.length >= 1 && this.files.length <= 2
          ){
              
            if(this.dailyUploadTotal < 4 && this.monthlyUploadTotal < 20){
              
              this.unvalidated = false

            }

          } else {

              this.unvalidated = true

          }

        } else {

          this.unvalidated = true

        }

        ///////////////////////
        // setup image preview
        let thumbList = document.getElementById('thumbList');
        console.log(thumbList)
        if (thumbList.hasChildNodes()){
          //thumbList.innerHTML = '';
          let span = document.getElementsByClassName('image-upload');
          console.log('span before reset: ', span)
          thumbList.innerHTML = '';
          console.log('span after reset: ', span)
          console.log('thumblist after innerhtml reset: ', thumbList)
          console.log('thumblist has child nodes')        }
        // create image previews from file list
        for (var i = 0, f; f = fileList[i]; i++) {
          // Only process image files.
          if (!f.type.match('image.*')) {
            continue;
          }
          var reader = new FileReader();
          // Closure to capture the file information.
          reader.onload = (function(theFile) {
            return function(e) {
              // Render thumbnail.
              
              var span = document.createElement('span');
              
              span.setAttribute("class", "image-upload");
              span.innerHTML = ['<img src="', e.target.result,                      // can we use innerText in lieu of innerHTML?
                                '" title="', escape(theFile.name), '"/>'].join('');
              document.getElementById('thumbList').insertBefore(span, null);
            };
          })(f);
          // Read in the image file as a data URL.
          reader.readAsDataURL(f);            // ISSUE would it be better to use URL.createObjectURL()??
          this.feedback = null
        }
      } else {
        /////////////////////////
        // disable upload button
        this.unvalidated = true;
        ///////////////////////
        // reset image preview
        let thumbList = null
        this.feedback = 'Please select 1 or 2 files'
      }
      /////////////////////////////////
      // setup and reset image preview
      let thumbList = document.getElementById('thumbList');
      if (thumbList.hasChildNodes()){
        thumbList.innerHTML = '';
      }
    },
    handleDrop(e) {
      this.unhighlight()
      this.getFilesToPost(e)
    },
    dealPost(){
      console.log('start dealPost()')
      // start when user clicks 'upload' button

      // open a note modal that explains 'dealing' with 'okay' button
      console.log('open the deal notification modal')
      this.dealModalOn = true
      this.dealModalVisible = true

      // make doc query for docs with lowest scores (limit to 10 results)
      let docsToDeal = projectFirestore.collection('userPosts').orderBy('score').limit(7)

      // now we have 4 more posts than we need for rando selection code below...
      // we will remove any posts that current user authored, or those with the highest scores, removing a total of 4 always
      // this will mitigate the issue of a user being dealt there own post during low traffic and serial uploading.

      docsToDeal.get().then(docs => {

        console.log('these are the docs to deal from: ', docs.docs)

        let queriedDocs = docs.docs       // docs retreived for dealing
        let filteredDocs = queriedDocs    // docs to be filtered of current user's posts
        let filterCounter = 0             // tracking how many of current user's posts have been removed (should be 4 max)
        let excessDocs = 0                // how many more docs to remove after filtering out current user's docs

        console.log('These are the queried docs: ', queriedDocs)
        console.log('These are the filtered docs: ', filteredDocs)

        for (let i = 0; i < queriedDocs.length; i++){

          if(queriedDocs[i].data().userId === this.user.uid){

            filterCounter = filterCounter + 1

            filteredDocs.splice(i, 1) // removes same item from sister array

            console.log('This is the filter counter: ', filterCounter)
            console.log('This is the filtered array: ', filteredDocs)

          }

          if(filterCounter === 4){

            // we have 3 remaining docs to deal, so break out of loop now.

            break

          }

        }

        console.log('The filteredDocs length: ', filteredDocs.length)

        if(filteredDocs.length > 3){            // if there are more than 3 docs still inside filteredDocs array 
                                                // then current user did not have 4 posts to filter out.

          excessDocs = filteredDocs.length - 3 

          console.log('The excessDocs value: ', excessDocs)

          // now remove number of excess docs from filterDocs

          filteredDocs.splice(-(excessDocs), excessDocs)// this should remove the excess docs from filterdDocs array

        }

        console.log('The final filteredDocs length: ', filteredDocs.length)

        // calculate a random number between 0 and length of query - 1 (minus 1 because 0 can be returned)
        let randoNum = Math.round(Math.random() * 2)

        // use random number to select post for dealing and set this.openPostId to selected post
        console.log('this is the rando selected doc data  userId: ', filteredDocs[randoNum].data().userId)
        console.log('this is the rando selected doc data id: ', filteredDocs[randoNum].id)

        this.dealedPost = filteredDocs[randoNum].data();
        this.dealedPost.id = filteredDocs[randoNum].id//.slice(0, -7);
        // this.dealedPost.authorImageURL = this.authorImageURL
        console.log('This is the dealedPost prop to send to DisplayPost: ', this.dealedPost)

        // ISSUE need to add: 1. authorImageURL 2. doc id to this.dealedPost 

       
        // call cloud function to retreive user record and therefore photo URL

        let getAuthorImageURLs = projectFunctions.httpsCallable('getAuthorImageURLs')

        getAuthorImageURLs({

          userPool: [{ uid: filteredDocs[randoNum].data().userId}]

        }).then(result => {
          
          console.log('This is rewsult from getting author imageURLs: ', result)

          if(result.data[0].photoURL){

            this.dealedPost.authorImage = result.data[0].photoURL

          }
          
          this.dealedPost.alias = result.data[0].displayName

          // open DealPost modal with randomized low score post

          const body = document.getElementsByTagName("BODY")[0]

          body.classList.add("noscroll")

          this.modalOn = true          

        }).catch(error => {

          console.log('there was an error getting the author image URL: ', error)

        })

      }).catch(error => {
        console.log('there was an error getting the docs to deal: ', error)
        this.$emit('notice', error)
      })
      
    },
    closeDealModal(){
      this.dealModalOn = false
      this.dealModalVisible = false
    },
    closeModal(e){
      const body = document.getElementsByTagName("BODY")[0]
      body.classList.remove("noscroll")
      // turn modal off
      this.modalOn = false
      // reset post images
      // document.getElementById('thumbList').innerHTML = '' // this code inadvertently erases preview when dealed post feedback fails (rate limit reached)
      // reset doc data
      // this.openPost = []
      this.narrowMode = false
      this.authorImageURL = null
    },
    uploadPost() {

      console.log('Start uploadPost().')

      this.showSpinner = true

      // this turns on display of progress bar for upload
      
      this.uploading = true

      if(this.files.length > 0){

        let metadata = {

          customMetadata: {

            'user': this.user.uid

          }

        }

        let uploadProgress = document.getElementById('uploadProgress')

        let uploadCounter = 0

        let storageRef = projectStorage.ref()

        for (let i = 0; i < this.files.length; i++){

          let imageFile = this.files[i];

          let uploadTask = storageRef.child('raw-user-images/' + this.user.uid + '-post-image-' + (i + 1)).put(imageFile, metadata)
        
          
          uploadTask.on('state_changed', (snapshot) => {

              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

              uploadProgress.value = progress;  

            }, (error) => {

              console.log(' .on(state_changed) Error uploading file: ', error)

              if(error.code === 'storage/unauthorized'){

                this.$emit('notice', 'You are not authorized to complete this task.')

              } else {

                this.$emit('notice', error.message)

              }

              this.showSpinner = false

              uploadProgress.value = 0

            }, () => {

              // call cloud function to process images and create post docs

              console.log('This is the uploadCounter before update: ', uploadCounter)

              uploadCounter = uploadCounter + 1

              console.log('This is the uploadCounter after update: ', uploadCounter)

              if(uploadCounter === this.files.length){      

                let processPostDocs = projectFunctions.httpsCallable('processPostDocs')

                console.log('Call cloud function: processPostDocs.')

                processPostDocs({

                  postInfo: {
                    postTitle: this.postTitle,        
                    postDesc: this.postDesc,
                    postCat: this.postCat,
                    focusedFeedback: this.focusedFeedback,
                    narrowedTags: this.narrowedTags             
                  }

                }).then(result => {

                  console.log('this is the result::::', result)

                  if(result.data.error){

                    console.log('This is the error code returned from cloud function: ', result.data.error)
                    
                    if(result.data.error === 'minuteLimit'){

                      this.$emit('notice', 'You must wait at least 3 minutes between posts.')

                    } else if(result.data.error === 'dailyLimit'){

                      this.$emit('notice', 'You can only upload 4 posts per day.')

                    } else if(result.data.error === 'monthlyLimit'){

                      this.$emit('notice', 'You can only upload 20 posts per month.')

                    } else if(result.data.error === 'invalidAuth'){

                      this.$emit('notice', 'Your records have been repaired.')

                    } else if(result.data.error === 'offensiveContent'){

                      this.$emit('notice', 'The content you uploading is not allowed because it may be offensive.')

                    } else if(result.data.error){

                      this.$emit('notice', 'There was an error uploading your post: ', result.data.error)

                    }
                    
                    this.showSpinner = false

                  } else if(!result.data.error){

                    let postId = result.data

                    let imageURLs = []

                    // const batch = projectFirestore.batch()
                    const postDocRef = projectFirestore.collection('userPosts').doc(postId)
                    // const myPostsDocRef = projectFirestore.collection('users').doc(this.user.uid).collection('myPosts').doc(postId)

                    const finalizePostDoc = () => {

                      // console.log('Batch update is disabled for clean up testing of upload related docs/files.')

                      console.log('Update post doc with imageURLs and update processed to true.')

                      // batch update post docs with urls here

                      postDocRef.update({
                        imageURLs: imageURLs,
                        processed: true,
                      }).then(() => {

                        console.log('The post doc is finalized.')

                        // emit event to updateUsersDoc so new upload timestamps can be accessed by app

                        this.$emit('updateUsersDoc')
                        
                        this.showSpinner = false

                        this.$router.push({ name: 'MGallery' })
                        
                      }).catch(error => {

                        console.log('There was an error updating post docs with image urls: ' + error)

                        console.log('This is the error code: ', error.code)

                        this.$emit('notice', 'There was an error uploading your post: ', error)

                        this.showSpinner = false

                        // ********************************************************************************************************
                        // ISSUE delete post docs and clean images if post was not updated with URLs, handle with scheduled cloud functio
                        // ********************************************************************************************************

                      })

                    }

                    // get download URL of clean image(s) and record them to post docs

                    let filePath = 'user-images/' + this.user.uid + '/' + postId + '/' + this.user.uid + '-post-image-1'

                    console.log('This is the file path: ', filePath)

                    projectStorage.ref()
                    .child(filePath)
                    .getDownloadURL()
                    .then(url => {

                      console.log('This is the 1st download URL: ', url)

                      imageURLs.push(url)

                      console.log('This is the imageURLs: ', imageURLs)

                      if(this.files.length === 2){

                        filePath = 'user-images/' + this.user.uid + '/' + postId + '/' + this.user.uid + '-post-image-2'

                        console.log('This is the file path: ', filePath)

                        projectStorage.ref()
                        .child(filePath)
                        .getDownloadURL()
                        .then(url => {

                          console.log('This is the 2nd download URL: ', url)

                          imageURLs.push(url)

                          console.log('These are the imageURLs: ', imageURLs)

                          finalizePostDoc()

                        }).catch(error => {

                          console.log('There was an error getting the 2nd download URL: ', error)

                          // ********************************************************************************************************
                          // ISSUE delete post docs and clean images if error getting urls, handle with scheduled cloud function
                          // ********************************************************************************************************

                          this.$emit('notice', error)
                          // A full list of error codes is available at
                          // https://firebase.google.com/docs/storage/web/handle-errors
                          switch (error.code) {
                            case 'storage/object-not-found':
                              // File doesn't exist
                              break;
                            case 'storage/unauthorized':
                              // User doesn't have permission to access the object
                              break;
                            case 'storage/canceled':
                              // User canceled the upload
                              break;
                            case 'storage/unknown':
                              // Unknown error occurred, inspect the server response
                              break;
                          }

                        })

                      } else {

                        finalizePostDoc()

                      }

                    }).catch(error => {

                      console.log('There was an error getting the download URL: ', error)
                      
                      // ********************************************************************************************************
                      // ISSUE delete post docs and clean images if error getting urls, handle with scheduled cloud function
                      // ********************************************************************************************************

                      this.$emit('notice', error)
                      // A full list of error codes is available at
                      // https://firebase.google.com/docs/storage/web/handle-errors
                      switch (error.code) {
                        case 'storage/object-not-found':
                          // File doesn't exist
                          break;
                        case 'storage/unauthorized':
                          // User doesn't have permission to access the object
                          break;
                        case 'storage/canceled':
                          // User canceled the upload
                          break;
                        case 'storage/unknown':
                          // Unknown error occurred, inspect the server response
                          break;
                      }

                    })

                  }
                  
                }).catch(error => {

                  console.log('There was an error uploading the post docs and processing the post images: ', error)

                  this.$emit('notice', 'There was an error uploading your post: ', error)

                  // ********************************************************************************************************
                  // ISSUE delete raw images and maybe clean images if error creating docs and processing images, handle with scheduled cloud function
                  // ********************************************************************************************************

                })

              }

            }

          )

        }

      }

    }

  }, 
  created() {

    this.checkUploadRates()

    // let thumbList = document.getElementById('thumbList'); // ISSUE thumbList is null here

    // thumbList.innerHTML = '';
    
  },
  mounted(){

    let thumbList = document.getElementById('thumbList'); // ISSUE thumbList is null here

    thumbList.innerHTML = '';

  },
  unmounted() {

    console.log("Start unmounted() in Upload.vue.")

  }

}

</script>

<style>


/* ---------- START UPLOAD STYLING ---------- */
#upload {
  padding-bottom: 70px;
  margin-top: 1em;
  margin-bottom: 2em;
}
#upload #uploadContent {
  display: flex;
  flex-direction: column;
}
#upload .directions {
  color: #aaa;
  display: block;
}
/* ---------- END UPLOAD STYLING ---------- */


/* ---------- START DROP ZONE STYLING ---------- */
#upload #dropZone {
  border-radius: 8px;
  border: dashed 2px #ccc;
  padding: 20px;
}
#upload #dropZone.highlight {
  border-color: #9B7EDE;
}
#upload #dropZone #dropZoneInstructions {
  display: grid;
  text-align: center;
}
#upload #dropZone #dropZoneInstructions i {
  font-size: 98px;
  color: #aaa;
}
#upload #dropZone #dropZoneInstructions span {
  font-size: 1.25em;
}
#upload #dropZone #dropZoneInstructions .directions {
  align-items: center;
  font-size: 1em;
  display: flex;
  justify-content: center;
  margin-bottom: .5em;
  text-align: left;
}
#upload #dropZone #dropZoneInstructions #uploadTotals {
  /* font-weight: 500; */
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
#upload #dropZone #dropZoneInstructions #uploadTotals span:first-of-type {
  /* font-size: 1em;
  font-weight: 500; */
  margin-right: 10px;
  /* margin-top: 20px; */
}
#upload #dropZone #dropZoneInstructions #uploadTotals span span {
  /* font-size: 1em; */
  font-weight: 500;
  /* margin-right: 10px; */
  /* margin-top: 20px; */
}
#upload #dropZone #dropZoneInstructions .modeBadge {
  background-color: #939598;
  color: #fff;
  padding: 1px 3px;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 4px;
  margin-right: 1em;
}
#upload #dropZone #fileBrowseLink {
  display: inline-block;
  font-size: 1em;
  color: #D97AEB;
  cursor: pointer;
}
#upload #dropZone #fileUpload {
  display: none;
}
/* ---------- END DROP ZONE STYLING ---------- */


/* ---------- START IMAGE STAGE STYLING ---------- */
#upload #imageStage {
  margin-top: 1em;
  width: 100%;
}
#upload #imageStage output {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
#upload #imageStage span {
  display: flex;
  max-width: 180px;
  width: 33%;
  border-radius: 8px;
  border: dashed 2px #ccc;
  padding: 2px;
}
#upload #imageStage img {
  width: 100%;
  border-radius: 8px;
}
/* ---------- END IMAGE STAGE STYLING ---------- */


/* ---------- START UPLOAD FORM STYLING ---------- */
#upload #postForm {
  display: flex;
  flex-direction: column;
}
/* ---------- END UPLOAD FORM STYLING ---------- */


/* ---------- START CONCEPT SELECTION STYLING ---------- */
#upload #conceptSelection .concepts {
  float: left;
  width: 50%;
}
#upload #conceptSelection .focusedFeedbackDisabled {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: .3s ease-out;
}
#upload #conceptSelection .focusedFeedbackEnabled {
  opacity: 1;
  max-height: 310px;
  transition: .3s ease-out;
}
#upload #conceptSelection label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
#upload #conceptSelection div label {
  color: #cdcdcd;
}
#upload #conceptSelection .conceptSelectionButton {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  transition: transform .05s;
  transition: .3s ease-out;
  opacity: 1;
}
#upload #conceptSelection .conceptSelectionButton.conceptSelectionButtonDisabled {
  cursor: default;
  opacity: .66;
  transition: .3s ease-out;
}
#upload #conceptSelection .conceptSelectionButton .conceptIcon {
  display: flex;
}
#upload #conceptSelection .conceptName {
  text-transform: capitalize;
}
#upload #conceptSelection .feedbackIcons { 
  height: 28px;
  margin-right: 1em;
}
#upload #conceptSelection .selected .conceptName {
  font-weight: 700;
}
/* ---------- END CONCEPT SELECTION STYLING ---------- */


/* ---------- START BADGE STYLING ---------- */
#upload #conceptSelection .badgeContainer {
  align-items: center;
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  max-width: 110px;
}
#upload #conceptSelection .badgeContainer .modeBadge {
  background-color: #ddd;
  color: #fff;
  padding: 1px 3px;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 4px;
}
#upload #conceptSelection .badgeContainer .modeBadge.enabled {
  background-color: #939598;
}
#upload #conceptSelection .badgeContainer .toolTip {
  visibility: hidden;
  color: #939598!important;
  background-color: #ddd;
  border-radius: 8px;
  margin-top: 2em;
  min-width: 120px;
  padding: .5em;
  position: absolute;
  text-align: center;
  z-index: 9999;
}
#upload #conceptSelection .badgeContainer:hover .toolTip {
  visibility: visible;
}
#upload #conceptSelection .badgeContainer .toolTip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ddd transparent;
}
/* ---------- END BADGE STYLING ---------- */


/* ---------- START MODE SELECTION STYLING ---------- */
#upload #conceptSelection #critiqueModeSelection {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: .5em;
  max-width: 160px;
}
#upload #conceptSelection #critiqueModeSelection .switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 14px;
  margin-bottom: 0;
}
#upload #conceptSelection .switch label {
  margin-bottom: 0;
}
#upload #conceptSelection #critiqueModeSelection .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #9B7EDE;
}
input:focus + .slider {
  box-shadow: 0 0 1px #9B7EDE;
}
input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}
/* ---------- END MODE SELECTION STYLING ---------- */


/* ---------- START DEAL MODAL STYLING ---------- */
#upload #dealModalContent {
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
}
#upload #dealModalHeader {
  padding: 1em 1em 0;
  text-align: center;
  font-weight: 600;
  color: #9B7EDE;
}
#upload #dealModalHeader h1 {
  font-size: 2.5em;
  font-weight: 500;
}
#upload #dealModalHeader i { 
  font-size: 95px;
}
#upload #dealModalBody {
  text-align: center;
  padding: 0 2em 2em;
}
#upload #dealModalBody .btn {
  margin-top: 20px;
  text-transform: uppercase;
  width: 100%;
}
/* ---------- END DEAL MODAL STYLING ---------- */


/* ---------- START CONCEPT SVG STYLING ---------- */
#upload .selected .cls-1 {fill:#D97AEB;transition: .3s ease-out;}
#upload .cls-1{fill:#939598; transition: .3s ease-out;}
#upload #icon-balance .cls-2 {fill:#58595b;}
#upload #icon-balance .cls-3 {fill:#fff;}
#upload #icon-contrast .cls-2{fill:#58595b;}
#upload #icon-contrast .cls-3{fill:#fff;}
#upload #icon-emphasis .cls-2{fill:#fff;}
#upload #icon-emphasis .cls-3{fill:#58595b;}
#upload #icon-movement .cls-2{fill:#fff;}
#upload #icon-pattern .cls-2{fill:#fff;}
#upload #icon-rhythm .cls-2{fill:#f1f2f2;}
#upload #icon-rhythm .cls-3{fill:#58595b;}
#upload #icon-rhythm .cls-4{fill:#fff;}
#upload #icon-unity .cls-2{fill:#fff;}
#upload #icon-unity .cls-3{fill:#58595b;}
#upload #icon-line .cls-2{fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px;}
#upload #icon-shape .cls-2{fill:#fff;}
#upload #icon-form .cls-2{fill:#fff;}
#upload #icon-form .cls-3{fill:#58595b;}
#upload #icon-space .cls-2{fill:#fff;}
#upload #icon-texture .cls-2{fill:#fff;}
#upload #icon-value .cls-2{clip-path:url(#clip-path);}
#upload #icon-value .cls-3,#icon-value .cls-4,#icon-value .cls-5,#icon-value .cls-6{fill:#58595b;}
#upload #icon-value .cls-3{opacity:0.25;}
#upload #icon-value .cls-4{opacity:0.5;}
#upload #icon-value .cls-5{opacity:0.75;}
#upload #icon-color .cls-2,#icon-color .cls-3,#icon-color .cls-4{fill:#f1f2f2;}
#upload #icon-color .cls-3{opacity:0.66;}
#upload #icon-color .cls-4{opacity:0.33;}
/* ---------- END CONCEPT SVG STYLING ---------- */


/* ---------- START FOOTER STYLING ---------- */
footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 70px;
  background-color: rgba(66, 54, 94, 1);
}
footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
progress::-webkit-progress-bar {background-color: #f3f3f4; width: 100%;}
progress::-webkit-progress-value { background:#D97AEB; }
.progressBar {
  display: none;
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
  width:100%;
  margin: 10px auto;
  position: absolute;
  top: -15px;
  height: 5px;
}
.progressOn {
  display: block;
  opacity: 1;
}
/* ---------- END FOOTER STYLING ---------- */


/* ---------- START ERROR FEEDBACK STYLING ---------- */
.error-feedback {
  position: absolute;
}
/* ---------- END ERROR FEEDBACK STYLING ---------- */


@media screen and (min-width: 601px) {
  #upload {
    margin-top: 2em;
  }
}
@media screen and (min-width: 901px) {
  #upload #postForm {
    flex-direction: row;
  }
  #upload #postForm #conceptSelectionContainer {
    margin-left: 2em;
    margin-top: 2em;
    width: 50%;
  }
  #upload #postForm #postInputs {
    margin-top: 1em;
    width: 50%;
  }
}


</style>