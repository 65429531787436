import { createRouter, createWebHistory } from 'vue-router'

import Login from '@/views/auth/Login'
import Signup from '@/views/auth/Signup'
import VerifyEmail from '@/views/auth/VerifyEmail'
import MGallery from '@/views/home/MGallery'
import Profile from '@/views/home/Profile'
import About from '@/views/home/About'
import NotFound from '@/views/home/NotFound'
import Terms from '@/views/legal/Terms'
import Privacy from '@/views/legal/Privacy'
import DisplayPost from '@/components/posts/DisplayPost'
import DisplayProfilePost from '@/components/posts/DisplayProfilePost'
import Upload from '@/views/posts/Upload'
// import MyPosts from '@/views/posts/MyPosts'
import Settings from '@/views/auth/Settings'

import { projectAuth } from '../firebase/config'

const requireAuth = (to, from, next) => {

  let user = projectAuth.currentUser

  if(user){

    // user signed in, proceed to route

    if(user.emailVerified === true){

      next()

    } else {

      // route VerifyEmail.vue

      next({ name: 'VerifyEmail' })

    }

  } else {

    // user not signed in, redirect to login

    next({ name: 'About' })

  }

}

const requireNoAuth = (to, from, next) => {

  let user = projectAuth.currentUser

  if(user){

    // user is signed in, dont allow access to signup/signin

    next(from)

  } else {

    //user is not signed in, so allow access to signup/signin

    next()

  }

}

const routes = [
  {
    path: '/',
    name: 'MGallery',
    component: MGallery,
    //beforeEnter: requireAuth,  // changed 9/20
    children: [
      {
        path: '/posts/:displayPostId',
        name: 'DisplayPost',
        component: DisplayPost,
        props: true,
        // beforeEnter: requireAuth  // changed 9/21
      }
    ]
  },
  {
    path: '/users/:displayName',
    name: 'Profile',
    component: Profile,
    beforeEnter: requireAuth,
    children: [
      {
        path: '/users/:displayName/:displayProfilePostId',
        name: 'DisplayProfilePost',
        component: DisplayProfilePost,
        props: true,
        beforeEnter: requireAuth
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    // beforeEnter: requireAuth
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    beforeEnter: requireAuth
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    beforeEnter: requireNoAuth
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: requireNoAuth
  },
  {
    path: '/verifyemail',
    name: 'VerifyEmail',
    component: VerifyEmail//,
    // beforeEnter: requireAuth
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload,
    beforeEnter: requireAuth
  },
  // ISSUE want to make this route go to current users profile
  // {
  //   path: '/profile',
  //   name: 'Profile',
  //   component: Profile,
  // },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router