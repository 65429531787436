<template>

<!-- this is profile post container -->

  <div v-if="displayedPostDoc" id="view-post">

    <!-- this is post header -->
    <div id="postHeader">
      <div id="authorAndTitle">
        <div id="authorProfileImageContainer">
          <!-- this is post author image  -->
          <div class="profileImageInnerContainer">
            <!-- author profile img for MGallery and direct post visits (thru post URL) -->
            <img v-if="displayedPostDoc.authorImageURL" :src="displayedPostDoc.authorImageURL"/>
          </div>
        </div>
        <i class="material-icons">account_circle</i>
        <!-- this is post title and author -->
        <div id="titleAndAuthorContainer">
          <h1>{{ displayedPostDoc.postTitle }}</h1>
          <h2>by {{ displayedPostDoc.userAlias }}</h2>
        </div>
      </div>

      <!-- these are critique mode badges/indicators -->
      <div id="modeBadgesContainer">
        <label for="modeBadges">critique mode</label>
        <div id="modeBadges" class="mode">
          <div class="badgeContainer" v-if="displayedPostDoc.imageURLs.length === 1">
            <div class="modeBadge">solo</div>
            <div class="toolTip"><p>One image critique.</p></div>
          </div>  
          <div class="badgeContainer" v-if="displayedPostDoc.imageURLs.length === 2">
            <div class="modeBadge">versus</div>
            <div class="toolTip"><p>Two image comparison critique.</p></div>
          </div>
          <div class="badgeContainer" v-if="!displayedPostDoc.focusedFeedback">
            <div class="modeBadge">broad</div>
            <div class="toolTip"><p>All elements and principles available for critique.</p></div>
          </div>
          <div class="badgeContainer" v-if="displayedPostDoc.focusedFeedback">
            <div class="modeBadge">focused</div>
            <div class="toolTip"><p>Limited elements and principles available for critique.</p></div>
          </div>
        </div>
      </div>
    </div>

    <!-- this is the image stage -->
    <div id="postImageStage" v-bind:class="{ spaceEvenly: !displayedPostDoc.imageURLs[1] }">
      <div v-bind:class="{ halfWidthImg: displayedPostDoc.imageURLs[1] }" class="postImageContainer">
        <img :src="displayedPostDoc.imageURLs[0]" alt="Image One">
        <span v-if="displayedPostDoc.imageURLs[1]" class="aVotes imageIndicator"><span id="aVote">1</span></span>
      </div>
      <div v-if="displayedPostDoc.imageURLs[1]" v-bind:class="{ halfWidthImg: displayedPostDoc.imageURLs[1] }" class="postImageContainer">
        <img :src="displayedPostDoc.imageURLs[1]" alt="Image Two">
        <span class="bVotes imageIndicator"><span id="bVote">2</span></span>
      </div>
    </div>
    
    <!-- this is the interfaces container -->
    <ul id="interfaces">

      <!-- this is the critique interface container -->
      <li id="critiqueInterfaceContainer" class="interfaceContainer" v-bind:class="{ active: true }">
        <div id="critiqueInterface">

          <!-- this is the submit critique stage -->
          <div id="submitCritique">

            <!-- this is the authors description/request -->
            <div id="critiqueBrief"> <!-- need to replace 'unit' materialize css -->
              <div id="critiqueBriefHeader">
                <h3>Critique Brief</h3>
                <i @click="critiqueHelpModalOn = !critiqueHelpModalOn" class="material-icons">help</i>
              </div>
              <p>{{ displayedPostDoc.postDesc }}</p>
            </div>

            <!-- this is the collapsible critique interface / only displays if parent component is MGallery or Upload and feedback has not been submitted -->
            <div id="critiqueSteps" v-if="!feedbackSubmitted && displayedPostDoc.userId !== user.uid || !feedbackSubmitted && $parent.$options.name === 'Upload' ">   <!--  removed on 4/13: && $parent.$options.name === 'MGallery'  -->
              <ul id="collapsibleCritiqueInterface">

                <!-- this is the versus vote stage  -->
                <li id="versusStage" v-if="displayedPostDoc.imageURLs.length === 2" class="collapsibleContainer" v-bind:class="{ active: displayedPostDoc.imageURLs.length === 2 }">
                  <div class="collapsibleHeader" @click="collClick">
                    <span class="collapsibleLabel">
                      <i class="material-icons">looks_one</i>
                      <h2>Which do you like best?</h2>
                    </span>
                    <!-- this is selected image indicator -->
                    <div id="versusSelectionIndicators">
                      <span id="aVote" class="versusSelectionIndicator image-a disabled-icon" >1</span> <!-- v-bind:class="{ enabledIcon: versusImages[0].isSelected }" -->
                      <span id="bVote" class="versusSelectionIndicator image-b disabled-icon" >2</span> <!-- v-bind:class="{ enabledIcon: versusImages[1].isSelected }" -->
                    </div>
                  </div>
                  <div class="collapsibleBody">
                    <div class="bodyContainer">
                      <div>
                        <span @click="selectImage($event), validateDuringInput($event)" id="aVote" class="img-selection-btn image-a" >1</span>
                        or
                        <span @click="selectImage($event), validateDuringInput($event)" id="bVote" class="img-selection-btn image-b" >2</span>
                      </div>
                    </div>
                  </div>
                </li>

                <!-- this is whats working stage -->
                <li id="workingStage" class="collapsibleContainer" v-bind:class="{ active: displayedPostDoc.imageURLs.length === 1 }">
                  <!-- this is working stage header -->
                  <div class="collapsibleHeader" @click="collClick">
                    <span class="collapsibleLabel">
                      <i v-if="displayedPostDoc.imageURLs.length === 2" class="material-icons">looks_two</i>
                      <i v-if="displayedPostDoc.imageURLs.length === 1" class="material-icons">looks_one</i>
                      <h2>What's working?</h2>
                    </span>
                    <!-- this is selected working concept indicator -->
                    <div class="conceptSelectionIndicator">
                      <div class="conceptSelections">
                        <!-- <span v-if="currentlySelectedWorking.includes(tag.name)" :class="tag.name + '-icon'" v-for="(tag, index) in feedbackIcons" :key="index"> -->
                        <div :class="tag.name + '-icon'" v-for="(tag, index) in selectedWorkingConcepts" :key="index">
                          <span class="selectedWorkingTags" v-html="tag.svg"></span>
                        </div>
                      </div>
                      <div v-if="newWorkingNotes.length >= 1" class="newConceptNotes">
                        <span>
                          with {{ newWorkingNotes.length }} notes
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- this is working stage body -->
                  <div class="collapsibleBody">
                    <div class="bodyContainer">
                      <!-- these are voting instructions -->
                      <!-- <span class="directions" v-if="!post.focusedFeedback">Select up to 3 principles and/or elements.</span> -->
                      <!-- <span class="directions" v-if="post.focusedFeedback">Select the principles and/or elements that are working.</span> -->
                      <div class="conceptButtonsContainer">
                        <div class="conceptsButtons">
                          <!-- these are design principle selection buttons -->
                          <label for="workingDesignPrincipleButtons" class="active">Design Principles</label>
                          <div id="workingDesignPrincipleButtons">                                                                                           <!--|| tag.isSelected --> <!-- selectedIcon: tag.isSelected -->
                            <div v-for="(tag, index) in feedbackIcons.slice(0, 7)" :key="index" :id="tag.name + 'WorkingButton'" :data-concept="tag.name" data-status="working" v-bind:class="{ enabledIcon: tag.narrowed || !displayedPostDoc.focusedFeedback }" class="conceptSelectionButton disabled-icon" @click="selectTags($event, 'working'), validateDuringInput($event)">
                              <div v-html="tag.svg"></div>
                              <p>{{ tag.name }}</p>
                              <!-- <i v-if="selectedWorkingTags.includes(tag.name) || selectedNarrowWorkingTags.includes(tag.name)" @click.stop="openNoteModal($event, 'write')" :data-fbtag="tag.name" data-workingStatus="working" class="material-icons">comment</i> -->

                              <svg class="addNote" v-if="selectedWorkingTags.includes(tag.name) || selectedNarrowWorkingTags.includes(tag.name)" @click.stop="openNoteModal($event, 'write')" :data-fbtag="tag.name" data-workingStatus="working" 
                                version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                              <!-- <style type="text/css">
                                .st0{fill:#939598;}
                              </style> -->
                              <path class="st0" d="M28,0H4C1.8,0,0,1.8,0,4v17.6c0,2.2,1.8,4,4,4h22.1L32,32V4C32,1.8,30.2,0,28,0z M22.4,14.4h-4.8v4.8h-3.2v-4.8
                                H9.6v-3.2h4.8V6.4h3.2v4.8h4.8V14.4z"/>
                              </svg>
                              
                            </div>
                          </div>
                        </div>
                        <div class="conceptsButtons">
                          <label for="workingArtElementButtons" class="active">Art Elements</label>
                          <!-- these are art element icons -->
                          <div id="workingArtElementButtons">                                                                                             <!--|| tag.isSelected --> <!-- selectedIcon: tag.isSelected -->
                            <div v-for="(tag, index) in feedbackIcons.slice(7)" :key="index" :id="tag.name + 'WorkingButton'" :data-concept="tag.name" data-status="working" v-bind:class="{ enabledIcon: tag.narrowed || !displayedPostDoc.focusedFeedback }" class="conceptSelectionButton disabled-icon" @click="selectTags($event, 'working'), validateDuringInput($event)">
                              <div v-html="tag.svg"></div>
                              <p>{{ tag.name }}</p>
                              <!-- <i v-if="selectedWorkingTags.includes(tag.name) || selectedNarrowWorkingTags.includes(tag.name)" @click.stop="openNoteModal($event, 'write')" :data-fbtag="tag.name" data-workingStatus="working" class="material-icons">comment</i> -->
                              <svg class="addNote" v-if="selectedWorkingTags.includes(tag.name) || selectedNarrowWorkingTags.includes(tag.name)" @click.stop="openNoteModal($event, 'write')" :data-fbtag="tag.name" data-workingStatus="working" 
                                version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                              <!-- <style type="text/css">
                                .st0{fill:#939598;}
                              </style> -->
                              <path class="st0" d="M28,0H4C1.8,0,0,1.8,0,4v17.6c0,2.2,1.8,4,4,4h22.1L32,32V4C32,1.8,30.2,0,28,0z M22.4,14.4h-4.8v4.8h-3.2v-4.8
                                H9.6v-3.2h4.8V6.4h3.2v4.8h4.8V14.4z"/>
                              </svg>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <!-- this is whats NOT working stage -->
                <li id="notWorkingStage" class="collapsibleContainer">
                  <div class="collapsibleHeader" @click="collClick">
                    <span class="collapsibleLabel">
                      <i v-if="displayedPostDoc.imageURLs.length === 2" class="material-icons">looks_3</i>
                      <i v-if="displayedPostDoc.imageURLs.length === 1" class="material-icons">looks_two</i>
                      <h2>Not working?</h2>
                    </span>
                    <div class="conceptSelectionIndicator">
                      <div class="conceptSelections">
                        <div :class="tag.name + '-icon'" v-for="(tag, index) in selectedNotWorkingConcepts" :key="index">
                          <span class="selectedNotWorkingTags" v-html="tag.svg"></span>
                        </div>
                      </div>
                      <div v-if="newNotWorkingNotes.length >= 1" class="newConceptNotes">
                        <span>
                          with {{ newNotWorkingNotes.length }} notes
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="collapsibleBody">
                    <div class="bodyContainer">
                      <!-- these are voting instructions -->
                      <!-- <span class="directions" v-if="!post.focusedFeedback">Select up to 3 principles and/or elements.</span> -->
                      <!-- <span class="directions" v-if="post.focusedFeedback">Select the principles and/or elements that are not working.</span> -->
                      <div class="conceptButtonsContainer">
                        <div class="conceptsButtons">
                          <label for="notWorkingDesignPrincipleButtons" class="active">Design Principles</label>
                          <!-- these are design principle icons -->
                          <div id="notWorkingDesignPrincipleButtons">
                            <div v-for="(tag, index) in feedbackIcons.slice(0, 7)" :key="index" :id="tag.name + 'NotWorkingButton'" :data-concept="tag.name" data-status="notWorking" v-bind:class="{ enabledIcon: tag.narrowed || !displayedPostDoc.focusedFeedback }" class="conceptSelectionButton disabled-icon" @click="selectTags($event, 'not-working'), validateDuringInput($event)">
                              <div v-html="tag.svg"></div>
                              <p>{{ tag.name }}</p>
                              <i v-if="selectedNotWorkingTags.includes(tag.name) || selectedNarrowNotWorkingTags.includes(tag.name)" @click.stop="openNoteModal($event, 'write')" :data-fbtag="tag.name" data-workingStatus="notWorking" class="material-icons">comment</i>
                            </div>
                          </div>
                        </div>
                        <div class="conceptsButtons">
                          <label for="notWorkingArtElementButtons" class="active">Art Elements</label>
                          <!-- these are art element icons -->
                          <div id="notWorkingArtElementButtons">
                            <div v-for="(tag, index) in feedbackIcons.slice(7)" :key="index" :id="tag.name + 'NotWorkingButton'" :data-concept="tag.name" data-status="notWorking" v-bind:class="{ enabledIcon: tag.narrowed || !displayedPostDoc.focusedFeedback }" class="conceptSelectionButton disabled-icon" @click="selectTags($event, 'not-working'), validateDuringInput($event)">
                              <div v-html="tag.svg"></div>
                              <p>{{ tag.name }}</p>
                              <i v-if="selectedNotWorkingTags.includes(tag.name) || selectedNarrowNotWorkingTags.includes(tag.name)" @click.stop="openNoteModal($event, 'write')" :data-fbtag="tag.name" data-workingStatus="notWorking" class="material-icons">comment</i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <!-- this is add comment stage -->
                <li id="commentStage" class="collapsibleContainer">
                  <div class="collapsibleHeader" @click="collClick">
                    <span class="collapsibleLabel">
                      <i v-if="displayedPostDoc.imageURLs.length === 2" class="material-icons">looks_4</i>
                      <i v-if="displayedPostDoc.imageURLs.length === 1" class="material-icons">looks_3</i>
                      <h2>Add comments</h2>
                    </span>
                  </div>
                  <div class="collapsibleBody">
                    <div class="bodyContainer">
                      <textarea v-on:keyup="validateDuringInput($event)" v-on:click="validateDuringInput($event)" v-on:blur="validateAfterInput" name="new-comment" id="add-comment" class="add-comment" cols="30" rows="10" placeholder="Tell us what you think about the work. What do you like? What do you not like? How might it be improved?"></textarea>
                    </div>
                  </div>
                </li>

              </ul>

              <div v-if="postNotice" id="postNoticeBar" role="alert">
                <h2>{{ postNotice }}</h2>
              </div>

              <!-- these are the submission buttons -->
              <div id="submit-buttons">
                <button @click="submitNewFeedback" v-bind:class="{ disabled: this.unvalidated }" id="submitCritiqueButton" class="btn">Submit</button>
                <button @click="$emit('closePostModal')" id="cancelCritiqueButton" class="gray btn">cancel</button>
              </div>

              <!-- NEED to improve error feedback -->
              <!-- this is the error feedback stage -->
              <p v-if="errorFeedback" class="error-feedback red-text">{{ errorFeedback }}</p>
            </div>

            <!-- this is comments stage, display all post comments after feedback submission -->
            <div v-if="comments.length > 0 || newComment.show" id="commentsContainer">

              <!-- this is comments container -->
              <div v-if="commentsVisible" id="displayedComments">
                <h3>Comments</h3> <!-- removed: {{ comments.length }} because it shows incorrect count when 'newComment' is displayed -->

                <ul>

                  <!-- these are existing comments -->
                  <li v-for="(comment, index) in loadedComments.comments" :key="index" class="comment">
                    <span class="noteAuthor">
                      <div class="critiquerProfileImageContainer">
                        <div class="profileImageInnerContainer"> 
                          <img v-if="commentorImagesLoaded && comment.commentorImageURL" class="profilePhoto" :src="comment.commentorImageURL"/> 
                        </div>
                      </div>
                      <i class="material-icons">account_circle</i>
                      <span class="authorName">{{ comment.author }}</span>
                    </span>
                    <p class="noteBody">
                      {{ comment.body}}
                    </p>
                    <span class="noteDate">
                      {{ comment.date }}
                    </span>
                  </li>

                  <!-- this is newly added comment -->
                  <li v-if="newComment.show" class="comment">
                    <span class="noteAuthor">
                      <div class="critiquerProfileImageContainer">
                        <div class="profileImageInnerContainer"> 
                          <img v-if="user.photoURL" class="profilePhoto" :src="user.photoURL"/>
                        </div>
                      </div>
                      <i class="material-icons">account_circle</i>
                      <span class="authorName">{{ newComment.comment.author }}</span>
                    </span>
                    <p class="noteBody">
                      {{ newComment.comment.body}}
                    </p>
                    <span class="noteDate">
                      {{ newComment.comment.date }}
                    </span>
                  </li>

                </ul>

                <!-- this is load more comments -->
                <div id="loadMoreCommentsContainer">
                  <button v-if="moreCommentsToLoad && commentorImagesLoaded" @click="loadMoreComments" id="loadMoreComments" class="btn">Show More</button>
                </div>

              </div>
              
              <!-- this is show comments button -->
              <div v-if="showCommentToggle" id="toggleCommentsContainer">
                <button @click="toggleComments" id="toggle-comments" class="btn">View Comments</button> 
              </div>

            </div>

          </div>     

          <!-- this is the result stage -->
          <div id="resultsStage">
            <div id="results-stage-header">
              <h3>Results</h3>
            </div>

            <!-- this is the versus results -->
            <div id="versusResults" v-if="this.displayedPostDoc.imageURLs.length === 2">
              <ul>
                <li v-bind:class="{ selected: versusImages[0].isSelected }" class="versusResultsContainer">
                  <span>Image 1</span>
                  <small>{{ this.versusImages[0].pollPercentage }}%, {{ this.displayedPostDoc.aVotes }} votes</small>
                  <div class="versusPollResultsContainer">
                    <div id="aPoll" class="versusPollBar" :style="cssVarsforA()"></div>
                  </div>
                </li>
                <li v-bind:class="{ selected: versusImages[1].isSelected }" class="versusResultsContainer">
                  <span>Image 2</span>
                  <small>{{ this.versusImages[1].pollPercentage }}%, {{ this.displayedPostDoc.bVotes }} votes</small>
                  <div class="versusPollResultsContainer">
                    <div id="bPoll" class="versusPollBar" :style="cssVarsforB()"></div>
                  </div>
                </li>
              </ul>
            </div>

            <span class="directions">Click on any results to view saved notes.</span>

            <!-- this is the working / not working results  -->
            <div id="conceptsResultsContainer">
              <span v-for="(tag, index) in feedbackIcons" :key="index" v-bind:class="{ enabledIcon: tag.narrowed || !displayedPostDoc.focusedFeedback}" class="tag-results disabled-icon">
                <span v-bind:class="{ workingColor: tag.working, notWorkingColor: !tag.working && tag.working != null }" class="tagIconContainer" v-html="tag.svg"></span>
                <div class="tag-polls-container">
                  <div :id="tag.name + '-working-results'" class="tag-poll-results-container">
                    <span class="results">
                      <div class="working-poll-bar" :style="cssVarsforWorkingWidth(index)"></div>
                    </span>
                    <span @click.stop="openNoteModal($event, 'read', tag.workingNoteTotal)"  :data-fbtag="tag.name" v-bind:class="{ noNotes: tag.workingNoteTotal === 0  }" data-workingStatus="working" class="tagData" v-if="tag.workingPercentage != 0">
                      <span class="tagPercentage">{{ tag.workingPercentage }}%</span>
                      with <span class="noteTotal">{{ tag.workingNoteTotal }} notes</span>
                    </span>
                  </div>
                  <div :id="tag.name + '-not-working-results'" class="tag-poll-results-container">
                    <span class="results">
                      <div class="not-working-poll-bar" :style="cssVarsforNotWorkingWidth(index)"></div>
                    </span>
                    <span @click.stop="openNoteModal($event, 'read', tag.notWorkingNoteTotal)"  :data-fbtag="tag.name" v-bind:class="{ noNotes: tag.notWorkingNoteTotal === 0  }" data-workingStatus="notWorking" class="tagData" v-if="tag.notWorkingPercentage != 0">
                      <span class="tagPercentage">{{ tag.notWorkingPercentage }}%</span>
                      with <span class="noteTotal">{{ tag.notWorkingNoteTotal }} notes</span>
                    </span>
                  </div>  
                </div>
              </span>
            </div>

          </div>

        </div>

      </li>

      <!-- this is the edit interface container -->
      <li v-if="displayedPostDoc.userId === user.uid" id="editStage" class="interfaceContainer">     <!-- removed from v-if on 4/13/21: $parent.$options.name === 'MGallery' && -->
        <div id="edit-header">
          <h3>Edit Post</h3>
        </div>
        
          <!-- this is the form to update post -->
          <form class="">
            <div class="field title">
              <label for="title">Title</label>
              <input type="text" id="postTitleInput" class="" name="title" v-model="postTitleInput" v-on:keyup="validateDuringInput($event, 'edit')" v-on:blur="validateAfterInput('edit')">
            </div>
            <div class="field desc">
              <label for="desc">Description</label>
              <input type="text" id="postDescrInput" class="" name="desc" v-model="postDescInput" v-on:keyup="validateDuringInput($event, 'edit')" v-on:blur="validateAfterInput('edit')">
            </div>
          </form>
          <div v-if="editNotice" id="editNoticeBar" role="alert">
            <h2>{{ editNotice }}</h2>
          </div>
      </li>

    </ul>
   
    <!-- this is button stage -->
    <div id="button-stage">

      <button v-if="feedbackSubmitted && displayedPostDoc.userId !== user.uid" @click="resubmitFeedback" id="resubmit-feedback" class="btn">Resubmit Feedback</button>                    

      <button v-if="displayedPostDoc.userId === user.uid && !editing"  @click="expandEditStage" id="edit-post" class="btn">Edit</button>    
      <button v-if="displayedPostDoc.userId === user.uid && editing"  @click="updatePost" id="save-edit" v-bind:class="{ disabled: this.editUnvalidated }" class="btn">Save</button>     
      <div>
        <button v-if="editing" @click="expandEditStage" id="cancel-edit" class="gray btn">Cancel</button>
        <button v-if="displayedPostDoc.userId === user.uid && editing" id="delete-post" @click="deletePost" class="gray btn">Delete</button>     
      </div>
    </div>

    <!-- this is notes modal -->
    <div v-if="noteModalOn" id="noteModal" v-bind:class="{ noteModalOn: noteModalVisible }" class="post-modal" @click.self="closeNoteModal">
      <i @click.self="closeNoteModal" class="material-icons close-modal">close</i>
      <div id="noteModalContent">
        <div id="noteModalHeader">
          <div>
            <span id="noteSubject">{{ tagNotesToDisplay }}: </span>
            <span v-if="workingNotesDisplayed">working</span>
            <span v-if="notWorkingNotesDisplayed">not working</span>
          </div>
           <i class="material-icons">comment</i>
        </div>
        <!-- this is the notes container -->
        <div id="notesContainer">
          <!-- container for 'working' notes -->
          <div id="workingNotesContainer" v-if="workingNotesDisplayed">
            <div class="noteContainer" v-for="(note, index) in workingNotes" :key="index" > 
              <div class="note" v-if="note.tag === tagNotesToDisplay"> <!-- maybe this line and above could be improved by using a computed value to determine which notes to show -->
                <span class="noteAuthor">
                  <div class="critiquerProfileImageContainer">
                    <!-- user profile photo will go here -->
                    <div class="profileImageInnerContainer">
                      <!-- this is where the saved picture will display -->
                      <img v-if="noteImagesLoaded && note.noteImageURL" class="profilePhoto" :src="note.noteImageURL"/> 
                    </div>
                  </div>
                  <i class="material-icons">account_circle</i>
                  <span class="authorName">{{ note.author }}</span>
                </span>
                <p class="noteBody">
                  {{ note.body }}
                </p>
                <span class="noteDate">
                  {{ note.date }}
                </span>
              </div>
            </div>
          </div>
          <!-- container for 'not working' notes -->
          <div id="notWorkingNotesContainer" v-if="notWorkingNotesDisplayed">
            <div class="noteContainer" v-for="(note, index) in notWorkingNotes" :key="index">  <!-- ADD VIF TO DETERMINE WHAT  -->
              <div class="note" v-if="note.tag === tagNotesToDisplay">
                <span class="noteAuthor">
                  <div class="critiquerProfileImageContainer">
                    <!-- user profile photo will go here -->
                    <div class="profileImageInnerContainer">
                      <!-- this is where the saved picture will display -->
                      <img v-if="noteImagesLoaded && note.noteImageURL" class="profilePhoto" :src="note.noteImageURL"/> 
                    </div>
                  </div>
                  <i class="material-icons">account_circle</i>
                  <span class="authorName">{{ note.author }}</span>
                </span>
                <p class="noteBody">
                  {{ note.body}}
                </p>
                <span class="noteDate">
                  {{ note.date }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- this is add note section -->
        <div v-if="noteWrite" id="addNoteContainer">
          <span id="addNoteLabel">Add Note</span>
          <!-- add on key up to validate note -->
          <textarea v-on:keyup="validateDuringInput($event, 'note')" v-on:blur="validateAfterInput('note')" name="addNote" id="addNoteTextarea" cols="30" rows="10" placeholder="Tell us what you think about this element or principle. How might it be improved?"></textarea>
          <div v-if="noteNotice" id="noteNoticeBar" role="alert">
            <h2>{{ noteNotice }}</h2>
          </div>
          <div class="noteButtons">
            <button v-bind:class="{ disabled: this.noteUnvalidated }" class="btn" @click="storeTagNote(), closeNoteModal()">Save</button>
            <button v-if="deleteNoteButton" class="gray btn" @click="deleteTagNote">Delete</button>
          </div>
        </div>
      </div>
    </div>

    <!-- this is help modal -->
    <div v-if="critiqueHelpModalOn" id="critiqueHelpModal" v-bind:class="{ critiqueHelpModalOn: critiqueHelpModalOn }" class="post-modal" @click.self="critiqueHelpModalOn = false">
      <i @click.self="critiqueHelpModalOn = false" class="material-icons close-modal">close</i>
      <div id="critiqueHelpModalContent">
        <div id="modeHelp" class="help">
          <h3>Critique Modes</h3>
          <!-- <p>There are four critique modes.</p> -->
          <ul>
            <li>
              <div class="modeBadge">solo</div>
              <p>One image critique.</p>
            </li>
            <li>
              <div class="modeBadge">versus</div>
              <p>Two image comparison critique.</p>
            </li>
            <li>
              <div class="modeBadge">broad</div>
              <p>All elements and principles available for critique.</p>
            </li>
            <li>
              <div class="modeBadge">focused</div>
              <p>Limited elements and principles available for critique.</p>
            </li>
          </ul>
        </div>
        <div id="briefHelp" class="help">
          <h3>Critique Brief</h3>
          <p>The critique brief is a description of the work and the feedback requested.</p>
        </div>
        <div id="workingHelp" class="help">
          <h3>What's Working or Not?</h3>
          <ul>
            <li>Tell us which elements and principles you think are working or not working.</li>
            <li>In Broad mode you may select up to 3 elements and principles.</li>
            <li>In Focused mode the author determines which elements and priciples are selected.</li>
            <li>You can leave a note on any selection to explain your choice.</li>
          </ul>
        </div>
        <div id="resultsHelp" class="help">
          <h3>Results</h3>
          <ul>
            <li>Critiquers determine which elements and principles are working or not working.</li>
            <li>The results are displayed in green if the majority thinks they are working, otherwise they are displayed in red.</li>
            <li>Critique notes can be viewed by clicking on the displayed results.</li>
          </ul>
        </div>
        <div id="conceptsHelp" class="help">
          <h3>Elements & Principles</h3>
          <div id="conceptsMenu">
            <ul @click="openConceptsMenu">

              <!-- NEED to rewrite code below to use v-for on a object array 
              or add req info to feedbackIcons and use it to load help info -->

              <li  id="balanceHelp" data-concept="balance" class="conceptsMenuItem active">
                <div @click="toggleConcepts" class="menuItemContainer"><div v-html="feedbackIcons[0].svg"></div>Balance</div>
              </li>
              <li  id="contrastHelp" data-concept="contrast" class="conceptsMenuItem">
                <div @click="toggleConcepts" class="menuItemContainer"><div v-html="feedbackIcons[1].svg"></div>Contrast</div>
              </li>
              <li  id="emphasisHelp" data-concept="emphasis" class="conceptsMenuItem">
                <div @click="toggleConcepts" class="menuItemContainer"><div v-html="feedbackIcons[2].svg"></div>Emphasis</div>
              </li>
              <li  id="movementHelp" data-concept="movement" class="conceptsMenuItem">
                <div @click="toggleConcepts" class="menuItemContainer"><div v-html="feedbackIcons[3].svg"></div>Movement</div>
              </li>
              <li  id="patternHelp" data-concept="pattern" class="conceptsMenuItem">
                <div @click="toggleConcepts" class="menuItemContainer"><div v-html="feedbackIcons[4].svg"></div>Pattern</div>
              </li>
              <li  id="rhythmHelp" data-concept="rhythm" class="conceptsMenuItem">
                <div @click="toggleConcepts" class="menuItemContainer"><div v-html="feedbackIcons[5].svg"></div>Rhythm</div>
              </li>
              <li  id="unityHelp" data-concept="unity" class="conceptsMenuItem">
                <div @click="toggleConcepts" class="menuItemContainer"><div v-html="feedbackIcons[6].svg"></div>Unity</div>
              </li>
              <li  id="lineHelp" data-concept="line" class="conceptsMenuItem">
                <div @click="toggleConcepts" class="menuItemContainer"><div v-html="feedbackIcons[0].svg"></div>Line</div>
              </li>
              <li  id="shapeHelp" data-concept="shape" class="conceptsMenuItem">
                <div @click="toggleConcepts" class="menuItemContainer"><div v-html="feedbackIcons[1].svg"></div>Shape</div>
              </li>
              <li  id="formHelp" data-concept="form" class="conceptsMenuItem">
                <div @click="toggleConcepts" class="menuItemContainer"><div v-html="feedbackIcons[2].svg"></div>Form</div>
              </li>
              <li  id="spaceHelp" data-concept="space" class="conceptsMenuItem">
                <div @click="toggleConcepts" class="menuItemContainer"><div v-html="feedbackIcons[3].svg"></div>Space</div>
              </li>
              <li  id="textureHelp" data-concept="texture" class="conceptsMenuItem">
                <div @click="toggleConcepts" class="menuItemContainer"><div v-html="feedbackIcons[4].svg"></div>Texture</div>
              </li>
              <li  id="valueHelp" data-concept="value" class="conceptsMenuItem">
                <div @click="toggleConcepts" class="menuItemContainer"><div v-html="feedbackIcons[5].svg"></div>Value</div>
              </li>
              <li  id="colorHelp" data-concept="color" class="conceptsMenuItem">
                <div @click="toggleConcepts" class="menuItemContainer"><div v-html="feedbackIcons[6].svg"></div>Color</div>
              </li>
            </ul>
          </div>
          <div id="balanceContent" class="conceptContent active">
            Balance is the distribution of the visual weight of elements within your design.
          </div>
          <div id="contrastContent" class="conceptContent">
            Contrast is the arrangement of opposing elements within your design. In other words contrast is the difference between elements within your design.
          </div>
          <div id="emphasisContent" class="conceptContent">
            Emphasis Content
          </div>
          <div id="movementContent" class="conceptContent">
            Movement Content
          </div>
          <div id="patternContent" class="conceptContent">
            Pattern Content
          </div>
          <div id="rhythmContent" class="conceptContent">
            Rhythm Content
          </div>
          <div id="unityContent" class="conceptContent">
            Unity Content
          </div>
          <div id="lineContent" class="conceptContent active">
            Line
          </div>
          <div id="shapeContent" class="conceptContent">
            Shape
          </div>
          <div id="formContent" class="conceptContent">
            Form Content
          </div>
          <div id="spaceContent" class="conceptContent">
            Space Content
          </div>
          <div id="textureContent" class="conceptContent">
            Texture Content
          </div>
          <div id="valueContent" class="conceptContent">
            Value Content
          </div>
          <div id="colorContent" class="conceptContent">
            Color Content
          </div>
          
        </div>
      </div>
    </div>

    <!-- this is the loader animation -->
    <div v-if="showSpinner" id="spinner-wrapper">
      <Spinner />  
    </div>    

  </div>
</template>

<script>

import { projectFirestore } from '@/firebase/config'
import { projectFunctions } from '@/firebase/config'
import Spinner from '@/components/layout/Spinner'

export default {
  name: 'DisplayProfilePost',
  components: {
    Spinner
  },
  computed: {
    // using computed to display which concepts have been selected during submission process
    selectedWorkingConcepts: function (){
      return this.feedbackIcons.filter(concept => {
        return this.currentlySelectedWorking.includes(concept.name)
      })
    },
    selectedNotWorkingConcepts: function (){
      return this.feedbackIcons.filter(concept => {
        return this.currentlySelectedNotWorking.includes(concept.name)
      })
    }
  },
  props: {
    postDocToOpen: Object, // passed this prop from Profile.vue and use for post display (may be full post object or object with post id only)
    user: Object,
  },
  data(){

    // ISSUE go thru all data and remove anything not requried or used

    return {
      showSpinner: false,
      postDocRef: null,
      displayedPostDoc: null,
      authorProfileImageUrl: null,      // updates to true in created() if user has profile picture
      
      postCommentorPool: [],
      returnedCommentorRecords: [],
      commentorImagesLoaded: false,

      postNotePool: [],
      returnedNoteRecords: [],
      noteImagesLoaded: false,

      post: null,                       // post doc data
      narrowedTags: [],                 // array of tags author requested feedback on
      narrowTags: [],
      feedbackIcons: [
        { name: 'balance', svg: '<svg class="feedback-icons" id="icon-balance" data-name="Balance" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Balance</title><rect class="cls-1" width="32" height="32" rx="4" ry="4"/><polygon class="cls-2" points="24 32 8 32 16 24 24 32"/><circle class="cls-3" cx="16" cy="15" r="9"/></svg>',
        tagPercentage: 0, workingPercentage: 0, notWorkingPercentage: 0, working: null,
        narrowed: false, workingNoteTotal: 0, notWorkingNoteTotal: 0 },
        { name: 'contrast', svg: '<svg class="feedback-icons" id="icon-contrast" data-name="Contrast" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Contrast</title><rect class="cls-1" width="32" height="32" rx="4" ry="4"/><path class="cls-2" d="M4,0H16a0,0,0,0,1,0,0V32a0,0,0,0,1,0,0H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z"/><circle class="cls-3" cx="16" cy="16" r="9"/><path class="cls-2" d="M25,16a9,9,0,0,1-9,9V7A9,9,0,0,1,25,16Z"/></svg>', 
        tagPercentage: 0, workingPercentage: 0, notWorkingPercentage: 0, working: null,
        narrowed: false, workingNoteTotal: 0, notWorkingNoteTotal: 0 },
        { name: 'emphasis', svg: '<svg class="feedback-icons" id="icon-emphasis" data-name="Emphasis" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Emphasis</title><rect class="cls-1" width="32" height="32" rx="4" ry="4"/><circle class="cls-2" cx="16" cy="14" r="8"/><rect class="cls-3" x="8" y="24" width="16" height="4"/></svg>',
        tagPercentage: 0, workingPercentage: 0, notWorkingPercentage: 0, working: null,
        narrowed: false, workingNoteTotal: 0, notWorkingNoteTotal: 0 },
        { name: 'movement', svg: '<svg class="feedback-icons" id="icon-movement" data-name="Movement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Movement</title><rect class="cls-1" width="32" height="32" rx="4" ry="4"/><path class="cls-2" d="M23.2,18.16a1.22,1.22,0,0,0-.59,1.42c.37,1.22.32,2.22-.25,2.79s-1.57.63-2.79.25a1.22,1.22,0,0,0-1.42.59C17.56,24.33,16.81,25,16,25s-1.56-.67-2.16-1.8a1.22,1.22,0,0,0-1.42-.59c-1.22.37-2.22.32-2.79-.25S9,20.8,9.38,19.57a1.22,1.22,0,0,0-.59-1.42C7.67,17.56,7,16.81,7,16s.67-1.56,1.8-2.16a1.22,1.22,0,0,0,.59-1.42c-.37-1.22-.32-2.22.25-2.79S11.2,9,12.43,9.38a1.22,1.22,0,0,0,1.42-.59C14.44,7.67,15.19,7,16,7s1.56.67,2.16,1.8a1.22,1.22,0,0,0,1.42.59c1.22-.37,2.22-.32,2.79.25s.63,1.57.25,2.79a1.22,1.22,0,0,0,.59,1.42c1.13.6,1.8,1.35,1.8,2.16S24.33,17.56,23.2,18.16Z"/></svg>',
        tagPercentage: 0, workingPercentage: 0, notWorkingPercentage: 0, working: null,
        narrowed: false, workingNoteTotal: 0, notWorkingNoteTotal: 0 },
        { name: 'pattern', svg: '<svg class="feedback-icons" id="icon-pattern" data-name="Patterns" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Pattern</title><rect class="cls-1" width="32" height="32" rx="4" ry="4"/><circle class="cls-2" cx="8" cy="25" r="2"/><circle class="cls-2" cx="16" cy="25" r="2"/><circle class="cls-2" cx="24" cy="25" r="2"/><circle class="cls-2" cx="8" cy="7" r="2"/><circle class="cls-2" cx="16" cy="7" r="2"/><circle class="cls-2" cx="24" cy="7" r="2"/><circle class="cls-2" cx="8" cy="16" r="2"/><circle class="cls-2" cx="16" cy="16" r="2"/><circle class="cls-2" cx="24" cy="16" r="2"/></svg>',
        tagPercentage: 0, workingPercentage: 0, notWorkingPercentage: 0, working: null,
        narrowed: false, workingNoteTotal: 0, notWorkingNoteTotal: 0 },
        { name: 'rhythm', svg: '<svg class="feedback-icons" id="icon-rhythm" data-name="Rhythm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Rhythm</title><rect class="cls-1" width="32" height="32" rx="4" ry="4"/><circle class="cls-2" cx="16" cy="16" r="11"/><circle class="cls-3" cx="16" cy="16" r="9"/><circle class="cls-4" cx="16" cy="16" r="7"/><circle class="cls-3" cx="16" cy="16" r="5"/><circle class="cls-2" cx="16" cy="16" r="3"/></svg>',
        tagPercentage: 0, workingPercentage: 0, notWorkingPercentage: 0, working: null,
        narrowed: false, workingNoteTotal: 0, notWorkingNoteTotal: 0 },
        { name: 'unity', svg: '<svg class="feedback-icons" id="icon-unity" data-name="Unity" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Unity</title><rect class="cls-1" width="32" height="32" rx="4" ry="4"/><circle class="cls-2" cx="20" cy="16" r="7"/><circle class="cls-2" cx="12" cy="16" r="7"/><path class="cls-3" d="M19,16a7,7,0,0,1-3,5.74,7,7,0,0,1,0-11.48A7,7,0,0,1,19,16Z"/><path class="cls-3" d="M19,16a7,7,0,0,1-3,5.74,7,7,0,0,1,0-11.48A7,7,0,0,1,19,16Z"/></svg>',
        tagPercentage: 0, workingPercentage: 0, notWorkingPercentage: 0, working: null,
        narrowed: false, workingNoteTotal: 0, notWorkingNoteTotal: 0 },
        { name: 'line', svg: '<svg class="feedback-icons" id="icon-line" data-name="Line" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Line</title><rect class="cls-1" width="32" height="32" rx="4"/><line class="cls-2" x1="8.63" y1="23.38" x2="23.38" y2="8.63"/></svg>',
        tagPercentage: 0, workingPercentage: 0, notWorkingPercentage: 0, working: null,
        narrowed: false, workingNoteTotal: 0, notWorkingNoteTotal: 0 },
        { name: 'shape', svg: '<svg class="feedback-icons" id="icon-shape" data-name="Shape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Shape</title><rect class="cls-1" width="32" height="32" rx="4"/><circle class="cls-2" cx="16" cy="16" r="9"/><polygon class="cls-1" points="16 6.1 7.34 21.1 24.66 21.1 16 6.1"/></svg>',
        tagPercentage: 0, workingPercentage: 0, notWorkingPercentage: 0, working: null,
        narrowed: false, workingNoteTotal: 0, notWorkingNoteTotal: 0 },
        { name: 'form', svg: '<svg class="feedback-icons" id="icon-form" data-name="Form" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Form</title><rect class="cls-1" width="32" height="32" rx="4"/><circle class="cls-2" cx="16" cy="16" r="9"/><path class="cls-1" d="M20.88,11.35a2.33,2.33,0,0,1-.11.31,31.88,31.88,0,0,1-2.6,5.59c-.35.61-.71,1.2-1.07,1.76a28.66,28.66,0,0,1-2.69,3.55c-1.09,1.18-1.95,1.78-2.61,1.8a1.64,1.64,0,0,1-.06-1l0,0c.42.08,2-1.25,4-4.1.5-.73,1-1.57,1.58-2.52a30.52,30.52,0,0,0,2.57-5.51c.48-1.46.57-2.39.38-2.6h0a.71.71,0,0,0-.3.09,4.47,4.47,0,0,1,1.25-.5C21.45,8.83,21.35,9.86,20.88,11.35Z"/><path class="cls-3" d="M17.25,13.83a31.64,31.64,0,0,0-5.9-2.71c-2-.63-3.16-.59-3.58.13a1,1,0,0,0-.1.74c.24,1.24,2.17,2.92,4.21,4.35a2.48,2.48,0,0,0,.44-.91c-2.58-1.83-3.78-3.28-3.69-3.67l.06,0a.86.86,0,0,1,.37-.06c1.06,0,3.87.84,7.69,3A26.25,26.25,0,0,1,22.59,19c.56.59.81,1,.77,1.22-.32.28-2.22-.05-5.14-1.4a3.1,3.1,0,0,0-.74.75,25.67,25.67,0,0,0,3.17,1.28,7.68,7.68,0,0,0,2.26.44,2.11,2.11,0,0,0,.7-.1,1.07,1.07,0,0,0,.62-.47C25.21,19,20.31,15.6,17.25,13.83Z"/><path class="cls-1" d="M18.22,18.85a3.1,3.1,0,0,0-.74.75c-.89-.42-1.81-.9-2.73-1.43S12.87,17,11.88,16.34a2.48,2.48,0,0,0,.44-.91c.83.59,1.81,1.22,2.93,1.87S17.32,18.44,18.22,18.85Z"/><path class="cls-3" d="M20.75,7.77C20,7.36,19,7.91,17.59,9.44a31.54,31.54,0,0,0-3.76,5.31,31.64,31.64,0,0,0-2.71,5.9c-.63,2-.59,3.16.13,3.58a1,1,0,0,0,.51.13h0a1.64,1.64,0,0,1-.06-1c-.33-.5.37-3.6,3-8.09,2.08-3.61,4-5.71,5-6.37l.23-.15a4.47,4.47,0,0,1,1.25-.5A1,1,0,0,0,20.75,7.77Z"/><path class="cls-1" d="M12.32,15.43a2.48,2.48,0,0,1-.44.91c-2-1.43-4-3.11-4.21-4.35a3.41,3.41,0,0,1,1-.27l-.06,0C8.54,12.15,9.74,13.6,12.32,15.43Z"/><path class="cls-1" d="M23.61,21.19v0a2.11,2.11,0,0,1-.7.1,7.68,7.68,0,0,1-2.26-.44,25.67,25.67,0,0,1-3.17-1.28,3.1,3.1,0,0,1,.74-.75c2.92,1.35,4.82,1.68,5.14,1.4,0-.2-.21-.63-.77-1.22A2.86,2.86,0,0,1,23.61,21.19Z"/></svg>',
        tagPercentage: 0, workingPercentage: 0, notWorkingPercentage: 0, working: null,
        narrowed: false, workingNoteTotal: 0, notWorkingNoteTotal: 0 },
        { name: 'space', svg: '<svg class="feedback-icons" id="icon-space" data-name="Space" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Space</title><rect class="cls-1" width="32" height="32" rx="4"/><circle class="cls-2" cx="10.38" cy="11.08" r="3.38"/><circle class="cls-2" cx="21.62" cy="11.08" r="3.38"/><circle class="cls-2" cx="16" cy="20.92" r="3.38"/></svg>',
        tagPercentage: 0, workingPercentage: 0, notWorkingPercentage: 0, working: null,
        narrowed: false, workingNoteTotal: 0, notWorkingNoteTotal: 0 },
        { name: 'texture', svg: '<svg class="feedback-icons" id="icon-texture" data-name="Texture" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Texture</title><rect class="cls-1" width="32" height="32" rx="4"/><circle class="cls-2" cx="10.38" cy="11.08" r="3.38"/><circle class="cls-2" cx="21.62" cy="11.08" r="3.38"/><circle class="cls-2" cx="16" cy="20.92" r="3.38"/></svg>',
        tagPercentage: 0, workingPercentage: 0, notWorkingPercentage: 0, working: null,
        narrowed: false, workingNoteTotal: 0, notWorkingNoteTotal: 0 },
        { name: 'value', svg: '<svg class="feedback-icons" id="icon-value" data-name="Value" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32"><defs><clipPath id="clip-path"><rect class="cls-1" width="32" height="32" rx="4"/></clipPath></defs><title>Value</title><rect class="cls-1" width="32" height="32" rx="4"/><g class="cls-2"><rect class="cls-3" y="-10.5" width="8" height="48"/><rect class="cls-4" x="8" y="-8.5" width="8" height="48"/><rect class="cls-5" x="16" y="-10.5" width="8" height="48"/><rect class="cls-6" x="24" y="-9.5" width="8" height="48"/></g></svg>',
        tagPercentage: 0, workingPercentage: 0, notWorkingPercentage: 0, working: null,
        narrowed: false, workingNoteTotal: 0, notWorkingNoteTotal: 0 },
        { name: 'color', svg: '<svg class="feedback-icons" id="icon-color" data-name="Color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs></defs><title>Color</title><rect class="cls-1" width="32" height="32" rx="4"/><circle class="cls-2" cx="11" cy="12.67" r="6.67"/><circle class="cls-3" cx="21" cy="12.67" r="6.67"/><circle class="cls-4" cx="16" cy="19.33" r="6.67"/></svg>',
        tagPercentage: 0, workingPercentage: 0, notWorkingPercentage: 0, working: null,
        narrowed: false, workingNoteTotal: 0, notWorkingNoteTotal: 0 },
      ],
      selectedWorkingTags: [],          // stores broad working tags as user selects and deselects them
      selectedNotWorkingTags: [],       // stores broad not-working tags as user selects and deselects them
      selectedNarrowWorkingTags: [],    // stores narrow working tags as user selects and deselects them
      selectedNarrowNotWorkingTags: [], // stores narrow not-working tags as user selects and deselects them
      currentlySelectedWorking: [],     // used to display tag icons in collapsibleHeader as they are selected
      currentlySelectedNotWorking: [],  // used to display tag icons in collapsibleHeader as they are selected
      versusImages: [
        { vote: 'aVote', isSelected: false, pollPercentage: 0},
        { vote: 'bVote', isSelected: false, pollPercentage: 0}
      ],
      voteSelection: null,
      errorFeedback: null,
      modalMode: null,
      noteModalOn: false,
      noteModalVisible: false, 
      selectedTag: null,
      noteWrite: false,
      noteAdded: false,
      currentNoteTotal: null, 
      savedNotes: [],
      deleteNoteButton: false,
      newWorkingNotes: [],
      newNotWorkingNotes: [],
      tagNotesToDisplay: '',
      workingNotesDisplayed: false, 
      notWorkingNotesDisplayed: false,
      workingNotes: [],
      notWorkingNotes: [],
      feedbackSubmitted: false,
      commentsVisible: false,
      comments: [],
      loadedComments: {
        comments: [],
        sliceStart: 0,
        sliceEnd: 0,
        groupsLoaded: 0
      },
      moreCommentsToLoad: false,
      showCommentToggle: false,
      editing: false,
      newComment: {
        comment: null,
        show: false
      },
      noteDoc: null,
      critiqueHelpModalOn: false,
      conceptButtonsDisabled: false,

      // Used to populate post edit inputs

      postTitleInput: null,
      postDescInput: null,

      // Used to enable/disable save/submit buttons for client side validation of data

      unvalidated: true,        // used for feedback submission
      noteUnvalidated: true,    // used for notes during feedback submission
      editUnvalidated: false,    // used for post editing/updating

      // Notices displayed to user for improved user experience
      
      postNotice: '',           // displayed during critique process
      noteNotice: '',           // displayed during add note process
      editNotice: ''            // displayed during edit post process
    }
  },
  methods: {
    cssVarsforA()                   {return {'--width': this.versusImages[0].pollPercentage + '%'}},
    cssVarsforB()                   {return {'--width': this.versusImages[1].pollPercentage + '%'}},
    cssVarsforWorkingWidth(index) {
      // return {'--width': index + '%'}
      return {'--width': this.feedbackIcons[index].workingPercentage + '%'}
    },
    cssVarsforNotWorkingWidth(index) {
      // return {'--width': index + '%'}
      return {'--width': this.feedbackIcons[index].notWorkingPercentage + '%'}
    },
    // handle clicks on collapsible feedback walk thru process
    collClick(e) {
      let collList = document.getElementsByClassName('collapsibleContainer')
      console.log('this is the collList: ', collList)
      for (var i = 0; i < collList.length; i++) {
        if(collList[i].classList.contains('active')){
          collList[i].classList.remove('active')
        }
      }
      e.currentTarget.parentNode.classList.toggle('active')
    },
    // handle clicks on collapsible feedback walk thru process
    expandEditStage(e) {
      this.editing = true
      let collStages = document.getElementsByClassName('interfaceContainer')
      //check if interaction stage is active
      if(collStages[0].classList.contains('active')){
        // ineractions stage is active so collapse, then expand edit stage
        collStages[0].classList.remove('active')
        collStages[1].classList.add('active')
      } else {
        collStages[0].classList.add('active')
        collStages[1].classList.remove('active')
        this.editing = false
      }
    },
    openNoteModal(e, attributes, noteTotal) {
      console.log('open note modal for this e.currentTarget: ', e.currentTarget)
        
      // make modal visible only after all data is setup
      this.noteModalVisible = false
      // set value of selectedTag 
      this.selectedTag = e.currentTarget

      console.log('this is the noteTotal: ', noteTotal)
      this.currentNoteTotal = noteTotal

      const setupNoteModal = () => {
        console.log('tracker 7')
        // setup the profile images for commentors
        if(this.returnedCommentorRecords.data){
          console.log('tracker 8')
          // this code block runs after post has had at least 1 feedback submission
          if(this.notWorkingNotes != undefined){
            console.log('tracker 9')
            this.notWorkingNotes.forEach((note, index) => {
              this.returnedCommentorRecords.data.forEach((record, index) => {
                if(note.uid === record.uid){
                  note.noteImageURL = record.photoURL
                  note.author = record.displayName
                }
              })
            })
          }
          if(this.workingNotes != undefined){
            console.log('tracker 10')
            this.workingNotes.forEach((note, index) => {
              this.returnedCommentorRecords.data.forEach((record, index) => {
                if(note.uid === record.uid){
                  note.noteImageURL = record.photoURL
                  note.author = record.displayName
                }
              })
            })
          }
        } else if(!this.returnedCommentorRecords.data){
          console.log('tracker 11')
          // this code block runs if post has no previous feedback submissions
          // if there is no other feedback, then we know any notes that need to be displayed
          // are from the current so we dont need to match photo urls with this.returnedCommentorRecords
          // this code block runs after post has had at least 1 feedback submission
          if(this.notWorkingNotes != undefined){
            console.log('tracker 12')
            this.notWorkingNotes.forEach((note, index) => {
              note.noteImageURL = this.user.photoURL
              note.author = this.user.displayName
            })
          }
          if(this.workingNotes != undefined){
            console.log('tracker 13')
            this.workingNotes.forEach((note, index) => {
              note.noteImageURL = this.user.photoURL
              note.author = this.user.displayName
            })
          }
        }

        this.noteImagesLoaded = true

        // maybe instead of saving tag to selectedTag.id.... add name attribute to spans and use that to store tags, so no duplicate ids

        // if(this.selectedTag.id.includes('-not-working-notes')){ // how about adding class 'not-working-notes' then check if class list includes it?
        // if(this.selectedTag.classList.contains('notWorking')){
        if(this.selectedTag.getAttribute('data-workingStatus') === 'notWorking'){
        console.log('tracker 14 xxx it worked')
          this.tagNotesToDisplay = this.selectedTag.getAttribute('data-fbtag')// if we add class like above, no need to slice here
          console.log('this is the tag to display: ', this.tagNotesToDisplay)
          this.modalMode = 'not-working'
          this.notWorkingNotesDisplayed = true
          this.workingNotesDisplayed = false
        } else if(this.selectedTag.getAttribute('data-workingStatus') === 'working'){
          console.log('tracker 15 xxx it worked')
          // store tag without '-working-notes' appended
          this.tagNotesToDisplay = this.selectedTag.getAttribute('data-fbtag')
          console.log('this is the tag to display: ', this.tagNotesToDisplay)
          this.modalMode = 'working'
          this.workingNotesDisplayed = true
          this.notWorkingNotesDisplayed = false
        }

        this.deleteNoteButton = false
            
        if(attributes === 'read'){
          this.noteWrite = false
          console.log('the notes are read only')
        } else if(attributes === 'write'){
          console.log('the notes are writable')
          this.noteWrite = true
          console.log('tracker 1')
          if(this.modalMode === 'working' && this.newWorkingNotes.length >= 1){
            console.log('tracker 2')
            this.newWorkingNotes.forEach((item, index) => {
              if(item.uid === this.user.uid && item.tag === this.tagNotesToDisplay){
                this.$nextTick(() => {
                  document.getElementById('addNoteTextarea').value = item.body
                  this.deleteNoteButton = true
                })
              }
            })
          } else if(this.modalMode === 'not-working' && this.newNotWorkingNotes.length >= 1){
            console.log('tracker 3')
            this.newNotWorkingNotes.forEach((item, index) => {
              if(item.uid === this.user.uid && item.tag === this.tagNotesToDisplay){
                this.$nextTick(() => {
                  document.getElementById('addNoteTextarea').value = item.body
                  this.deleteNoteButton = true
                })
              }
            })
          } else {
            console.log('tracker 4')
            this.$nextTick(() => {
              document.getElementById('addNoteTextarea').value = ''
            })
          }
        }


        // the code below is messing up the note modal from opening correctly
        // the code below should only run if 
        console.log('tracker 16')
        if(this.selectedTag.getAttribute('data-workingStatus') === 'working' && attributes === 'read'){
          console.log('tracker 17 xxx it worked')
          if(this.workingNotes.some(note => note.tag === this.selectedTag.getAttribute('data-fbtag'))){
            console.log('tracker 18')
            this.noteModalVisible = true
          } else {
            if(!this.noteModalOn) {
              console.log('tracker 19')
              console.log('turn note modal on')
              this.noteModalOn = true
            } else { // do we need the else statement here?
              console.log('tracker 20')
              console.log('turn note modal off')
              this.noteModalOn = false
            }
          }
        }

        if(this.selectedTag.getAttribute('data-workingStatus') === 'notWorking' && attributes === 'read'){
          console.log('tracker 21 xxx it worked')
          if(this.notWorkingNotes.some(note => note.tag === this.selectedTag.getAttribute('data-fbtag'))){
            console.log('tracker 22')
            this.noteModalVisible = true
          } else {
            if(!this.noteModalOn) {
              console.log('tracker 23')
              console.log('turn note modal on')
              this.noteModalOn = true
            } else { // do we need the else statement here?
            console.log('tracker 24')
            console.log('turn note modal off')
              this.noteModalOn = false
            }
          }
        }

        if(attributes === 'write'){
          console.log('tracker 25')
          this.noteModalVisible = true
        }

      }


      console.log('@@@@@@@@@@@: these are the new notes: ', this.newWorkingNotes.length, this.newNotWorkingNotes.length)
      // if note is already saved, then it has the selectedNote class
      // open modal even if 3 notes are already saved so user can edit or delete it
      if(this.selectedTag.classList.contains('results') ||
         this.selectedTag.classList.contains('selectedNote') || 
         attributes === 'read' ||
         this.newWorkingNotes.length + this.newNotWorkingNotes.length < 6 ){ //change to totalNewVotes
        console.log('tracker A')
        // modal must be on before .then otherwise the code inside .then will not be able to .getElementById
        if(!this.noteModalOn) {
          console.log('turn note modal on')
          this.noteModalOn = true
        } else { // do we need the else statement here?
        console.log('turn note modal off')
          this.noteModalOn = false
        }

        setupNoteModal()

        
      } else {
        // do not open note modal
        // set error feedback
        this.errorFeedback = 'You can not leave make more than 3 notes.'
      }

    },
    closeNoteModal() {
      // this.noteModalVisible = false
      this.noteModalOn = false
      this.noteWrite = false
      this.noteImagesLoaded = false
      this.noteUnvalidated = true
      this.noteNotice = ''
      // console.log('check if note was added: ', this.noteAdded)
      if(this.noteAdded){
        // console.log('this is the tag to add classList to: ', this.selectedTag)
        this.selectedTag.classList.add('selectedNote')
        // reset this.noteAdded
        this.noteAdded = false
      }
    },
    // handle feedback tag selection and display in collapsibleHeader
    selectTags(e, workingStatus) {
      let selectedTags = null
      let currentlySelectedTags = null
      // let working = false

      let conceptName = e.currentTarget.getAttribute('data-concept')
      // console.log('This is the concept name to disable sister with: ', conceptName)

      // check if workingStatus is working for broad posts
      if(workingStatus === 'working' && !this.displayedPostDoc.focusedFeedback){
        // working = true
        selectedTags = this.selectedWorkingTags
        currentlySelectedTags = this.currentlySelectedWorking
      // check if workingStatus is not-working for broad posts
      } else if(workingStatus === 'not-working' && !this.displayedPostDoc.focusedFeedback){
        // working = false
        selectedTags = this.selectedNotWorkingTags
        currentlySelectedTags = this.currentlySelectedNotWorking
      // check if workingStatus is working for narrow posts
      } else if(workingStatus === 'working' && this.displayedPostDoc.focusedFeedback){
        // working = true
        selectedTags = this.selectedNarrowWorkingTags
        currentlySelectedTags = this.currentlySelectedWorking
        // check if workingStatus is not-working for narrow posts
      } else if(workingStatus === 'not-working' && this.displayedPostDoc.focusedFeedback){
        // working = false
        selectedTags = this.selectedNarrowNotWorkingTags
        currentlySelectedTags = this.currentlySelectedNotWorking
      }

      // check if narrow mode is off and currentTarget is not already selected and less than 3 tags have been selected
      if(!this.displayedPostDoc.focusedFeedback && !e.currentTarget.classList.contains('selectedIcon') && selectedTags.length < 3) { // changed this.selectedWorkingTags.length to selectedTags.length
        // reset error feedback
        // this.errorFeedback = null
        // add selectedIcon style to selected tag
        e.currentTarget.classList.add('selectedIcon');

        // NEED to disable this icons sister icon in working or not working


        // add principle to selectedWorkingTags array   
        selectedTags.push(e.currentTarget.getAttribute('data-concept')); // on 11/2 I changed all occurences of e.currentTarget.id with e.currentTarget.getAttribute('data-concept')

        // check if selected tag is not already stored in currentlySelectedtags
        if(!currentlySelectedTags.includes(e.currentTarget.getAttribute('data-concept'))){  
          // add selected tag to currentlySelectedtags
          currentlySelectedTags.push(e.currentTarget.getAttribute('data-concept')) 
        } else {
          // remove selected tag from currentlySelectedWorking
          currentlySelectedTags = currentlySelectedTags.filter(tag => { 
            return tag != e.currentTarget.getAttribute('data-concept')
          })
        }

        // make sister tag disabled (cant select same tag for working and not working)
        // let conceptName = e.currentTarget.getAttribute('data-concept')
        // console.log('This is the concept name to disable sister with: ', conceptName)
        if(workingStatus === 'working'){
          // selected tag is working so disable sister not working tag
          let notWorkingStage = document.getElementById('notWorkingStage')
          // let notWorkingTag = notWorkingStage.querySelector(e.currentTarget.getAttribute('data-concept'))
          let notWorkingTag = notWorkingStage.querySelector(`div[data-concept=${CSS.escape(conceptName)}]`)
          // console.log('this is notWorkingTag: ', notWorkingTag)
          notWorkingTag.classList.remove('enabledIcon') // remove enabledIcon class
        } else if(workingStatus === 'not-working') {
          // selected tag is not working so disable sister working tag
          let workingStage = document.getElementById('workingStage')
          // let workingTag = workingStage.querySelector(e.currentTarget.getAttribute('data-concept'))
          let workingTag = workingStage.querySelector(`div[data-concept=${CSS.escape(conceptName)}]`)
          // console.log('this is workingTag: ', workingTag)
          workingTag.classList.remove('enabledIcon') // remove enabledIcon class
        }

        // toggle 'conceptSelectionButtonDisabled' class to style cursor from pointer to arrow 
        let workingStage = document.getElementById('workingStage')
        let notWorkingStage = document.getElementById('notWorkingStage')

        if(workingStage.contains(e.currentTarget)){
          // console.log('the user is at the working stage')
          if(selectedTags.length === 3){
            let workingConceptButtons = workingStage.getElementsByClassName('conceptSelectionButton')
            for (var i = 0; i <  workingConceptButtons.length; i++) {
              if(!workingConceptButtons[i].classList.contains('selectedIcon')){
                 workingConceptButtons[i].classList.add('conceptSelectionButtonDisabled')
              }
            }
          }
        } else {
          // console.log('the user is at the not working stage')
          if(selectedTags.length === 3){
            let notWorkingConceptButtons = notWorkingStage.getElementsByClassName('conceptSelectionButton')
            for (var i = 0; i < notWorkingConceptButtons.length; i++) {
              if(!notWorkingConceptButtons[i].classList.contains('selectedIcon')){
                notWorkingConceptButtons[i].classList.add('conceptSelectionButtonDisabled')
              }
            }
          }
        }

        // autoCollapse()

      // check if narrow mode is off and currentTarget is not already selected and 3 or more have been selected
      } else if(!this.displayedPostDoc.focusedFeedback && !e.currentTarget.classList.contains('selectedIcon') && selectedTags.length >= 3) {
        // this.errorFeedback = 'Please select no more than 3 tags'
        // setTimeout(() => { this.errorFeedback = null }, 2000)

      // check if narrow mode if off (current target must be selected at this point)
      } else if(!this.displayedPostDoc.focusedFeedback) {
        // this.errorFeedback = null


        // toggle 'conceptSelectionButtonDisabled' class to style cursor from pointer to arrow 
        let workingStage = document.getElementById('workingStage')
        let notWorkingStage = document.getElementById('notWorkingStage')

        if(workingStage.contains(e.currentTarget)){
          // console.log('the user is at the working stage')
          if(selectedTags.length === 3){
            // console.log('there are 3 working tags, so remove this one and add pointer back')
            let workingConceptButtons = workingStage.getElementsByClassName('conceptSelectionButton')
            for (var i = 0; i <  workingConceptButtons.length; i++) {
              if(workingConceptButtons[i].classList.contains('conceptSelectionButtonDisabled')){
                 workingConceptButtons[i].classList.remove('conceptSelectionButtonDisabled')
              }
            }
          }
        } else {
          // console.log('the user is at the not working stage')
          if(selectedTags.length === 3){
            // console.log('there are 3 NOT working tags, so remove this one and add pointer back')
            let notWorkingConceptButtons = notWorkingStage.getElementsByClassName('conceptSelectionButton')
            for (var i = 0; i < notWorkingConceptButtons.length; i++) {
              if(notWorkingConceptButtons[i].classList.contains('conceptSelectionButtonDisabled')){
                notWorkingConceptButtons[i].classList.remove('conceptSelectionButtonDisabled')
              }
            }
          }
        }

        // remove selectedIcon style from tag icon
        e.currentTarget.classList.remove('selectedIcon');
        // console.log('remove selected icon class #1')



        // reset stored note for the deselected tag
        this.newWorkingNotes.forEach((item, index) => {
          if(item.tag === e.currentTarget.getAttribute('data-concept')){
            // console.log('this item from newWorkingNotes has a matching tag: ', item)
            // console.log('this is the items tag: ', item.tag)
            //remove object in newWorkingNotes that contains e.currentTarget.getAttribute('data-concept')
            this.newWorkingNotes = this.newWorkingNotes.filter(note => {
              return note.tag != e.currentTarget.getAttribute('data-concept')
            })
          }
        })
        this.newNotWorkingNotes.forEach((item, index) => {
          if(item.tag === e.currentTarget.getAttribute('data-concept')){
            //remove object in newWorkingNotes that contains e.currentTarget.getAttribute('data-concept')
            this.newNotWorkingNotes = this.newNotWorkingNotes.filter(note => {
              return note.tag != e.currentTarget.getAttribute('data-concept')
            })
          }
        })

        // use filter to remove selected tag from selectedTags array
        selectedTags = selectedTags.filter(tag => {
          return tag != e.currentTarget.getAttribute('data-concept');
        })
        // use filter to remove selected tag from currentlySelectedWorking
        currentlySelectedTags = currentlySelectedTags.filter(tag => {
          return tag != e.currentTarget.getAttribute('data-concept')
        })

        // enable sister tag if tag is unselected (now sister tag is available)

        // let conceptName = e.currentTarget.getAttribute('data-concept')
        // console.log('This is the concept name to disable sister with: ', conceptName)
        // set new values stored in local variables to vue data
        if(workingStatus === 'working'){

          // selected tag is working so enable sister not working tag
          let notWorkingStage = document.getElementById('notWorkingStage')
          let notWorkingTag = notWorkingStage.querySelector(`div[data-concept=${CSS.escape(conceptName)}]`)
          notWorkingTag.classList.add('enabledIcon')

          this.selectedWorkingTags = selectedTags
          this.currentlySelectedWorking = currentlySelectedTags

        } else if(workingStatus === 'not-working'){

          // selected tag is not-working so enable sister working tag
          let workingStage = document.getElementById('workingStage')
          let workingTag = workingStage.querySelector(`div[data-concept=${CSS.escape(conceptName)}]`)
          workingTag.classList.add('enabledIcon')

          this.selectedNotWorkingTags = selectedTags
          this.currentlySelectedNotWorking = currentlySelectedTags
        }
      }

      // check if narrow mode is on and currentTarget is not already selected and less than (total of narrow tags) tags have been selected
      if(this.displayedPostDoc.focusedFeedback && !e.currentTarget.classList.contains('selectedIcon') && selectedTags.length < this.narrowedTags.length){ // && selectedTags.length < this.narrowedTags.length
        // reset error feedback
        // this.errorFeedback = null
        // add selectedIcon style to selected tag
        e.currentTarget.classList.add('selectedIcon')
        // add selected tag to selectedNarrowWorkingTags array
        selectedTags.push(e.currentTarget.getAttribute('data-concept'))
        // check if selected tag is not already stored in currentlySelectedtags
        if(!currentlySelectedTags.includes(e.currentTarget.getAttribute('data-concept'))){  
          // add selected tag to currentlySelectedtags
          currentlySelectedTags.push(e.currentTarget.getAttribute('data-concept')) 
        } else {
          // remove selected tag from currentlySelectedWorking
          currentlySelectedTags = currentlySelectedTags.filter(tag => { 
            return tag != e.currentTarget.getAttribute('data-concept')
          })
        }

        // let conceptName = e.currentTarget.getAttribute('data-concept')
        // console.log('This is the concept name to disable sister with: ', conceptName)
        // make sister tag disabled (cant select same tag for working and not working)
        if(workingStatus === 'working'){
          // selected tag is working so disable sister not working tag
          let notWorkingStage = document.getElementById('notWorkingStage')
          let notWorkingTag = notWorkingStage.querySelector(`div[data-concept=${CSS.escape(conceptName)}]`)
          notWorkingTag.classList.remove('enabledIcon') // remove enabledIcon class
        } else if(workingStatus === 'not-working') {
          // selected tag is not working so disable sister working tag
          let workingStage = document.getElementById('workingStage')
          let workingTag = workingStage.querySelector(`div[data-concept=${CSS.escape(conceptName)}]`)
          workingTag.classList.remove('enabledIcon') // remove enabledIcon class
        }

        if(selectedTags.length === 3) {
          // this.conceptButtonsDisabled = true
          console.log('there are 3 tags selected now. narrow mode')
        }
        // autoCollapse()

      // check if narrow mode is on and currentTarget is not already selecetd and 3 or more tags have been selected
      } else if(this.displayedPostDoc.focusedFeedback && !e.currentTarget.classList.contains('selectedIcon') && selectedTags.length >= this.narrowedTags.length){// && selectedTags.length >= this.narrowedTags.length
        // this.errorFeedback = 'Please select no more than 3 tags'
        // setTimeout(() => { this.errorFeedback = null }, 2000)

      // check if narrow mode is on (current target must be selected at this point)
      } else if(this.displayedPostDoc.focusedFeedback) {
        // this.errorFeedback = null
        // remove selectedIcon style from selected tag
        e.currentTarget.classList.remove('selectedIcon');
        // console.log('remove selected icon class #2')

        // reset stored note for the deselected tag
        this.newWorkingNotes.forEach((item, index) => {
          if(item.tag === e.currentTarget.getAttribute('data-concept')){
            // console.log('this item from newWorkingNotes has a matching tag: ', item)
            // console.log('this is the items tag: ', item.tag)
            //remove object in newWorkingNotes that contains e.currentTarget.getAttribute('data-concept')
            this.newWorkingNotes = this.newWorkingNotes.filter(note => {
              return note.tag != e.currentTarget.getAttribute('data-concept')
            })
          }
        })
        this.newNotWorkingNotes.forEach((item, index) => {
          if(item.tag === e.currentTarget.getAttribute('data-concept')){
            //remove object in newWorkingNotes that contains e.currentTarget.getAttribute('data-concept')
            this.newNotWorkingNotes = this.newNotWorkingNotes.filter(note => {
              return note.tag != e.currentTarget.getAttribute('data-concept')
            })
          }
        })

        // use filter to remove tag from narrowTags array
        selectedTags = selectedTags.filter(tag => {
          return tag != e.currentTarget.getAttribute('data-concept');
        })
        // use filter to remove selected tag from currentlySelectedWorking
        currentlySelectedTags = currentlySelectedTags.filter(tag => {
          return tag != e.currentTarget.getAttribute('data-concept')
        })
        // set new values stored in local variables to vue data
        if(workingStatus === 'working'){

          // selected tag is working so enable sister not working tag
          let notWorkingStage = document.getElementById('notWorkingStage')
          let notWorkingTag = notWorkingStage.querySelector(`div[data-concept=${CSS.escape(conceptName)}]`)
          notWorkingTag.classList.add('enabledIcon')

          this.selectedNarrowWorkingTags = selectedTags
          this.currentlySelectedWorking = currentlySelectedTags
        } else if(workingStatus === 'not-working'){

          // selected tag is not-working so enable sister working tag
          let workingStage = document.getElementById('workingStage')
          let workingTag = workingStage.querySelector(`div[data-concept=${CSS.escape(conceptName)}]`)
          workingTag.classList.add('enabledIcon')

          this.selectedNarrowNotWorkingTags = selectedTags
          this.currentlySelectedNotWorking = currentlySelectedTags
        }
      }
    },
    storeTagNote(){
      this.noteAdded = false
      let textarea = document.getElementById('addNoteTextarea')
      let textareaValue = textarea.value

      let date = new Date()      
      let month = date.getMonth() + 1
      let day = date.getDate()
      let year = date.getFullYear()
      let displayDate = month + '/' + day + '/' + year

      // setup if checks here to ensure user has filled out form
      if(textareaValue > '' && this.newWorkingNotes.length + this.newNotWorkingNotes.length < 6){// NEED to edit so there is a better way to ensure min post
        this.errorFeedback = ''
        if(this.modalMode === 'working'){
          // need to check if any existing objects stored in newWorkingNotes contains the same tag and uid
          // if any objects do, remove them from newWorkingNotes before pushing this new note
          // console.log('start forEach on newWorkingNotes to filter out existing saved note, to be replaced')
          this.newWorkingNotes.forEach((item, index) => {
            if(item.uid === this.user.uid && item.tag === this.tagNotesToDisplay){
              // filter this item out of newWorkingNotes
              this.newWorkingNotes = this.newWorkingNotes.filter(note => {
                return note != item
              })
            }
          })
          this.newWorkingNotes.push(      

            // REMINDER: we are using the alias, date, and uid on client side for display during submission
            // but we are getting the alias, date, and uid again on server to verify no malicous activity

            { body: textareaValue, date: displayDate, tag: this.tagNotesToDisplay, uid: this.user.uid } // removed: author: this.currentUserAlias, 
          
          )
          console.log('this is the newWorkingNotes array: ', this.newWorkingNotes)
        } else if(this.modalMode === 'not-working'){
          // console.log('start forEach on newWorkingNotes to filter out existing saved note, to be replaced')
          this.newNotWorkingNotes.forEach((item, index) => {
            if(item.uid === this.user.uid && item.tag === this.tagNotesToDisplay){
              // filter this item out of newWorkingNotes
              this.newNotWorkingNotes = this.newNotWorkingNotes.filter(note => {
                return note != item
              })
            }
          })
          this.newNotWorkingNotes.push(   

            // REMINDER: we are using the alias, date, and uid on client side for display during submission
            // but we are getting the alias, date, and uid again on server to verify no malicous activity

            { body: textareaValue, date: displayDate, tag: this.tagNotesToDisplay, uid: this.user.uid } // removed: author: this.currentUserAlias, 
          
          )
          console.log('this is the newNotWorkingNotes array: ', this.newNotWorkingNotes)
        }
        // add if check here to see if there is no more than 3 saved notes
        // this.savedNotes.push({ body: textareaValue, uid: this.user.uid })
        this.noteAdded = true

      } else if(textareaValue === ''){
        console.log('this is error when no note is submitted')
        this.errorFeedback = 'You must enter a note to save a note.'
      } else if(this.newWorkingNotes.length + this.newNotWorkingNotes.length >= 6 && !this.selectedTag.classList.contains('selectedNote')){
        this.errorFeedback = 'You can not save more than 6 notes.'
      } else if(this.newWorkingNotes.length + this.newNotWorkingNotes.length >= 6 && this.selectedTag.classList.contains('selectedNote')){
        this.errorFeedback = ''
        if(this.modalMode === 'working'){
          // need to check if any existing objects stored in newWorkingNotes contains the same tag and uid
          // if any objects do, remove them from newWorkingNotes before pushing this new note
          // console.log('start forEach on newWorkingNotes to filter out existing saved note, to be replaced')
          this.newWorkingNotes.forEach((item, index) => {
            if(item.uid === this.user.uid && item.tag === this.tagNotesToDisplay){
              // filter this item out of newWorkingNotes
              this.newWorkingNotes = this.newWorkingNotes.filter(note => {
                return note != item
              })
            }
          })
          this.newWorkingNotes.push(

            // REMINDER: we are using the alias, date, and uid on client side for display during submission
            // but we are getting the alias, date, and uid again on server to verify no malicous activity

            { body: textareaValue, date: displayDate, tag: this.tagNotesToDisplay, uid: this.user.uid } // removed: , profileImageUrl: this.currentUserProfileImageUrl, author: this.currentUserAlias, 
          
          )
          console.log('this is the newWorkingNotes array: ', this.newWorkingNotes)
        } else if(this.modalMode === 'not-working'){
          // console.log('start forEach on newWorkingNotes to filter out existing saved note, to be replaced')
          this.newNotWorkingNotes.forEach((item, index) => {
            if(item.uid === this.user.uid && item.tag === this.tagNotesToDisplay){
              // filter this item out of newNotWorkingNotes
              this.newNotWorkingNotes = this.newNotWorkingNotes.filter(note => {
                return note != item
              })
            }
          })
          this.newNotWorkingNotes.push( 
            { body: textareaValue, date: displayDate, tag: this.tagNotesToDisplay, uid: this.user.uid } // removed: , profileImageUrl: this.currentUserProfileImageUrl, author: this.currentUserAlias, 
          )
          console.log('this is the newNotWorkingNotes array: ', this.newNotWorkingNotes)
        }
        // add if check here to see if there is no more than 3 saved notes
        // this.savedNotes.push({ body: textareaValue, uid: this.user.uid })
        this.noteAdded = true
      }

      // after storing new note set readOnly to true
      // this.noteReadOnly = true

    },
    deleteTagNote(){
      this.noteUnvalidated = true
      if(this.modalMode === 'working'){
          // need to check if any existing objects stored in newWorkingNotes contains the same tag and uid
          // if any objects do, remove them from newWorkingNotes before pushing this new note
          // console.log('start forEach on newWorkingNotes to filter out existing saved note, to be replaced')
          this.newWorkingNotes.forEach((item, index) => {
            if(item.uid === this.user.uid && item.tag === this.tagNotesToDisplay){
              // filter this item out of newWorkingNotes
              this.newWorkingNotes = this.newWorkingNotes.filter(note => {
                return note != item
              })
              console.log('this is the newWorkingNotes array: ', this.newWorkingNotes)
              document.getElementById('addNoteTextarea').value = ''
              this.selectedTag.classList.remove('selectedNote')
              this.deleteNoteButton = false
              this.errorFeedback = ''
            }
          })
          // this.newWorkingNotes.push(
          //   { author: this.currentUserAlias, body: textareaValue, date: 'Jan. 14, 1984', tag: this.tagNotesToDisplay, uid: this.user.uid } 
          // )
          // console.log('this is the newWorkingNotes array: ', this.newWorkingNotes)
        } else if(this.modalMode === 'not-working'){
          // console.log('start forEach on newWorkingNotes to filter out existing saved note, to be replaced')
          this.newNotWorkingNotes.forEach((item, index) => {
            if(item.uid === this.user.uid && item.tag === this.tagNotesToDisplay){
              // filter this item out of newWorkingNotes
              this.newNotWorkingNotes = this.newNotWorkingNotes.filter(note => {
                return note != item
              })
              console.log('this is the newNotWorkingNotes array: ', this.newNotWorkingNotes)
              document.getElementById('addNoteTextarea').value = ''
              this.selectedTag.classList.remove('selectedNote')
              this.deleteNoteButton = false
              this.errorFeedback = ''
            }
          })
          // this.newNotWorkingNotes.push(
          //   { author: this.currentUserAlias, body: textareaValue, date: 'Jan. 14, 1984', tag: this.tagNotesToDisplay, uid: this.user.uid } 
          // )
          // console.log('this is the newNotWorkingNotes array: ', this.newNotWorkingNotes)
        }
    },
    submitNewFeedback(){
      // turn on loader animation
      this.showSpinner = true

      // setup date for post record
      let date = new Date()      
      let month = date.getMonth() + 1
      let day = date.getDate()
      let year = date.getFullYear()
      let displayDate = month + '/' + day + '/' + year

      // get the new comment
      let commentTextareaValue = document.getElementById('add-comment').value

      // this only runs after successfull fb submission
      const updateNoteData = () => {
        console.log('start updateNoteData to update note data with new fb')
      
        if(this.newWorkingNotes || this.newNotWorkingNotes){
          console.log('update this.workingNotes, and this.notWorkingnotes with new notes: ', this.newWorkingNotes, this.newNotWorkingNotes)
          
          // remove any existing notes from current user
          this.workingNotes = this.workingNotes.filter(note => {
            // remove any notes with current user id
            return note.uid != this.user.uid 
          })

          this.notWorkingNotes = this.notWorkingNotes.filter(note => {
            // remove any notes with current user id
            return note.uid != this.user.uid 
          })

          // store new notes into this.workingNotes and this.notWorkingNotes
          if(this.newWorkingNotes.length > 0){
            console.log('update this.workingNotes with these notes: ', this.newWorkingNotes)
            this.newWorkingNotes.forEach(note => 
              this.workingNotes.push(note)
            )
          }
          if(this.newNotWorkingNotes.length > 0){
            console.log('update this.notWorkingNotes with these notes: ', this.newNotWorkingNotes)
            this.newNotWorkingNotes.forEach(note => 
              this.notWorkingNotes.push(note)
            )
          }

          // reset note totals on feedbackIcons
          console.log('reset note totals on feedbackIcons objects')
          this.feedbackIcons.forEach(tag => {
            tag.workingNoteTotal = 0
            tag.notWorkingNoteTotal = 0
          })

          // calculate all working note totals and update feedbackIcons with data
          if(this.workingNotes.length > 0){
            console.log('iterate over all working notes to find other notes with same tags')
            // iterate over all working notes
            this.workingNotes.forEach(currentNote => {
              let currentNoteTotal = 0
              let feedbackIcon = null
              // for each working note check if its tag matches any of the working notes (will always return at least 1 match(with itself))
              this.workingNotes.forEach(noteToCheck => {
                if(currentNote.tag === noteToCheck.tag){
                  // update note total on each tag match
                  currentNoteTotal = currentNoteTotal + 1
                }
              })
              // find the feedback icon that has same tag
              feedbackIcon = this.feedbackIcons.find(tag => tag.name === currentNote.tag)
              // update feedback icon with note total
              feedbackIcon.workingNoteTotal = currentNoteTotal
            })
          }
          // calculate all notWorking note totals and update feedbackIcons with data
          if(this.notWorkingNotes.length > 0){
            console.log('iterate over all not working notes to find other notes with same tags')
            // iterate over all notWorking notes
            this.notWorkingNotes.forEach(currentNote => {
              let currentNoteTotal = 0
              let feedbackIcon = null
              // for each working note check if its tag matches any of the working notes (will always return at least 1 match(with itself))
              this.notWorkingNotes.forEach(noteToCheck => {
                if(currentNote.tag === noteToCheck.tag){
                  // update note total on each tag match
                  currentNoteTotal = currentNoteTotal + 1
                }
              })
              // find the feedback icon that has same tag
              feedbackIcon = this.feedbackIcons.find(tag => tag.name === currentNote.tag)
              // update feedback icon with note total
              feedbackIcon.notWorkingNoteTotal = currentNoteTotal
            })
          }
          console.log('all done updating notes data after fb submission')
        } else {
          // the note doc does not exist
          console.log('there are no new notes to add, the user did not submit any')
        }
      }

      const updateVersusResults = () => {
        this.versusImages[0].pollPercentage = Math.round((this.displayedPostDoc.aVotes/(this.displayedPostDoc.aVotes + this.displayedPostDoc.bVotes)) * 100)
        this.versusImages[1].pollPercentage = Math.round((this.displayedPostDoc.bVotes/(this.displayedPostDoc.aVotes + this.displayedPostDoc.bVotes)) * 100)
      }

      const updateVersusCount = result => {
        console.log('these are the updated aVotes: ', this.displayedPostDoc.aVotes)
        console.log('these are the updated bVotes: ', this.displayedPostDoc.bVotes)

        // set isSelected property on versusImages objects so current vote can be indicated
        if(result.data === 'aVotes'){

          console.log('The new vote is for A')

          // new vote is for A
          if(this.versusImages[0].isSelected){
            console.log('The existing vote is for A, do nothing.')
            // existing vote is for A
            // make no changes
          } else if(this.versusImages[1].isSelected){
            console.log('The existing vote is for B, so switch from B to A')
            // existing vote is for B
            // change vote from B to A
            this.displayedPostDoc.aVotes = this.displayedPostDoc.aVotes + 1
            this.displayedPostDoc.bVotes = this.displayedPostDoc.bVotes - 1
            updateVersusResults()
          } else {
            console.log('There is no existing vote, so add for A')
            // there is no existing vote from this user
            // add (1) vote for A
            this.displayedPostDoc.aVotes = this.displayedPostDoc.aVotes + 1
            updateVersusResults()
          }

          this.versusImages[0].isSelected = true
          this.versusImages[1].isSelected = false

        } else if(result.data === 'bVotes') {

          console.log('The new vote is for B')

          // new vote is for B
          if(this.versusImages[0].isSelected){
            console.log('The existing vote is for A, so switch from A to B')
            // existing vote is for A
            // change vote from A to B
            this.displayedPostDoc.aVotes = this.displayedPostDoc.aVotes - 1
            this.displayedPostDoc.bVotes = this.displayedPostDoc.bVotes + 1
            updateVersusResults()
          } else if(this.versusImages[1].isSelected){
            // existing vote is for B
            // make no changes
            console.log('The existing vote is for B, do nothing')
          } else {
            console.log('There is no existing vote, so add for B')
            // there is no existing vote from this user
            // add (1) vote for B
            this.displayedPostDoc.bVotes = this.displayedPostDoc.bVotes + 1
            updateVersusResults()
          }
          
          this.versusImages[0].isSelected = false
          this.versusImages[1].isSelected = true

        } else if(result.data === null){
          console.log('vote is null')
        }
      }

      // NEED to rework following 2 blocks of code to be 1 block
      if(this.selectedWorkingTags.length >= 1 && this.selectedNotWorkingTags.length >= 1 && !this.displayedPostDoc.focusedFeedback){
        if(this.user){
      
          console.log('call cloud function to write selected tags to post tag-record doc')
          
          let recordNewFeedback = projectFunctions.httpsCallable('recordNewFeedback')
          recordNewFeedback({
            post_id: this.displayedPostDoc.id, // validate data type, length, chars, etc on server // malicous user could alter this post id, but self feedback is still prohibited and rate limits still apply.
            selectedWorkingTags: this.selectedWorkingTags,                    // validate on server and client
            selectedNotWorkingTags: this.selectedNotWorkingTags,              // validate on server and client
            commentText: commentTextareaValue,                                // validate on server and client
            newWorkingNotes: this.newWorkingNotes,                            // validate on server and client
            newNotWorkingNotes: this.newNotWorkingNotes,                      // validate on server and client
            voteSelection: this.voteSelection                                 // validate on server
          }).then(result => {
            
            console.log('cloud function to update tags is complete', result.data)

            if(result.data.error){

              if(result.data.error === 'dailyLimit'){

                this.$emit('notice', 'You may only submit up to 30 critiques per day.')

                this.$emit('closePostModal')
             
              }

              if(result.data.error === 'minuteLimit'){

                this.$emit('notice', 'Please wait a little longer to submit another critique.')

                this.$emit('closePostModal')
             
              }

              if(result.data.error === 'invalidAuth'){

                this.$emit('notice', 'Your records have been repaired.')

                this.$emit('closePostModal')
             
              }

            } else if(result.data === 'self-critique-prohibited'){

              // ISSUE need to warn/penalize/ban user for malicous activity, trying to critique their own work

              this.$emit('notice', 'You may not critique your own work.')

              // turn off loader animation
              this.showSpinner = false

            } else if(!result.data.error){

              // check if $parent.dealedPost is true
              if(this.$parent.dealedPost){
                //  call method from parent (upload)
                this.$parent.uploadPost()

                this.$emit('closePostModal')

                return 'dealPlayed' // this will stop rest of code from firing
              }

              console.log('these are the aVotes: ', this.displayedPostDoc.aVotes)
              console.log('these are the bVotes: ', this.displayedPostDoc.bVotes)

              // update the versus count
              updateVersusCount(result)

              // reload the updated tag records
              this.loadTagRecords()

              // add the new comment to the displayed comments
              const addNewComment = () => {
                this.newComment.comment = { 
                  author: this.user.displayName, 
                  body: commentTextareaValue,
                  date: displayDate 
                }
                if(this.comments.length <= 5 || this.loadedComments.comments.length >= this.comments.length){ // if less than 5 existing comments show new comment now
                  this.newComment.show = true
                }
              }

              addNewComment()

              updateNoteData()

              let iconsToDeselect = document.querySelectorAll('.enabledIcon')
              iconsToDeselect.forEach((item, index) => {
                item.classList.remove('selectedIcon')
              })

              this.selectedWorkingTags = []
              this.selectedNotWorkingTags = []
              this.currentlySelectedWorking = []
              this.currentlySelectedNotWorking = []

              this.showCommentToggle = false
              this.commentsVisible = true
              this.feedbackSubmitted = true
              this.showSpinner = false

            }
          }).catch(error => {
            console.log('there was an error submitting the new broad feedback: ', error)

            this.$emit('notice', error)
          })
        } else {
          // user must be logged in to submit feedback on post
          this.$router.push({ name: 'Login' })
        }
      } else if(this.selectedWorkingTags.length < 1 && this.selectedNotWorkingTags.length >= 1 && !this.displayedPostDoc.focusedFeedback) {
        this.errorFeedback = 'You must select at least 1 working tag.'
      } else if(this.selectedWorkingTags.length >= 1 && this.selectedNotWorkingTags.length < 1 && !this.displayedPostDoc.focusedFeedback) {
        this.errorFeedback = 'You must select at least 1 not working tag.'
      } else if(this.selectedWorkingTags.length < 1 && this.selectedNotWorkingTags.length < 1 && !this.displayedPostDoc.focusedFeedback) {
        this.errorFeedback = 'You must select at least 1 working tag and 1 not working tag.'
      }
      // handle submission with focusedFeeback/NARROW mode on
      if(this.selectedNarrowWorkingTags.length >= 1 && this.selectedNarrowNotWorkingTags.length >= 1 && this.displayedPostDoc.focusedFeedback ||      // at least 1 working, at least 1 not working, FFB is on
         this.selectedNarrowWorkingTags.length === 3 && this.selectedNarrowNotWorkingTags.length === 0 && this.displayedPostDoc.focusedFeedback ||    // all 3 working, no not working tags, FFB is on
         this.selectedNarrowWorkingTags.length === 0 && this.selectedNarrowNotWorkingTags.length === 3 && this.displayedPostDoc.focusedFeedback  ||    // no working tags, all 3 not working tags, FFB is on
         this.selectedNarrowWorkingTags.length === this.narrowedTags.length && this.displayedPostDoc.focusedFeedback ||
         this.selectedNarrowNotWorkingTags.length === this.narrowedTags.length && this.displayedPostDoc.focusedFeedback){
        if(this.user){
          
          console.log('call cloud function to write selected tags to post tag-record doc')
          
          let recordNewFeedback = projectFunctions.httpsCallable('recordNewFeedback')
          recordNewFeedback({
            post_id: this.displayedPostDoc.id,
            selectedNarrowWorkingTags: this.selectedNarrowWorkingTags,
            selectedNarrowNotWorkingTags: this.selectedNarrowNotWorkingTags,        
            commentText: commentTextareaValue,
            newWorkingNotes: this.newWorkingNotes,
            newNotWorkingNotes: this.newNotWorkingNotes,
            voteSelection: this.voteSelection
          }).then(result => {

            console.log('cloud function to update tags is complete', result.data)

            if(result.data.error){

              if(result.data.error === 'dailyLimit'){

                this.$emit('notice', 'You may only submit up to 30 critiques per day.')

                this.$emit('closePostModal')
             
              }

              if(result.data.error === 'minuteLimit'){

                this.$emit('notice', 'Please wait a little longer to submit another critique.')

                this.$emit('closePostModal')
             
              }

            } else if(result.data === 'self-critique-prohibited'){

              // ISSUE need to warn/penalize/ban user for malicous activity, trying to critique their own work

              this.$emit('notice', 'You may not critique your own work.')

              // turn off loader animation
              this.showSpinner = false

            } else if(!result.data.error){

              // check if $parent.dealedPost is true
              if(this.$parent.dealedPost){
                //  call method from parent (upload)
                this.$parent.uploadPost()

                this.$emit('closePostModal')

                return 'dealPlayed' // this will stop rest of code from firing
              }

              console.log('these are the aVotes: ', this.displayedPostDoc.aVotes)
              console.log('these are the bVotes: ', this.displayedPostDoc.bVotes)
              
              // update the versus count
              updateVersusCount(result)

              // reload the updated tag records
              this.loadTagRecords()

              // add the new comment to the displayed comments 
              const addNewComment = () => {
                this.newComment.comment = { 
                  author: this.user.displayName, 
                  body: commentTextareaValue,
                  date: displayDate 
                }
                if(this.comments.length <= 5 || this.loadedComments.comments.length >= this.comments.length){ // if less than 5 existing comments show new comment now || or if all comments have been loaded already...
                  this.newComment.show = true
                }
              }

              addNewComment()

              updateNoteData()

              let iconsToDeselect = document.querySelectorAll('.enabledIcon')
              iconsToDeselect.forEach((item, index) => {
                item.classList.remove('selectedIcon')
              })

              // NEED to add 1 to feedbackTotal displayed on homepage?

              this.selectedNarrowWorkingTags = []
              this.selectedNarrowNotWorkingTags = []
              this.currentlySelectedWorking = []
              this.currentlySelectedNotWorking = []

              this.showCommentToggle = false
              this.commentsVisible = true
              this.feedbackSubmitted = true
              this.showSpinner = false
            }
          }).catch(error => {
            console.log('there was an error submitting the new narrow feedback: ', error)
          })
        } else {
          // user must be logged in to submit feedback on post
          this.$router.push({ name: 'Login' })
        }
      } else if(this.selectedNarrowWorkingTags.length < 1 && this.selectedNarrowNotWorkingTags.length >= 1 && this.displayedPostDoc.focusedFeedback) {
        this.errorFeedback = 'You must select at least 1 working tag.'
      } else if(this.selectedNarrowWorkingTags.length >= 1 && this.selectedNarrowNotWorkingTags.length < 1 && this.displayedPostDoc.focusedFeedback) {
        this.errorFeedback = 'You must select at least 1 not working tag bbb.'
      } else if(this.selectedNarrowWorkingTags.length < 1 && this.selectedNarrowNotWorkingTags.length < 1 && this.displayedPostDoc.focusedFeedback) {
        this.errorFeedback = 'You must select at least 1 working tag and 1 not working tag.'
      }
      // }
    },
    resubmitFeedback(){
      // turn feedback form on and hide resubmit button
      console.log('set feedbackSubmitted to false when user clicks resubmit button: ', this.feedbackSubmitted)
      this.showCommentToggle = true // do we need to check that length is > 1?
      this.feedbackSubmitted = false
      console.log('set feedbackSubmitted to false when user clicks resubmit button: ', this.feedbackSubmitted)
      // hide comments
      this.commentsVisible = false
      
      // reset notes
      this.newWorkingNotes = []
      this.newNotWorkingNotes = []
      // make comments button visible
      // let commentButton = document.getElementById('toggle-comments')
      // console.log('this should be the comment button: ', commentButton)
      // commentButton.style.display = "inline-block"

      // call method to get comments
      // this.loadComments('resubmit')
    },
    selectImage(e){
      console.log('start selectImage function')
      // handle auto-collapse for collapsible feedback walk thru process
      const autoCollapse = () => {
        // check if user has selected 3 tags
        // if(this.selectedWorkingTags.length === 3) {
        let collListItems = document.getElementsByClassName('collapsibleContainer')
        let collNextPosition = 0
        for (var i = 0; i < collListItems.length; i++) {
          if(collListItems[i].classList.contains('active')) {
            // remove active class from any and all collapsibleContainer tags
            collListItems[i].classList.remove('active')
            // set index value for next active collapsibleContainer 
            collNextPosition = i + 1
          }
        }
        // add active class to next collapsibleContainer
        collListItems[collNextPosition].classList.toggle('active')
        // }
      }
       // get current user
      // this.user = firebase.auth().currentUser;
      if(this.user){
        // this.voteInfo still being used in client side but could be worked away
        // this.voteInfo =  {
        //   post_id: this.post.id,
        //   voteSelection: e.target.id, // this will not work if you call function from submit button
        //   user_id: this.user.uid // ISSUE: need to get uid from server side, not client
        // }
        this.voteSelection = e.target.id
        console.log('this is the voteSelection: ', this.voteSelection)
        // remove selected class from image selection buttons
        let imageSelectionButtons = document.getElementsByClassName('img-selection-btn')
        for (var i = 0; i < imageSelectionButtons.length; i++) {
          if(imageSelectionButtons[i].classList.contains('selected')) {
            imageSelectionButtons[i].classList.remove('selected')
          }
        }
        // add selected style to target
        e.target.classList.toggle('selected')
        // turn on selection indicator in coll header
        let imageSelectionIndicators = document.getElementsByClassName('versusSelectionIndicator')
        for (var i = 0; i < imageSelectionIndicators.length; i ++) {
          if(imageSelectionIndicators[i].classList.contains('enabledIcon')) {
            imageSelectionIndicators[i].classList.remove('enabledIcon')
          }
        }
        if(e.target.id === 'aVote') {
          imageSelectionIndicators[0].classList.add('enabledIcon') // html collection is not defined yet
        } else if(e.target.id === 'bVote') {                       // must use for loop or transform imageSelectionIndicatores into an array
          imageSelectionIndicators[1].classList.add('enabledIcon')
        }
        // auto-collapse the collapsible feedback form ui
        autoCollapse()
      }
    },
    setupUI(){
      let hasUserCritiqued = this.comments.some(comment => {
        return comment.uid === this.user.uid && this.$parent.$options.name != 'Upload'
      })
      if(hasUserCritiqued){
        this.showCommentToggle = false
        this.feedbackSubmitted = true
        this.commentsVisible = true
      }
    },
    loadComments(state){
      console.log('Start loadComments.')
      // NEED to change this doc read so that we only retreive the sliced amount, not all of them.
      // then get more as per slice
      let commentsDocRef = this.postDocRef.collection('post-records').doc('comments')
      commentsDocRef.get().then(doc => {

        // check if comments doc exists
        if(doc.exists){

          // get the comments from doc
          this.comments = doc.data().comments
          console.log('These are the loaded comments: ', this.comments)

          // get pool of all unique user uids from this.comments
          this.comments.forEach((comment, index) => {
            if(!this.postCommentorPool.some(commentor => commentor.uid === comment.uid)){
              let commentorObject = { uid: comment.uid }
              this.postCommentorPool.push(commentorObject)
            }
          })

          // use commentor pool to retreive all associated profile image urls
          let getAuthorImageURLs = projectFunctions.httpsCallable('getAuthorImageURLs')
          getAuthorImageURLs({
            userPool: this.postCommentorPool
          }).then(result => {
            // save record with profile image urls to vue data 
            // so it can be used to get profile images on notes
            this.returnedCommentorRecords = result

            // go thru record and add associated image url to each comment object 
            this.comments.forEach((comment, index) => {
              this.returnedCommentorRecords.data.forEach((record, index) => {
                if(comment.uid === record.uid){
                  comment.commentorImageURL = record.photoURL
                  comment.author = record.displayName
                }
              })
            })
            // now the commentor images are loaded
            this.commentorImagesLoaded = true
            
            
          }).catch(error => {
            console.log('there was an error getting commentor image URLs: ', error)
          })

          // diplay 'show comments' button if there at least 1 comment and parent is not Navbar
          if(this.comments.length >= 1){// && this.$parent.$options.name != 'Navbar'){
            this.showCommentToggle = true
          } else {
            this.showCommentToggle = false
          }

          // setup how many comments to initially load
          this.loadedComments.sliceEnd = this.comments.length
          // check if there are more than 5 comments
          if(this.loadedComments.sliceEnd > 5){
            // limit initial load of comments to 5
            this.loadedComments.sliceEnd = 5
          }
          this.loadedComments.comments = this.comments.slice(0, this.loadedComments.sliceEnd)
          this.loadedComments.groupsLoaded = this.loadedComments.groupsLoaded + 1

          console.log('@@@@@@@@@@@check if the following if statement is working...')
          if(this.loadedComments.comments.length < this.comments.length){
            console.log('8888888888888888888888888888888: need to load more comments true')
            this.moreCommentsToLoad = true
          } else {
            console.log('8888888888888888888888888888888: need to load more comments FALSE')
            this.moreCommentsToLoad = false

    
            console.log('check if newComment.comment is not null: ', this.newComment.comment)
            if(this.newComment.comment !== null){
              this.newComment.show = true
            }

          }

          console.log('this is state param form loadComments() call: ', state)
          if(state === 'created'){
            this.setupUI()
            
          }

        } else {
          console.log('this post has no comments yet')
        }
      })
    },
    loadMoreComments(){
      // this.commentSliceStart === 0
      // this.commentSliceEnd === 2
      // this.loadedComments.groupsLoaded === 1 
      // this.comments = doc.data().comments // what did this do????
      console.log('this is the slice start before update: ', this.loadedComments.sliceStart)
      console.log('this is the slice end before update: ', this.loadedComments.sliceEnd)
      // set new sliceStart at previous sliceEnd
      let numberOfNewComments = this.comments.length - this.loadedComments.comments.length
      console.log('this is the number of additional comments to load: ', numberOfNewComments)
      if(numberOfNewComments > 5){
        console.log('there are more than 5 comments to add, so limit this load to 5')
        numberOfNewComments = 5
      }
      this.loadedComments.sliceStart = this.loadedComments.sliceEnd
      this.loadedComments.sliceEnd = this.loadedComments.sliceEnd + numberOfNewComments
      // if(this.loadedComments.comments.length !== 0){ // below should only run if there is more than 5 loaded
      //   this.loadedComments.sliceStart = this.loadedComments.sliceStart + 5
      //   this.loadedComments.sliceEnd = this.loadedComments.sliceEnd + 5
      // }

      

      console.log('this is the slice start after update: ', this.loadedComments.sliceStart)
      console.log('this is the slice end after update: ', this.loadedComments.sliceEnd)

      let newCommentGroup = this.comments.slice(this.loadedComments.sliceStart, this.loadedComments.sliceEnd)
      console.log('this is the newCommentGroup: ', newCommentGroup)
      newCommentGroup.forEach((item, index) => {
        this.loadedComments.comments.push(item)
        console.log('this is a comment to add: ', item)
      })

      // this.loadedComments.comments.push(this.comments.slice(this.loadedComments.sliceStart, this.loadedComments.sliceEnd))
      this.loadedComments.groupsLoaded = this.loadedComments.groupsLoaded + 1
      console.log('this is the loaded comments array: ', this.loadedComments.comments )

      if(this.loadedComments.comments.length < this.comments.length){
        console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&: set load more comments to true')
        this.moreCommentsToLoad = true
      } else {
        console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&: set load more comments to false')
        this.moreCommentsToLoad = false

       
            // add the new comment to the displayed comments
            // const addNewComment = () => {
            //   this.newComment.comment = { 
            //     author: this.currentUserAlias, 
            //     body: this.commentTextareaValue,
            //     date: this.displayDate 
            //   }
            //   this.newComment.show = true
            // }
            // addNewComment()
            console.log('check if newComment.comment is not null: ', this.newComment.comment)
            if(this.newComment.comment !== null){
              this.newComment.show = true
            }
      }

    },
    toggleComments(){
      // write code that turns this.showCommentToggle on and off and changes the text
      // if(this.showCommentToggle){
      //   this.showCommentToggle = false
      // } else {
      //   this.showCommentToggle = true
      // }


      let commentButton = document.getElementById('toggle-comments')
      if(this.commentsVisible === false){
        this.commentsVisible = true
        //change button text to 'Hide Comments'
        commentButton.innerText = 'hide comments'
        
      } else if(this.commentsVisible === true) {
        this.commentsVisible = false
        //change button text to 'View Comments'
        commentButton.innerText = 'view comments'
      }
    },
    loadTagRecords(){

      // set result tag icon color based on working/notWorking results
      const updateWorkingConsensus = () => {
        // set working on each feedbackIcon/tag (use to control color of icon, and etc)
        console.log('start updateWorkingConsensus')
        this.feedbackIcons.forEach(tag => {
          console.log('these are the percentage to compare for each tag: ', tag.workingPercentage, tag.notWorkingPercentage)
          if(tag.workingPercentage >= tag.notWorkingPercentage && tag.workingPercentage != 0){
            console.log('set this tags working value to true: ', tag.name)
            tag.working = true
          } else if(tag.notWorkingPercentage > tag.workingPercentage){
            console.log('keep this tags working value set to false ', tag.name)
            tag.working = false
          } else if(tag.workingPercentage === tag.notWorkingPercentage && tag.workingPercentage === 0){
            tag.working = null
          }
        })
      }

      let tagDocRef = this.postDocRef.collection('post-records').doc('broad-tags')

      if(this.displayedPostDoc.focusedFeedback){
        tagDocRef = this.postDocRef.collection('post-records').doc('narrow-tags')
      }

      tagDocRef.get().then(doc => {
        console.log('start tagDocRef.get()')

        let tagVoteTotal = 0
        let tagWorkingTotal = 0
        let tagNotWorkingTotal = 0
        let tagWorkingPercentage = 0
        let tagNotWorkingPercentage = 0

        if(doc.data() !== undefined){ // was 'undefined'
          let tagRecordObject = doc.data()
          let tagRecordArray = Object.entries(tagRecordObject)// RETURNS UNDEFINED WHEN ON A NARROW POST
          console.log('this is the doc data from tag record: ', tagRecordObject, tagRecordArray)
          console.log('start forEach on tagRecordArray: ', tagRecordArray)

          // calculate individual and combined vote totals
          tagRecordArray.forEach((item, index) => {
            let tagName = item[0].toLowerCase()
            let numberOfVotes = item[1].length

            if(tagName.includes('-not-working')) {
              tagNotWorkingTotal = tagNotWorkingTotal + numberOfVotes
            } else if (tagName.includes('-working')) {
              tagWorkingTotal = tagWorkingTotal + numberOfVotes
            }
          
            if(item[1].includes(this.user.uid)){
              document.getElementById(tagName + '-results').classList.add('markedIcon') // in lieu of adding to class list you could: set a boolean (T/F) property on each object in feedbackIcons and then :vbind the class in the html
            } else {
              // console.log('current user id not found in record')
              document.getElementById(tagName + '-results').classList.remove('markedIcon')
            }
            
          })

          // update all tag percentages to 0 first...
          this.feedbackIcons.forEach(item => {
            item.workingPercentage = 0
            item.notWorkingPercentage = 0
          })

          // update selected tag percentages on tag doc 
          tagRecordArray.forEach((item, index) => {

            let tagName = item[0].toLowerCase()
            let feedbackIcon = null
            let truncatedTagName = ''
            let numberOfVotes = item[1].length
            let tagPercentage = 0
            
            if(tagName.includes('-not-working')) {
              tagPercentage = Math.round((numberOfVotes/tagNotWorkingTotal) * 100)
              truncatedTagName = tagName.slice(0, -12)
              feedbackIcon = this.feedbackIcons.find(tag => tag.name === truncatedTagName)
              if(!isNaN(tagPercentage)){
                feedbackIcon.notWorkingPercentage = tagPercentage
              }
              
            } else if (tagName.includes('-working')) {
              tagPercentage = Math.round((numberOfVotes/tagWorkingTotal) * 100)
              truncatedTagName = tagName.slice(0, -8)
              feedbackIcon = this.feedbackIcons.find(tag => tag.name === truncatedTagName)
              if(!isNaN(tagPercentage)){
                feedbackIcon.workingPercentage = tagPercentage
              }
            
            }
      
          })

          updateWorkingConsensus()

        } else {
          console.log('the rec doc does not exist')
        }
      })
      .catch(error => {
        console.log('There was an error getting the tags record doc: ', error)
      })
    },
    validateDuringInput(e, content){

      console.log('Start validateDuringInput().')

      const validateTags = () => {

        console.log('Start validateTags().')

        // check if working tags have been corrected

        if(this.postNotice === 'You must select at least 1 working element or principle.'){

          console.log('Check for working tag correction.')

          // FFB OFF / Broad tags

          if(!this.displayedPostDoc.focusedFeedback){

            // if tag length is greater than 0, it has been corrected (do nothing if it has not been corrected)

            if(this.selectedWorkingTags.length > 0){

              console.log('The working tag lengths are valid.')

              this.postNotice = ''

            } 

          } else {

            // FFB ON / Narrow Tags

            if(
              this.selectedNarrowWorkingTags.length > 0 ||
              this.selectedNarrowNotWorkingTags.length === this.narrowedTags.length
            ){

              console.log('The working tag lengths are valid.')

              this.postNotice = ''

            } 

          }

        }

        // check if not working tags have been corrected

        if(this.postNotice === 'You must select at least 1 not working element or principle.'){

          console.log('Check for not working tag correction.')

          // FFB OFF / Broad tags

          if(!this.displayedPostDoc.focusedFeedback){

            // if tag length is greater than 0, it has been corrected (do nothing if it has not been corrected)

            if(this.selectedNotWorkingTags.length > 0){

              console.log('The not working tag lengths are valid.')

              this.postNotice = ''

            } 

          } else {

            // FFB ON / Narrow Tags

            if(
              this.selectedNarrowNotWorkingTags.length > 0 ||
              this.selectedNarrowWorkingTags.length === this.narrowedTags.length
            ){

              console.log('The not working tag lengths are valid.')

              this.postNotice = ''

            } 

          }

        }

        // valildate working and not working tags with FFB OFF/ON

        // display postNotices as needed for helpful user experience

        if(!this.displayedPostDoc.focusedFeedback){

          if(this.selectedWorkingTags.length === 0){

            console.log('The working tag length is invalid')

            this.postNotice = 'You must select at least 1 working element or principle.'

            // this.unvalidated = true;

          } else if(this.selectedNotWorkingTags.length === 0){

            console.log('The not working tag length is invalid')

            this.postNotice = 'You must select at least 1 not working element or principle.'

            // this.unvalidated = true;

          } else {

            console.log('The working and not working tag lengths are valid.')

            this.postNotice = ''

          }

        } else {

          // Validate working and not working tags with FFB ON

          if(
            this.selectedNarrowWorkingTags.length === 0 &&
            this.selectedNarrowNotWorkingTags.length !== this.narrowedTags.length
          ){

            // user must select at least 1 working tag or select all not working tags

            console.log('The narrow working tag length is invalid.')

            this.postNotice = 'You must select at least 1 working element or principle.'

            this.unvalidated = true;

          } else if(
            this.selectedNarrowNotWorkingTags.length === 0 &&
            this.selectedNarrowWorkingTags.length !== this.narrowedTags.length
          ){

            // user must select at least 1 not working tag or select all working tags

            console.log('The narrow not working tag length is invalid.')

            this.postNotice = 'You must select at least 1 not working element or principle.'

            this.unvalidated = true;

          } else {

            console.log('The working and not working tag lengths are valid.')

            this.postNotice = ''

          }

        }

      }

      let commentTextareaValue = null

      if(content !== 'edit' ){

        // the comment input is not present duirng post edit, will be null

        commentTextareaValue = document.getElementById('add-comment').value

      }

      if(content === 'note'){

        console.log('Start validation on note.')

        let noteTextareaValue = document.getElementById('addNoteTextarea').value

        if(
          noteTextareaValue.match(/^[a-zA-Z0-9 \?!,.%-]+$/g)      // note must match regex chars
        ){

          console.log('the note chars are valid')

          this.noteNotice = ''

          if(
            noteTextareaValue.length >= 3 &&                        // note must be min 3 chars
            noteTextareaValue.length <= 300                         // note must be max 300 chars
          ){

            this.noteUnvalidated = false

          } else {

            this.noteUnvalidated = true

          }


        } else {

          this.noteUnvalidated = true

          if(noteTextareaValue !== null && noteTextareaValue !== ''){

            console.log('the note chars are not valid')

            this.noteNotice = 'Notes may not contain special characters.'

          } else {

            this.noteNotice = ''

          }

        }

      } else if(content === 'edit'){

        console.log('Start validation on edit post.')

        let title = document.getElementById('postTitleInput').value

        let descr = document.getElementById('postDescrInput').value

        // check if title is valid first

        if(title.match(/^[a-zA-Z0-9 \?!,.%-]+$/g)){

          console.log('The title chars are valid.')

          if(this.editNotice === 'Title may not contain special characters.'){

            this.editNotice = ''

          }

          // now check if the description is valid

          if(descr.match(/^[a-zA-Z0-9 \?!,.%-]+$/g)){

            console.log('The description chars are valid.')

            if(this.editNotice === 'Description may not contain special characters.'){

              this.editNotice = ''

            }

            if(
              title.length >= 3 &&                         // note must be min 3 chars
              title.length <= 64                           // note must be max 300 chars
            ){

              console.log('The title length is valid.')

              if(this.editNotice === 'Title must be longer.' || this.editNotice === 'Title must be shorter.'){

                this.editNotice = ''

                // ISSUE should we check desc length here? YES, see explanation below.
                // only executes if user has blurred from input 
                // because that is only way editNotice could have been set to 'Title must be shorter.' or 'Title must be longer.'
                // so okay to give notice during validation in lieu of after?
                // this works in this situation because there is only 2 input fields
                // if user has corrected the 1st field, then giving feedback during validation becomes appropriate

                if(descr.length < 3){

                  this.editNotice = 'Description must be longer.'

                } else if(descr.length > 600){

                  this.editNotice = 'Description must be shorter.'

                }

              }

              if(
                descr.length >= 3 &&                         // note must be min 3 chars
                descr.length <= 600                          // note must be max 300 chars
              ){

                console.log('The description length is valid.')

                if(this.editNotice === 'Description must be longer.' || this.editNotice === 'Description must be shorter.'){

                  this.editNotice = ''

                  // ISSUE we can only reach this section of code if the title length is already valid.
                  // so code does nothing...

                  // if(title.length < 3){

                  //   this.editNotice = 'Title must be longer.'

                  // } else if(title.length > 64){

                  //   this.editNotice = 'Title must be shorter.'

                  // }

                }

                this.editUnvalidated = false

              } else {

                console.log('The description length is invalid.')

                this.editUnvalidated = true

              }

            } else {

              console.log('The title length is invalid.')

              this.editUnvalidated = true

            }

          } else {

            this.editUnvalidated = true

            if(descr !== null && descr !== ''){

              console.log('The description chars are invalid.')

              this.editNotice = 'Description may not contain special characters.'

            } 

          }

          // took from here

        } else {

          this.editUnvalidated = true

          if(title !== null && title !== ''){

            console.log('The title chars are invalid.')

            this.editNotice = 'Title may not contain special characters.'

          } 

        }

      } else if(

        // this section controls enable/disable of 'submit' button via this.unvalidated = true/false

        // validation for narrow
        this.displayedPostDoc.focusedFeedback &&                                                                                                                        // FFB is on
        (this.displayedPostDoc.imageURLs.length === 1 && !this.voteSelection || this.displayedPostDoc.imageURLs.length === 2 && this.voteSelection) &&                                        // one image with no vote, or 2 images with vote
        this.selectedNarrowWorkingTags.length >= 1 &&                                                                                                       // at least 1 working tag
        this.selectedNarrowNotWorkingTags.length >= 1 &&                                                                                                    // at least 1 not working tag
        (commentTextareaValue.length >= 3 && commentTextareaValue.length <= 600) &&                                                                         // comment must be min 3 max 600
        commentTextareaValue.match(/^[a-zA-Z0-9 \?!,.%-]+$/g) ||                                                                                            // comment must only contain regular characters
                                                                                              // OR
        // alt validation for narrow
        this.displayedPostDoc.focusedFeedback &&                                                                                                                        // FFB is on
        (this.displayedPostDoc.imageURLs.length === 1 && !this.voteSelection || this.displayedPostDoc.imageURLs.length === 2 && this.voteSelection) &&                                        // one image with no vote, or 2 images with vote
        (this.selectedNarrowWorkingTags.length === this.narrowedTags.length || this.selectedNarrowNotWorkingTags.length === this.narrowedTags.length) &&    // qty of selected narrow working/not working tags are equal to qty of total tags
        (commentTextareaValue.length >= 3 && commentTextareaValue.length <= 600) &&                                                                         // comment must be min 3 max 600
        commentTextareaValue.match(/^[a-zA-Z0-9 \?!,.%-]+$/g) ||                                                                                            // comment must only contain regular characters                                                                   
                                                                                              // OR
        // validation for broad
        !this.displayedPostDoc.focusedFeedback &&                                                                                                                       // FFB is off
        (this.displayedPostDoc.imageURLs.length === 1 && !this.voteSelection || this.displayedPostDoc.imageURLs.length === 2 && this.voteSelection) &&                                        // one image with no vote, or 2 iumages with vote
        this.selectedWorkingTags.length >= 1 && this.selectedWorkingTags.length <= 3 &&                                                                     // at least 1 working tag and no more than 3
        this.selectedNotWorkingTags.length >= 1 && this.selectedNotWorkingTags.length <= 3 &&                                                               // at least 1 not working tag and not more than 3 
        (commentTextareaValue.length >= 3 && commentTextareaValue.length <= 600) &&                                                                         // comment must be min 3 max 600
        commentTextareaValue.match(/^[a-zA-Z0-9 \?!,.%-]+$/g)                                                                                               // comment must only contain regular characters

      ){

        // post is valid, clear notice

        console.log('The post is valid now.')

        this.postNotice = ''

        this.unvalidated = false 

      } else {

        // post is not valid
        // run all notice code here (because we only need notices when post is not valid)

        console.log('The post is NOT valid yet.')

        this.unvalidated = true

        // if comment has valid chars then validate tags

        if(commentTextareaValue.match(/^[a-zA-Z0-9 \?!,.%-]+$/g)){


          console.log('The comment chars are valid so call validateTags().') // still need to validate length after input

          validateTags()

        } else {

          // comment does not match valid chars, so check if empty

          console.log('The comment does not match regex chars, so display notice if comment is not empty.')

          if(commentTextareaValue !== null && commentTextareaValue !== ''){

            // not empty, so contains excluded spec chars, so diplay notice

            console.log('The comment contains special characters: ', commentTextareaValue)

            this.postNotice = 'Comments may not contain any special characters.'

          } else {

            // comment is empty, so user just deleted all chars
            // so now is good time to validate tags that should be entered by now

            if(e.currentTarget.id === 'add-comment'){

              console.log('The user clicked on comment text area, so call validateTags()')

              validateTags()

            } else if(

              // user is correcting working tag
              (this.postNotice === 'You must select at least 1 working element or principle.' &&
              e.currentTarget.getAttribute('data-status') === 'working') ||

              // user is correcting not working tag
              (this.postNotice === 'You must select at least 1 not working element or principle.' &&
              e.currentTarget.getAttribute('data-status') === 'notWorking')

            ){

              validateTags()

            }

          }

        }

      }

    },
    validateAfterInput(content){

      // only thing to validate after input is comment length
      // everyting else can be verified during input

      console.log('Start validateAfterInput().')

      if(content === 'note'){

        console.log('Start validation on note after input.')

        console.log('This is the noteNotice: ', this.noteNotice)

        if(this.noteNotice === ''){

          let noteTextareaValue = document.getElementById('addNoteTextarea').value

          if(noteTextareaValue.length < 3){

            console.log('The note is too short.')

            this.noteNotice = 'Note must be longer.'

          } else if(noteTextareaValue.length > 300){

            console.log('The note is too long.')

            this.noteNotice = 'Note must be shorter.'

          }

        }

      } else if(content === 'edit'){

        console.log('Start validation on post edit after input.')

        console.log('This is the editNotice: ', this.editNotice)

        // if(this.editNotice === ''){

          let title = document.getElementById('postTitleInput').value

          let descr = document.getElementById('postDescrInput').value

          if(title.length < 3){

            console.log('The title is too short.')

            this.editNotice = 'Title must be longer.'

          } else if(title.length > 64){

            console.log('The title is too long.')

            this.noteNotice = 'Title must be shorter.'

          } else if(descr.length < 3){

            console.log('The description is too short.')

            this.editNotice = 'Description must be longer.'

          } else if(descr.length > 600){

            console.log('The description is too long.')

            this.noteNotice = 'Description must be shorter.'

          }

        // }

      } else {

        if(this.postNotice === ''){

          let commentTextareaValue = document.getElementById('add-comment').value

          if(commentTextareaValue.length < 3){

            console.log('The comment is too short.')

            this.postNotice = 'Comment must be longer.'

          } else if(commentTextareaValue.length > 600){

            console.log('The comment is too long.')

            this.postNotice = 'Comment must be shorter.'

          }

        }

      }

    },
    updatePost(){

      this.showSpinner = true

      let updatePostDoc = projectFunctions.httpsCallable('updatePostDoc')

      updatePostDoc({ 

        postId: this.displayedPostDoc.id,
        postTitle: this.postTitleInput,
        postDesc: this.postDescInput

        }).then(result => {

        console.log('cloud update function is complete ', result)

        // ISSUE: how can we make this work without timeout?

        setTimeout( pause => {

          console.log('Now emit event from DisplayPost to MGallery, to close post modal.')

          this.$emit('closePostModal')

          this.showSpinner = false

        }, 500)

        }).catch(error => {

          console.log('error with cloud update function ', error)

        })

    },
    deletePost() {
      this.showSpinner = true
      let deletePostDoc = projectFunctions.httpsCallable('deletePostDoc')
      deletePostDoc({
        postId: this.displayedPostDoc.id
      }).then(result => {
        console.log('Cloud function deletePost is complete: ', result)
        // NEED TO MAKE THIS WORK WITHOUT THE TIMEOUT DELAY
        setTimeout( pause => {
          this.showSpinner = false

          this.$emit('closePostModal', { deletePost: this.displayedPostDoc.id })

        }, 500)                                 
      }).catch(error => {
        console.log('error with cloud delete function ', error)
      })
    },
    openConceptsMenu(){
      let menu = document.getElementById('conceptsMenu')
      let menuItems = document.getElementsByClassName('conceptsMenuItem')

      if(!menu.classList.contains('open')){
        menu.classList.add('open')
      } else {
        menu.classList.remove('open')
      }
    },
    toggleConcepts(e){
      let menuItems = document.getElementsByClassName('conceptsMenuItem')
      let conceptSelectionMenu = null
      let conceptSelectionContent = null

      // NEED to rewrite similar code for settings menu that uses data-settings tag
      let selectedConcept = e.currentTarget.parentNode.getAttribute('data-concept')
      conceptSelectionMenu = selectedConcept + 'Help'
      conceptSelectionContent = selectedConcept + 'Content'

      // remove active class from unclicked items
      for (var i = 0; i < menuItems.length; i ++) {
        if(menuItems[i].id != conceptSelectionMenu && menuItems[i].classList.contains('active')){
          menuItems[i].classList.remove('active')
        }
      }
      // add active class to clicked item if not present
      if(!e.currentTarget.parentNode.classList.contains('active')){
        e.currentTarget.parentNode.classList.toggle('active')
      }
      // turn off/on content
      let conceptContent = document.getElementsByClassName('conceptContent')
      for (var i = 0; i < conceptContent.length; i ++) {
        if(conceptContent[i].id != conceptSelectionContent){
          conceptContent[i].style.display = "none"
        }
        if(conceptContent[i].id === conceptSelectionContent){
          conceptContent[i].style.display = "block"
        }
      }
    }
  },
  created(){
    console.log('Start created() in DisplayProfilePost.vue')

    console.log('This is the postDocToOpen prop sent from Profile (parent): ', this.postDocToOpen)

    console.log('This is the user data passed as prop from root component: ', this.user)

    this.postDocRef = projectFirestore.collection('userPosts').doc(this.postDocToOpen.id)

    let voteDocRef = this.postDocRef.collection('post-records').doc('voting')
    let noteDocRef = this.postDocRef.collection('post-records').doc('notes')

    let getAuthorInfo = projectFunctions.httpsCallable('getAuthorImageURLs');

    const setupPost = () => {

      console.log('Start setupPost() at created() at DisplayPost.vue.')

      //     // only calculate value of versus images percentages if there is at least 1 vote
      if(this.displayedPostDoc.aVotes > 0 || this.displayedPostDoc.bVotes > 0){
        this.versusImages[0].pollPercentage = Math.round((this.displayedPostDoc.aVotes/(this.displayedPostDoc.aVotes + this.displayedPostDoc.bVotes)) * 100)
        this.versusImages[1].pollPercentage = Math.round((this.displayedPostDoc.bVotes/(this.displayedPostDoc.aVotes + this.displayedPostDoc.bVotes)) * 100)
      }

      // set this.narrowedTags to the tags from post doc (now stored in this.post)
      this.narrowedTags = this.displayedPostDoc.narrowedTags
      // iterate over feedbackIcons 
      this.feedbackIcons.forEach( (item, index) => {
        // set narrowed property to true if narrow mode is on and item is included in narrowedTags array
        if(this.narrowedTags.includes(item.name) && this.displayedPostDoc.focusedFeedback) {
          item.narrowed = true
        }
      })
        
      //     // check if user has not viewed this doc before

      if(!this.displayedPostDoc.viewers.includes(this.user.uid)){

        let updateViews = projectFunctions.httpsCallable('updateViews')
        
        updateViews({

          postId: this.displayedPostDoc.id

        }).then(result => {

          console.log('cloud views update function is complete: ', result)

        }).catch(error => {

          console.log('error with cloud voting function', error)

        })

      }
          
      //   // get vote record document and determine user's existing vote if any 
      if(this.displayedPostDoc.imageURLs.length === 2){
        voteDocRef.get().then(voteDoc => {
          if(voteDoc.exists){
            let voteRecord = voteDoc.data()
            // alreadyVotedForA will be true if the user id is included in the a vote record, otherwise false
            let alreadyVotedForA = voteRecord.aVoters.includes(this.user.uid) 
            // alreadyVotedForB will be true if the user id is included in the b vote record, otherwise false
            let alreadyVotedForB = voteRecord.bVoters.includes(this.user.uid) 
            if(alreadyVotedForA){
              // existing vote is for a
              this.versusImages[0].isSelected = true
            } else if(alreadyVotedForB){
              // existing vote is for b
              this.versusImages[1].isSelected = true
            } else {
              // there is no existing vote from this user
              this.versusImages[0].isSelected = false
              this.versusImages[1].isSelected = false
            }
          }
        })
      }
        
        
      // get tag records doc
      this.loadTagRecords()

      // get comments doc in created hook
      // ISSUE need to update code for dealing with posts opened fron dealing or from notifications
      this.loadComments('created')
      
      // get the note doc and store data if it exists
      noteDocRef.get().then(noteDoc => {
        console.log('this is the noteDoc: ', noteDoc)
        if(noteDoc.exists){
          // the note doc does exist
          console.log('the note doc does exist, this is the data: ', noteDoc.data())
          // store note doc data
          this.noteDoc = noteDoc.data()
          // store working notes and note working notes into seperate vue data
          if(this.noteDoc.workingNotes != undefined){
            this.workingNotes = this.noteDoc.workingNotes
          }
          if(this.noteDoc.notWorkingNotes != undefined){
            this.notWorkingNotes = this.noteDoc.notWorkingNotes
          }
          // calculate all working note totals and update feedbackIcons with data
          if(this.noteDoc.workingNotes){
            // iterate over all working notes
            this.noteDoc.workingNotes.forEach(currentNote => {
              let currentNoteTotal = 0
              let feedbackIcon = null
              // for each working note check if its tag matches any of the working notes (will always return at least 1 match(with itself))
              this.noteDoc.workingNotes.forEach(noteToCheck => {
                if(currentNote.tag === noteToCheck.tag){
                  // update note total on each tag match
                  currentNoteTotal = currentNoteTotal + 1
                }
              })
              // find the feedback icon that has same tag
              feedbackIcon = this.feedbackIcons.find(tag => tag.name === currentNote.tag)
              // update feedback icon with note total
              feedbackIcon.workingNoteTotal = currentNoteTotal
            })
          }
          // calculate all notWorking note totals and update feedbackIcons with data
          if(this.noteDoc.notWorkingNotes){
            // iterate over all notWorking notes
            this.noteDoc.notWorkingNotes.forEach(currentNote => {
              let currentNoteTotal = 0
              let feedbackIcon = null
              // for each working note check if its tag matches any of the working notes (will always return at least 1 match(with itself))
              this.noteDoc.notWorkingNotes.forEach(noteToCheck => {
                if(currentNote.tag === noteToCheck.tag){
                  // update note total on each tag match
                  currentNoteTotal = currentNoteTotal + 1
                }
              })
              // find the feedback icon that has same tag
              feedbackIcon = this.feedbackIcons.find(tag => tag.name === currentNote.tag)
              // update feedback icon with note total
              feedbackIcon.notWorkingNoteTotal = currentNoteTotal
            })
          }

          console.log('this is the this.feedbackIcons after note total updates: ', this.feedbackIcons)


        } else {
          // the note doc does not exist
          console.log('the note doc does not exist')
        }
      }).catch(error => {

        console.log('There was an error getting the notes doc: ', error)

      })

      // setup values for post edit inputs

      console.log('Set the post edit input values.')

      this.postTitleInput = this.displayedPostDoc.postTitle

      this.postDescInput = this.displayedPostDoc.postDesc

    }

    // check if we have full post object, if not get post doc before doing anything else

    if(!this.postDocToOpen.userId){    
      
      // we do not have the full post object yet, so get from firestore

      console.log('We do not have the full post object, so get from firestore.')

      this.postDocRef.get().then(doc => {

        console.log('We have got the doc from firestore, see if it exists: ', doc)

        if(doc.exists){

          console.log('The doc does exist, so set data to displayedPostDoc.')

          this.displayedPostDoc = doc.data()

          this.displayedPostDoc.id = doc.id//.slice(0, -7)

          // This post object does not contain the author img or alias, 
          // so call cloud function to get data and update post object.

          getAuthorInfo({

            userPool: [{ uid: this.displayedPostDoc.userId }]

          }).then(result => {

            console.log('This is the result.data from getAuthorInfo: ', result.data);

            this.displayedPostDoc.authorImageURL = result.data[0].photoURL;

            this.displayedPostDoc.userAlias = result.data[0].displayName;
            
            setupPost()

            console.log('this is the authorImageURL: ', this.displayedPostDoc.authorImageURL)
            console.log('this is the author alias: ', this.displayedPostDoc.userAlias)

          }).catch(error => {

            console.log('There was an error getting the author info: ', error);

          });

        } else {

          // the doc does not exist, go to 404 page

          console.log('The doc does not exist')

          this.$emit('closePostModal', '404')

          // this.$router.push({ name: 'NotFound', params: { catchAll: '404' }});

        }

      }).catch(error => {

        console.log('There was an error getting the postDocToOpen: ', error)

      })

    } else {

      // we do have the full post object already

      console.log('We do have the full post object.')

      this.displayedPostDoc = this.postDocToOpen

      setupPost()

      console.log('this is the authorImageURL: ', this.displayedPostDoc.authorImageURL)
      console.log('this is the author alias: ', this.displayedPostDoc.userAlias)

    }  
    
  },
  unmounted(){
    console.log('this is unmounted hook')
  }
 
}
</script>

<style>


/* ---------- START VIEW POST STYLING ---------- */
#view-post {
  width: 100%;
}
#view-post .close-modal {
  color: #f3f3f4;
}
#view-post .disabled-icon {
  display: none;
}
#view-post .enabledIcon {
  display: flex; cursor: pointer;
}
#view-post .enabledIcon svg {
  margin-right: 8px;
}
#view-post .feedback-icons {
  height: 28px;
  margin-right: 0;
}
#view-post h2 {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 16px;
  color: rgba(0,0,0,0.67);
}
#view-post .noteAuthor .material-icons {
  position: absolute;
}

/* ---------- END VIEW POST STYLING ---------- */


/* ---------- START CONCEPT SVG STYLING ---------- */
#view-post .cls-1{fill:#939598;}
#view-post #icon-balance .cls-2 {fill:#58595b;}
#view-post #icon-balance .cls-3 {fill:#fff;}
#view-post #icon-contrast .cls-2{fill:#58595b;}
#view-post #icon-contrast .cls-3{fill:#fff;}
#view-post #icon-emphasis .cls-2{fill:#fff;}
#view-post #icon-emphasis .cls-3{fill:#58595b;}
#view-post #icon-movement .cls-2{fill:#fff;}
#view-post #icon-pattern .cls-2{fill:#fff;}
#view-post #icon-rhythm .cls-2{fill:#f1f2f2;}
#view-post #icon-rhythm .cls-3{fill:#58595b;}
#view-post #icon-rhythm .cls-4{fill:#fff;}
#view-post #icon-unity .cls-2{fill:#fff;}
#view-post #icon-unity .cls-3{fill:#58595b;}
#view-post #icon-line .cls-2{fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px;}
#view-post #icon-shape .cls-2{fill:#fff;}
#view-post #icon-form .cls-2{fill:#fff;}
#view-post #icon-form .cls-3{fill:#58595b;}
#view-post #icon-space .cls-2{fill:#fff;}
#view-post #icon-texture .cls-2{fill:#fff;}
#view-post #icon-value .cls-2{clip-path:url(#clip-path);}
#view-post #icon-value .cls-3,#icon-value .cls-4,#icon-value .cls-5,#icon-value .cls-6{fill:#58595b;}
#view-post #icon-value .cls-3{opacity:0.25;}
#view-post #icon-value .cls-4{opacity:0.5;}
#view-post #icon-value .cls-5{opacity:0.75;}
#view-post #icon-color .cls-2,#icon-color .cls-3,#icon-color .cls-4{fill:#f1f2f2;}
#view-post #icon-color .cls-3{opacity:0.66;}
#view-post #icon-color .cls-4{opacity:0.33;}
/* ---------- END CONCEPT SVG STYLING ---------- */


/* ---------- START POST HEADER STYLING ---------- */
#view-post #postHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 1em 1em;
  transition: .3s ease-out;
  width: 100%;
}
#view-post #postHeader i {
  font-size: 48px;
}
#view-post #postHeader h1 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
#view-post #postHeader h2 {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
#view-post #postHeader #authorAndTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#view-post #postHeader #authorAndTitle .material-icons {
  position: absolute;
}
#view-post #postHeader #authorAndTitle #titleAndAuthorContainer {
  max-width: 200px;
}
#view-post #postHeader #authorProfileImageContainer {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
#view-post #postHeader #authorProfileImageContainer .profileImageInnerContainer {
  position: absolute;
  z-index: 2222;
}
#view-post #postHeader #authorProfileImageContainer .profileImageInnerContainer img {
  clip-path: circle(42%);
  height: 48px;
  width: 48px;
}
/* ---------- END POST HEADER STYLING ---------- */


/* ---------- START MODE BADGE STYLING ---------- */
#view-post #modeBadgesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2222;
  margin-top: 2px;
  margin-left: 60px;
}
#view-post #modeBadgesContainer label {
  display: none;
  margin-bottom: 8px;
  font-size: 14px;
  text-transform: uppercase;
  color: #cdcdcd;
}
#view-post #modeBadgesContainer #modeBadges {
  display: flex;
  justify-content: space-between;
}
#view-post #modeBadgesContainer #modeBadges .badgeContainer {
  align-items: center;
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  max-width: 110px;
}
#view-post #modeBadgesContainer #modeBadges .badgeContainer:first-of-type {
  margin-right: .25em;
}
#view-post #modeBadgesContainer #modeBadges .toolTip {
  visibility: hidden;
  color: #939598 !important;
  background-color: #ddd;
  border-radius: 8px;
  margin-top: 2em;
  min-width: 120px;
  padding: .5em;
  position: absolute;
  text-align: center;
  z-index: 9999;
}
#view-post #modeBadgesContainer #modeBadges .toolTip p {
  margin: 0;
}
#view-post #modeBadgesContainer #modeBadges .badgeContainer:hover .toolTip {
  visibility: visible;
}
#view-post #modeBadgesContainer #modeBadges .toolTip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ddd transparent;
}
#view-post #modeBadgesContainer .modeBadge {
  background-color: #939598;
  color: #fff;
  padding: 1px 3px;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 4px;
}
/* ---------- END MODE BADGE STYLING ---------- */


/* ---------- START POST IMAGE STAGE STYLING ---------- */
#view-post #postImageStage {
  display: flex;
  justify-content: space-between;
}
#view-post #postImageStage .imageIndicator {
  align-items: center;
  background-color: #DDD;
  border-radius: 4px;
  color: #939598;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 22px;
  justify-content: center;
  left: 0;
  margin-left: 1em;
  position: absolute;
  top: 0;
  width: 22px;
}
#view-post div#postImageStage .halfWidthImg {
  position: relative;
  width: 48%;
}
#view-post #postImageStage .full-width-image {
  width: 100%;
}
#view-post #postImageStage img {
  border-radius: 0px;
  width: 100%;
}
#view-post #postImageStage.spaceEvenly{
  justify-content: space-evenly;
}
#view-post div#postImageStage .postImageContainer {
  display: flex;
  justify-content: center;
}
/* ---------- END POST IMAGE STAGE STYLING ---------- */


/* ---------- START NOTICE BAR STYLING ---------- */
#postNoticeBar, #noteNoticeBar, #editNoticeBar {
  background-color: #D97AEB;
  margin-bottom: 1em;
  padding: 12px;
}
#postNoticeBar h2, #noteNoticeBar h2, #editNoticeBar h2 {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  margin: 0 auto;
  text-align: center;
}
/* ---------- END NOTICE BAR STYLING ---------- */


/* ---------- START INTERFACE STYLING ---------- */
#view-post #interfaces {
  margin: 0 auto 1em;
  transition: .3s ease-out;
  width: 100%;
}
#view-post #interfaces .interfaceContainer.active {
  max-height: 10000px;
  transition: .3s ease-out;
}
#view-post #interfaces .interfaceContainer {
  display: block;
  position: relative;
  max-height: 0;
  width: 100%;
  overflow: hidden;
  transition: .3s ease-out;
}
/* ---------- END INTERFACE STYLING ---------- */


/* ---------- START CRITIQUE INTERFACE STYLING ---------- */
#view-post #critiqueInterfaceContainer #critiqueInterface {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 10px 0;
}
#view-post #critiqueInterfaceContainer #critiqueInterface #submitCritique {
  transition: .3s ease-out;
  width: 100%;
}
#view-post #critiqueInterfaceContainer #critiqueInterface #critiqueSteps {
  border-bottom: none;
  border-top: 1px solid #ddd;
  display: inline-block;
  margin-bottom: 1em;
  padding: 1em 1em 0 1em;
  width: 100%;
}
#view-post #critiqueInterfaceContainer #critiqueInterface #critiqueSteps .error-feedback {
  position: relative;
}
#view-post #critiqueInterfaceContainer #critiqueInterface #critiqueSteps #submit-buttons {
  display: flex;
  justify-content: space-around;
}
#view-post #critiqueInterfaceContainer #critiqueInterface #critiqueSteps #submit-buttons #submitCritiqueButton {
  width: 50%;
}
#view-post #critiqueInterfaceContainer #commentsContainer {
  border-top: 1px solid #ddd;
  border-bottom: none;
  margin-bottom: 0;
  padding: 1em 1em 0 1em;
}
#view-post #critiqueInterfaceContainer #critiqueBrief {
  border-bottom: none;
  margin-bottom: 1em;
  margin-top: 1em;
  padding: 0 1em;
}
#view-post #critiqueInterfaceContainer #critiqueBrief p {
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 4px;
}
#view-post #critiqueInterfaceContainer #critiqueBrief #critiqueBriefHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
#view-post #critiqueInterfaceContainer #critiqueBrief #critiqueBriefHeader i {
  color: #9B7EDE;
  cursor: pointer;
}
#view-post #critiqueInterfaceContainer #loadMoreComments.btn {
  margin-bottom: 0;
}
#view-post #critiqueInterfaceContainer #toggleCommentsContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}
/* ---------- END CRITIQUE INTERFACE STYLING ---------- */


/* ---------- START COLLAPSIBLE CRITIQUE INTERFACE STYLING ---------- */
#view-post #collapsibleCritiqueInterface #workingStage .selectedIcon svg .cls-1 {
  fill: #0CA299;
}
#view-post #collapsibleCritiqueInterface #notWorkingStage .selectedIcon svg .cls-1 {
  fill: #EE3B6C;
}
#view-post #collapsibleCritiqueInterface .collapsibleContainer {
  border-bottom: 1px solid #ddd;
}
#view-post #collapsibleCritiqueInterface .collapsibleContainer:last-of-type {
  border-bottom: none
}
#view-post #collapsibleCritiqueInterface li:first-of-type {
  margin-top: -1em;
}
#view-post .active #collapsibleCritiqueInterface .active .collapsibleBody {
  max-height: 440px;
  overflow: visible;
  transition: .3s ease-out;
}
#view-post .active #collapsibleCritiqueInterface .active .collapsibleHeader {
  opacity: 1;
}
#view-post #collapsibleCritiqueInterface .collapsibleBody {
  display: block;
  max-height: 0;
  width: 100%;
  overflow: hidden;
  transition: .3s ease-out;
}
#view-post #collapsibleCritiqueInterface .collapsibleBody .add-comment {
  border: none;
  border-radius: 6px;
  min-height: 280px;
  line-height: 24px;
  padding: 16px;
  margin-bottom: 10px;
  background-color: #f3f3f4;
}
/* ---------- START CONCEPT BUTTON CONTAINER STYLING ---------- */
#view-post #collapsibleCritiqueInterface .collapsibleBody .conceptButtonsContainer {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  padding: 0;
  width: 100%;
}
#view-post #collapsibleCritiqueInterface .collapsibleBody .conceptButtonsContainer .conceptsButtons{
  padding: 0;
  width: 50%;
}
#view-post #collapsibleCritiqueInterface .collapsibleBody .conceptButtonsContainer .conceptsButtons .conceptSelectionButton {
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
  transition: .3s ease-out;
}
#view-post #collapsibleCritiqueInterface .collapsibleBody .conceptButtonsContainer .conceptsButtons .conceptSelectionButton.conceptSelectionButtonDisabled { 
  cursor: default;
  opacity: .66;
  transition: .3s ease-out;
}
#view-post #collapsibleCritiqueInterface .collapsibleBody .conceptButtonsContainer .conceptsButtons .conceptSelectionButton div {
  display: flex;  
}
#view-post #collapsibleCritiqueInterface .collapsibleBody .conceptButtonsContainer .conceptsButtons i {
  margin-left: auto;
  margin-right: 10px;
  font-size: 24px;
  color: #939598;
}
#view-post #collapsibleCritiqueInterface .collapsibleBody .conceptButtonsContainer .conceptsButtons svg.addNote {
  height: 20px;
  margin-left: auto;
  margin-right: 10px;
  /* font-size: 24px; */
  fill: #939598;
}
#view-post #collapsibleCritiqueInterface .collapsibleBody .conceptButtonsContainer .conceptsButtons i.selectedNote {
  color: #9B7EDE;
}
#view-post #collapsibleCritiqueInterface .collapsibleBody .conceptButtonsContainer .conceptsButtons svg.selectedNote path {
  fill: #9B7EDE;
}
#view-post #collapsibleCritiqueInterface .collapsibleBody .conceptButtonsContainer div label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #cdcdcd;
}
#view-post #collapsibleCritiqueInterface .collapsibleBody .conceptButtonsContainer p {
  margin: 0;
  text-transform: capitalize;
}
/* ---------- END CONCEPT BUTTON CONTAINER STYLING ---------- */
#view-post #collapsibleCritiqueInterface .collapsibleHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 48px;
  font-weight: 600;
  color: rgba(0,0,0,0.67);
  opacity: .33;
}
/* ---------- START CONCEPT SELECTION INDICATOR STYLING ---------- */
#view-post #collapsibleCritiqueInterface .collapsibleHeader .conceptSelectionIndicator {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
#view-post #collapsibleCritiqueInterface .collapsibleHeader .conceptSelectionIndicator .feedback-icons {
  margin-left: .25em;
}
#view-post #collapsibleCritiqueInterface .collapsibleHeader .conceptSelectionIndicator .conceptSelections {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  margin-top: 8px;
}

#view-post #collapsibleCritiqueInterface .collapsibleHeader .conceptSelectionIndicator .conceptSelections .selectedWorkingTags svg .cls-1 { 
  fill: #0CA299;
}
#view-post #collapsibleCritiqueInterface .collapsibleHeader .conceptSelectionIndicator .conceptSelections .selectedNotWorkingTags svg .cls-1 {
  fill: #EE3B6C;
}
#view-post #collapsibleCritiqueInterface .collapsibleHeader .conceptSelectionIndicator .newConceptNotes {
  display: none;
  margin-left: 1em;
}
/* ---------- END CONCEPT SELECTION INDICATOR STYLING ---------- */
#view-post #collapsibleCritiqueInterface .collapsibleHeader h2 {
  font-size: 1.4em;
  font-weight: 600;
  margin: 0;
  color: rgba(0,0,0,0.67);
}
#view-post #collapsibleCritiqueInterface .collapsibleHeader .material-icons {
  margin-right: 4px;
  font-size: 18px;
}
#view-post #collapsibleCritiqueInterface .collapsibleLabel {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
/* ---------- END COLLAPSIBLE CRITIQUE INTERFACE STYLING ---------- */


/* ---------- START VERSUS STAGE STYLING ---------- */
#view-post #versusStage .bodyContainer div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}
#view-post #versusStage .bodyContainer div span {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ddd;
  width: 60px;
  height: 60px;
  border-radius: 6px;
  font-size: 3em;
  padding-bottom: 6px;
  color: #fff;
  cursor: pointer;
}
#view-post #versusStage .bodyContainer div span.selected {
  background-color: #0CA299;
}
#view-post #versusStage #versusSelectionIndicators {
  margin-left: 14px;
}
#view-post #versusStage #versusSelectionIndicators span {
  justify-content: center;
  align-items: center;
  background-color: #0CA299;
  width: 28px;
  height: 28px;
  border-radius: 6px;
  font-size: 1.5em;
  color: #fff;
  cursor: pointer;
}
/* ---------- END VERSUS STAGE STYLING ---------- */


/* ---------- START COMMENTS CONTAINER STYLING ---------- */
#view-post #commentsContainer .comment {
  border-bottom: 1px solid #ddd;
  margin-bottom: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
}
#view-post #commentsContainer .comment .material-icons {
  font-size: 24px;
}
#view-post #commentsContainer .comment:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
}
#view-post #commentsContainer .comment:last-of-type .noteDate {
  margin-bottom: 0;
}
#view-post #commentsContainer .comment .critiquerProfileImageContainer {
  width: 24px;
  height: 24px;
  margin-right: 0;
}
#view-post #commentsContainer .comment .critiquerProfileImageContainer .profileImageInnerContainer {
  position: absolute;
  z-index: 2222;
}
#view-post #commentsContainer .comment .critiquerProfileImageContainer .profileImageInnerContainer img {
  clip-path: circle(42%);
  height: 24px;
  width: 24px;
}
#view-post #commentsContainer #loadMoreCommentsContainer {
  margin-bottom: 1em;
  text-align: center;
}
#view-post #commentsContainer #toggle-comments.btn {
  margin-bottom: 0;
}
/* ---------- END COMMENTS CONTAINER STYLING ---------- */


/* ---------- START RESULTS STAGE STYLING ---------- */
#view-post #resultsStage {
  border-top: 1px solid #ddd;
  padding: 1em 1em 0 1em;
  transition: .3s ease-out;
  width: 100%;
}
#view-post #resultsStage #conceptsResultsContainer .enabledIcon {
  cursor: default;
}
#view-post #resultsStage #conceptsResultsContainer .tag-results {
  align-items: center;
  justify-content: space-between;
  margin-bottom: .5em;
}
#view-post #resultsStage #conceptsResultsContainer .tag-results span.tagIconContainer {
  margin-right: .5em;
  width: auto;
  display: flex;
  align-items: center;
}
#view-post #resultsStage #conceptsResultsContainer .tag-results svg {
  height: 28px;
  /* margin-right: 0; */
}
#view-post #resultsStage .directions {
  color: #aaa;
  display: block;
  margin-bottom: .5em;
}
#view-post #resultsStage #results-stage-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
#view-post #resultsStage #results-stage-header i {
  color: #9B7EDE;
}
#view-post #resultsStage .versusPollBar, #view-post  #resultsStage .working-poll-bar, #view-post #resultsStage .not-working-poll-bar {
  background-color: #0CA299;
  border-radius: 9px;
  height: 12px;
  transition: .3s ease-out;
  width: var(--width);
}
#view-post .tag-poll-results-container .working-poll-bar {
  margin-bottom: .2em;
}
#view-post #resultsStage .working-poll-bar {
  background-color: #0CA299
}
#view-post #resultsStage .not-working-poll-bar {
  background-color: #EE3B6C;
}
#view-post #resultsStage #versusResults ul {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
#view-post #resultsStage #versusResults ul .versusResultsContainer {
  width: 50%;
}
#view-post #resultsStage #versusResults ul .versusResultsContainer:first-of-type {
  margin-right: 1em;
}
#view-post #resultsStage .workingColor svg .cls-1 {
  fill: #0CA299
}
#view-post #resultsStage .notWorkingColor svg .cls-1 {
  fill: #EE3B6C
}
#view-post #resultsStage .tag-poll-results-container {
  position: relative;
  width: 100%;
  background-color: #f3f3f4;
  border-radius: 9px;
} 
#view-post #resultsStage .tagData {
  visibility: hidden;
  position: absolute;
  z-index: 2222;
  top: -5px;
  left: 0;
  width: 100%;
  border-radius: 9px;
  padding: 0 10px;
  background-color: rgba(25, 27, 64, .97);
  color: white;
  font-size: 14px;
  text-align: center;
}
#view-post #resultsStage [data-workingstatus="working"].tagData {
  background-color: #0CA299
}
#view-post #resultsStage [data-workingstatus="notWorking"].tagData {
  background-color: #EE3B6C;
}
#view-post #resultsStage .tag-polls-container{
  width: 100%;
}
#view-post #resultsStage .tag-poll-results-container:hover .tagData {
  visibility: visible;
  cursor: pointer;
}
#view-post #resultsStage .tag-poll-results-container:hover .tagData.noNotes {
  visibility: visible;
  cursor: default;
} 
#view-post #resultsStage .tag-poll-results-container.markedIcon{
  background-color: rgba(0,0,0,0.24);
}
#view-post #resultsStage .versusPollResultsContainer {
  margin-top: 4px;
  margin-bottom: .75em;
  background-color: #f3f3f4;
  border-radius: 9px;
}
#view-post #resultsStage .selected .versusPollResultsContainer {
  background-color: rgba(0,0,0,0.24);
}

/* ---------- END RESULTS STAGE STYLING ---------- */


/* ---------- START BUTTON STAGE STYLING ---------- */
#view-post #button-stage {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 1em;
  padding: 0 1em;
  text-align: center;
  transition: .3s ease-out;
  width: 100%;
}
#view-post #button-stage button {
  margin-bottom: 1em;
  width: 100%;
}
/* #view-post #button-stage #cancel-edit,
#view-post #button-stage #delete-post {
  width: auto;
} */
#view-post #button-stage #cancel-edit {
  margin-right: 1em;
}
#view-post #button-stage div {
  display: flex;
  justify-content: flex-start;
}
/* ---------- END BUTTON STAGE STYLING ---------- */


/* ---------- START EDIT POST STAGE STYLING ---------- */
#view-post #editStage #edit-header {
  margin-top: 1em;
  padding-left: 1em;
}
#view-post #editStage form {
  padding: 1em 1em .5em;
}
#view-post #editStage form div {
  margin-bottom: 1em;
}
#view-post #editStage form div:last-of-type {
  margin-bottom: 0;
}
/* ---------- END EDIT STAGE STYLING ---------- */


/* ---------- START NOTE MODAL STYLING ---------- */
#view-post #noteModal .noteButtons {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}
#view-post .noteModalOn {
  display: flex;
  opacity: 1;
  z-index: 4444;
  -webkit-transition: opacity .2s ease-out;
  transition: opacity .2s ease-out;
}
#view-post #noteModalContent {
  position: absolute;
  top: 64px;
  width: 40%;
  margin-bottom: 64px;
  max-width: 400px;
  padding: 1.5em;
  border-radius: 8px;
  background-color: #fff;
  width: 90%;
}
#view-post #noteModalContent #addNoteTextarea {
  border: none;
  border-radius: 6px;
  min-height: 280px;
  line-height: 24px;
  padding: 16px;
  margin-bottom: 10px;
  background-color: #f3f3f4;
}
#view-post #noteModalHeader {
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  text-transform: capitalize;
  font-size: 1.4em;
  font-weight: 600;
  color: rgba(0,0,0,0.67);
}
#view-post #noteModalHeader i {
  color: #939598;
}
#view-post #notesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#view-post #notesContainer .critiquerProfileImageContainer {
  width: 24px;
  height: 24px;
  margin-right: 0;
}
#view-post #notesContainer .profileImageInnerContainer {
  position: absolute;
  z-index: 2222;
}
#view-post #notesContainer .profileImageInnerContainer img {
  clip-path: circle(42%);
  height: 24px;
  width: 24px;
}
#view-post .note {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
#view-post .noteAuthor {
  display: flex;
}
#view-post .noteBody {
  margin-top: 0;
  margin-bottom: 4px; 
  margin-left: 0px;
}
#view-post .noteDate {
  font-size: 14px;
  color: #6e6d7a;
  display: block;
  margin-bottom: 8px;
  margin-left: 0px;
}
#view-post .authorName {
  margin-left: 6px;
  text-transform: capitalize;
  font-weight: 700;
  font-weight: 500;
  font-size: 16px;
}
/* ---------- END NOTE MODAL STYLING ---------- */


/* ---------- START HELP MODAL STYLING ---------- */
#view-post #critiqueHelpModal {
  display: none;
}
#view-post  #critiqueHelpModal.critiqueHelpModalOn {
  display: flex;
  opacity: 1;
  z-index: 5555;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent {
  position: absolute;
  top: 64px;
  margin-bottom: 64px;
  padding: 1em;
  border-radius: 8px;
  background-color: #fff;
  width: 90%;
  max-width: 400px;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent div.help {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1.75em;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent div.help:last-of-type {
  border-bottom: none;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent .conceptContent {
    display: none;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent div.conceptContent.active {
    display: block;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent #modeHelp p {
  margin-bottom: 0;
  margin-top: .5em;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent #modeHelp ul {
  display: grid;
  grid-gap: 1em;
  margin-bottom: 1.25em;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent #modeHelp ul li {
  align-items: flex-start;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: .75em;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent #modeHelp ul li:nth-of-type(1),
#view-post #critiqueHelpModal #critiqueHelpModalContent #modeHelp ul li:nth-of-type(2) {
    grid-row-start: 1;
    grid-row-end: 2;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent #modeHelp ul li:nth-of-type(3),
#view-post #critiqueHelpModal #critiqueHelpModalContent #modeHelp ul li:nth-of-type(4) {
    grid-row-start: 2;
    grid-row-end: 3;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent #modeHelp ul li .modeBadge {
  background-color: #939598;
  color: #fff;
  padding: 1px 3px;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 4px;
  margin-right: 1em;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent #resultsHelp ul,
#view-post #critiqueHelpModal #critiqueHelpModalContent #workingHelp ul {
  list-style-position: outside;
  padding-left: 1.5em;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent #resultsHelp ul li,
#view-post #critiqueHelpModal #critiqueHelpModalContent #workingHelp ul li {
  list-style-type: circle;
  margin-bottom: 1em;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent #conceptsMenu ul {
  border: 1px solid #ddd;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 1em;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent #conceptsMenu ul .menuItemContainer {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent #conceptsMenu ul .menuItemContainer div {
  display: flex;
  margin-right: 1em;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent li.conceptsMenuItem {
  border-bottom: 1px solid #ddd;
  display: none;
  padding: 10px 16px
}
#view-post #critiqueHelpModal #critiqueHelpModalContent li.conceptsMenuItem.active {
  border-bottom: none;
  display: block;
  font-weight: 700;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent #conceptsMenu.open li.conceptsMenuItem {
  display: block;
}

#view-post #critiqueHelpModal #critiqueHelpModalContent #conceptsMenu.open li.conceptsMenuItem.active {
  border-bottom: 1px solid #ddd;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent li.conceptsMenuItem:last-of-type {
    border-bottom: none;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent fieldset {
  margin-top: 1.25em;
}
#view-post #critiqueHelpModal #critiqueHelpModalContent p {
  margin-top: .75em;
}
/* ---------- END HELP MODAL STYLING ---------- */


@media only screen and (min-width: 401px){
  #view-post #postHeader {
    transition: .3s ease-out;
    width: 90%;
  }
  #view-post #interfaces, #view-post #button-stage {
    transition: .3s ease-out;
    width: 90%;
  }
}
@media only screen and (min-width: 501px){
  #view-post #collapsibleCritiqueInterface .collapsibleHeader .conceptSelectionIndicator .newConceptNotes{
    display: block;
  }
  #view-post #postHeader {
    transition: .3s ease-out;
    width: 85%;
  }
  #view-post #interfaces, #view-post #button-stage {
    transition: .3s ease-out;
    width: 85%;
  }
}
@media only screen and (min-width: 601px){
  #view-post #postHeader {
    align-items: center;
    flex-direction: row;
    transition: .3s ease-out;
    width: 80%;
  }
  #view-post #modeBadgesContainer {
    margin-top: 0;
    margin-left: 0;
  }
  #view-post #modeBadgesContainer label {
    display: block;
  }
  #view-post #interfaces, #view-post #button-stage {
    transition: .3s ease-out;
    width: 80%;
  }
}
@media only screen and (min-width: 701px){
  #view-post #postHeader {
    transition: .3s ease-out;
    width: 75%;
  }
  #view-post #interfaces, #view-post #button-stage {
    transition: .3s ease-out;
    width: 75%;
  }
}
@media only screen and (min-width: 801px){
  #view-post #postHeader {
    transition: .3s ease-out;
    width: 70%;
  }
  #view-post #interfaces, #view-post #button-stage {
    transition: .3s ease-out;
    width: 70%;
  }
}
@media only screen and (min-width: 901px){
  #view-post #postHeader {
    padding: 0 0 2em;
    transition: .3s ease-out;
    width: 100%;
  }
  #view-post #postHeader #authorAndTitle #titleAndAuthorContainer {
    max-width: 300px;
  }
  #view-post #editStage #edit-header {
    padding-left: 0;
  }
  #view-post #editStage form {
    padding: 1em 0 .5em;
  }
  #view-post #postImageStage {
    margin-bottom: 1em;
  }
  #view-post #postImageStage .imageIndicator {
    margin-left: 0;
  }
  #view-post #postImageStage img {
    border-radius: 8px;
  }
  #view-post #interfaces, #view-post #button-stage {
    transition: .3s ease-out;
    width: 100%;
  }
  #view-post #button-stage {
    justify-content: space-between;
    padding: 0;
    text-align: initial;
  }
  #view-post #button-stage button {
    width: auto;
  }
  #view-post #button-stage #edit-post, #view-post #button-stage #resubmit-feedback {
    margin: 0 auto;
  }
  #view-post #critiqueInterfaceContainer #critiqueInterface {
    flex-direction: row;
  }
  #view-post #critiqueInterfaceContainer #commentsContainer {
    padding: 1em 0 0;
  }
  #view-post #critiqueInterfaceContainer #critiqueBrief {
    padding: 0;
  }
  #view-post #critiqueInterfaceContainer #critiqueInterface #submitCritique {
    margin-right: 2em;
    width: 60%;
  }
  #view-post #critiqueInterfaceContainer #critiqueInterface #critiqueSteps {
    padding: 1em 0 0;
  }
  #view-post #resultsStage {
    border-top: none;
    padding: 1em 0 0;
    transition: .3s ease-out;
    width: 40%;
  }
}
</style>