<template>
  <div id="MGallery">

  <!-- <p @click="callScheduledFunction()">click me for test of scheduled function for clean up!</p> -->

    <!-- filter bar -->
    <div id="filterBar" class="container">

      <ul>
        <li title="All" @click="filterUserPosts" data-postCat="all" class="category selected">All</li>
        <li title="Art" @click="filterUserPosts" data-postCat="art" class="category">Art</li>
        <li title="Graphic Design" @click="filterUserPosts" data-postCat="grd" class="category">Graphic Design</li>
        <li title="Illustration" @click="filterUserPosts" data-postCat="ill" class="category">Illustration</li>
        <li title="UI/UX" @click="filterUserPosts" data-postCat="uix" class="category">UI/UX</li>
        <li title="Web Design" @click="filterUserPosts" data-postCat="web" class="category">Web Design</li>
      </ul>

    </div>

    <!-- posts display -->
    <div class="index container">

      <!-- post card(s) -->
      <div class="postCard" v-for="post in postsToDisplay" :key="post.id">

        <!-- post card content -->
        <div class="postCardContent">

          <!-- post image container -->
          <div class="postCardImageContainer">

            <img v-bind:id="post.id" :src="post.imageURLs[0]">

          </div>

          <div>

            <router-link :to="{ name: 'DisplayPost', params: { displayPostId: post.id}}" class="postCardOverlay">
                <span class="postTitle">{{ post.postTitle }}</span>
            </router-link>

          </div>

        </div>

        <!-- post info -->
        <div class="postCardInfo">

          <!-- author info -->
          <div class="author" @click="loadProfile(post.userId, post.userAlias)">

            <img v-if="authorImagesLoaded && post.authorImageURL" :src="post.authorImageURL" alt="" class="profile-photo">
            <i class="material-icons">account_circle</i><span class="authorAlias">{{ post.userAlias }}</span>

          </div>

          <!-- data info -->
          <div class="data">

            <!-- view info -->
            <span class="views"><i class="material-icons">visibility</i><span>{{ post.views }}</span></span>        
            <!-- critique info -->
            <span class="votes"><i class="material-icons">check_box</i><span v-if="!post.feedbackTotal">0</span><span v-if="post.feedbackTotal">{{ post.feedbackTotal }}</span></span><!-- change name of span to feedback-total -->
          
          </div>

        </div>

      </div>

    </div>

    <div id="loadMoreButtonContainer">
      <button v-bind:class="{ disabled: this.allPostsLoaded[this.filterCat]}" class="btn" @click="loadPosts">Load More</button>
    </div>

    <div v-if="modalOn" id="post-modal" v-bind:class="{ modalOn: modalOn }" class="post-modal" @click.self="closeModal">
      
      <i @click.self="closeModal" class="material-icons close-modal">close</i>
      
      <div class="post-modal-content">

        <!-- opened posts are displayed here -->
        <router-view :postDoc="postDoc" :user="user" @closePostModal="closeModal" @notice="emitNotice"></router-view>
      
      </div>
   
    </div>

  </div>
</template>

<script>

import { projectFunctions } from '@/firebase/config'
import { projectFirestore } from '@/firebase/config'

export default {
  name: 'MGallery',
  props: {
    user: Object,
    postModalOn: Boolean,
    profileInfo: Object
    
  },
  watch: {

    // when 'profileInfo' changes, route to 'Profile.vue' using 'alias' from 'profileInfo' as route param

    profileInfo: {

      handler: function(newVal, oldVal) {

        console.log('The profileInfo prop value was: ', oldVal)

        console.log('The profileInfo prop value has changed to: ', newVal)

        // route to profile (which will have access to the profileInfo prop

        // ISSUE we do not want to route to profile if this profileInfo change was triggered by noti click....

        this.$router.push({ name: 'Profile', params: { displayName: newVal.alias}});
        
      },

      deep: true

    },

    postModalOn: {  // Note: postModalOn is set to true by root component if route is to DisplayPost
                    // Note: postModalOn is set to false by root component if route is from 'post to MGallery'
      handler: function(newVal, oldVal) {

        console.log('The postModalOn prop value has changed to: ', newVal)

        if(newVal === true){

          console.log('Call openModal() with post Id.')

          // postModalOn is true so call openModal()

          this.openModal(this.$route.params.displayPostId)

        }

      }, 

      deep: true
    
    }

  },
  computed: {

    // filter out any posts that do not have imageURLS

    postsToDisplay: function (){

      return this.posts.filter(post => {

        return post.imageURLs != undefined

      })

    }

  },
  data(){
      return {
        postAuthorPool: [],     // ids from all authors being displayed
        returnedAuthorRecords: [],
        authorImagesLoaded: false,
        postQueryLimit: 9,      // controls how many post can be retreived initially and when loading more
        filterCat: 'all',
        posts: [],              // all posts being displayed
       
        lastQueryLocations: {
          all: null,
          art: null, 
          grd: null,
          ill: null, 
          uix: null,
          web: null
        },

        initialPosts: [],       // all posts initially loaded
        filteredPosts: {},      // all filtered posts // ISSUE should this be an empty array or objet to begin with?
        allPostsLoaded: {       // object with boolean propery for each catagory
          all: false,
          art: false,
          grd: false,
          ill: false,
          uix: false,
          web: false
        },
        postDoc: null,           // this is sent out as a prop, it can either be an full post object or an object with post id only
        openPostId: [],         // to be used by child (modal)
        openPost: [],           // to be used by child (modal) // NEED to verify this is being used... i think it can be deleted
        narrowMode: false,      // sets to true if e.target.classList.contains('narrowMode), narrowMode added to class list when post.focusedFeedback is true
        modalOn: false,          // controls post modal

        profileDisplayName: null,
        profileName: null,
        profileBio: null,
        profileLocation: null
      }
  },
  methods: {
    // ISSUE remove this test method after testing
    callScheduledFunction(){
      console.log('Call cloud callScheduledFunction().')

      let scheduledFunction = projectFunctions.httpsCallable('scheduledFunction')

      scheduledFunction().then(result => {

        console.log('This is the result from scheduled function: ', result)

      }).catch(error => {

        console.log('There was an error with the scheduled function: ', error)

      })

    },
    emitNotice (notice) {

      console.log('this is the notice emitted from child component openpost:', notice)

      this.$emit('notice', notice)

    },
    closeModal(options){

      console.log('Start closeModal().');
      console.log('This is the options value: ', options)

      if(options !== undefined){

        if(options.deletePost){

          // filter out deleted post from this.posts

          this.posts = this.posts.filter(post => {

            return post.id !== options.deletePost

          })

        }

      }
      

      const body = document.getElementsByTagName("BODY")[0];

      body.classList.remove("noscroll");

      // turn modal off
      this.modalOn = false;

      // // reset post images
      // document.getElementById('postImageStage').innerHTML = [];

      // reset doc data
      this.openPost = [];

      // this.narrowMode = false;

      // this.authorImageURL = null;

      console.log('This is the options value: ', options)      

      if(options === '404'){

        this.$router.push({ name: 'NotFound', params: { catchAll: '404' }});

      } else {

        // reset post images
        document.getElementById('postImageStage').innerHTML = [];

        console.log('Route back to MGallery.');

        this.$router.push({ name: 'MGallery' });

      };

    },
    openModal(postId){ 

      console.log('Start openModal().')

      console.log('We are opening post modal with this post id: ', postId)

      // filter postsToDisplay to see if this postId matches any displayed posts
      // we will find match if user has opened post from MGallery
      // when we find a match, set postDoc equal to that post

      let fullPostObject = this.postsToDisplay.filter(post => {

        return post.id === postId

      })

      if(fullPostObject.length > 0){

        console.log('We have a full post object that matches the post id.')

        console.log('This is the filtered full post object: ', fullPostObject[0])

        this.postDoc = fullPostObject[0]

      } else {

        console.log('We do NOT have a full post object that matches the post id.')

        this.postDoc = {

          id: postId

        }

      }

      console.log('This is the postDoc object to send to DisplayPost as prop: ', this.postDoc)

      const body = document.getElementsByTagName("BODY")[0]
      body.classList.add("noscroll")
      this.modalOn = true
      this.$emit('notice', '')
    },
    filterUserPosts(e){

      console.log('Start filterUserPosts()')

      console.log('The user has selected the following catagory: ', e.currentTarget.getAttribute('data-postCat'))

      let filterClick = e.currentTarget

      this.filterCat = e.currentTarget.getAttribute('data-postCat')

      let filteredPostsRef = projectFirestore.collection('userPosts').where("processed", "==", true).where('postCat', '==', this.filterCat).orderBy('score').limit(this.postQueryLimit)

      const styleFilterButtons = () => {

        let filterButtons = document.getElementsByClassName('category')

        for(var i = 0; i < filterButtons.length; i++){

          if(filterButtons[i].classList.contains('selected')){

            filterButtons[i].classList.remove('selected')

            filterClick.classList.add('selected')

          }

        }

      }

      const filterPosts = () => {

        // we only get posts if there are none loaded for this catagory

        // otherwise we just display what has already been loaded

        // in otherwords if there are any posts in catagory, 
        // then just display those, dont get more

        // if we are here then no posts have been querried for this catagory

        console.log('Start filterPosts().')

        // this will never run on 'all' catagory because length is always > 1 after created() gets initial posts
        // so the filtered posts ref can include .where() always
        // and we dont need 'startAfter' because we only get docs if this is first query for active catagory

        filteredPostsRef.get().then(filteredDocs => {

          console.log('this is the filteredDocs from userPosts filtered query: ', filteredDocs.docs)

          // check if any docs were returned
          if(filteredDocs.docs.length >= 1){

            this.lastQueryLocations[this.filterCat] = filteredDocs.docs[filteredDocs.docs.length - 1]

            console.log('We have results for this catagory.')

            // style selected filter button

            styleFilterButtons()

            // reset posts

            this.posts = []

            this.authorImagesLoaded = false

            filteredDocs.docs.forEach(doc => {

              let post = doc.data();

              let docId = doc.id//.slice(0, -7);

              let preformedURL1 = 'https://firebasestorage.googleapis.com/v0/b/crittool-prod.appspot.com/o/user-images%2F' + post.userId + '%2F' + docId + '%2F' + post.userId + '-post-image-1?alt=media&token=';

              let preformedURL2 = 'https://firebasestorage.googleapis.com/v0/b/crittool-prod.appspot.com/o/user-images%2F' + post.userId + '%2F' + docId + '%2F' + post.userId + '-post-image-2?alt=media&token=';

              const displayPost = () => {

                // remove 'public' from end of doc id

                post.id = doc.id//.slice(0, -7);

                // create array of all unique userIds on page

                if(!this.postAuthorPool.some(author => author.uid === post.userId)){

                  let authorObject = { uid: post.userId };

                  this.postAuthorPool.push(authorObject);

                };

                // add post to begining of this.posts array

                this.posts.push(post); // unshift(post)

              };

              console.log('This is the post id: ', docId);
              console.log('This is the user id: ', post.userId);
              console.log('This is the 1st preformed url: ', preformedURL1);
              console.log('This is the 2nd preformed url: ', preformedURL2);
              console.log('This is the 1st post.imageURLs: ', post.imageURLs[0]);
              console.log('This is the 2nd post.imageURLs: ', post.imageURLs[1]);

              if(post.imageURLs.length === 1){

                if(post.imageURLs[0].startsWith(preformedURL1)){

                  displayPost();

                };

              } else if(post.imageURLs.length ===2){

                if(post.imageURLs[0].startsWith(preformedURL1) && post.imageURLs[1].startsWith(preformedURL2)){

                  displayPost();

                };

              };

            });

            // store the posts to vue data to avoid repeat query / doc reads

            this.filteredPosts[this.filterCat] = this.posts

            console.log('This is filteredPosts: ', this.filteredPosts)

            console.log('This is the posts array before sorting: ', this.posts)

            // reorder this.posts array by score field

            this.posts.sort((a, b) => (a.score > b.score) ? 1: -1)

            console.log('This is the posts array after sorting: ', this.posts)

            // get all displayed users profile image and display name

            this.getAuthorInfo()

            if(filteredDocs.docs.length < this.postQueryLimit){
          
              this.allPostsLoaded[this.filterCat] = true

              console.log('We got less results than limit allows, so we have all docs now.')
            
            }

          } else {

            console.log('There were no docs returned for this catagory.')

            // need to store empty array in this.filteredPost catagory, so its not undefined
            // then if user 're-filters' and we check for not undefined, we know no results from previous query

            this.allPostsLoaded[this.filterCat] = true

            this.filteredPosts[this.filterCat] = []

            styleFilterButtons()

            // reset posts

            this.posts = []

          }

        }).catch(error => {

          console.log('there was an error getting filtered user posts: ', error)

        })

      }

      const checkFilterCatagory = () => {

        console.log('Start checkFilterCatagory().');

        if(this.filteredPosts[this.filterCat] !== undefined){

          if(this.filteredPosts[this.filterCat].length > 0){

            console.log('These posts have already been stored: ', this.filteredPosts[this.filterCat]);

            // this catagory has been stored before, load stored posts into this.posts array

            this.posts = this.filteredPosts[this.filterCat];

          } else {

            console.log('These posts have been retreived, but no results.');

            // we have already tried to retreive these posts, no resutls for this catagory

            this.posts = [];

          };

        } else {

          console.log('These posts have not been retreived.');

          // we have not tried to get these posts yet, so get filtered posts

          filterPosts();

        };

        styleFilterButtons();

      };

      if(this.filterCat === 'all'){

        this.posts = this.filteredPosts.all;

        // style correct catagory button

        styleFilterButtons();

      } else {

        checkFilterCatagory();

      };

    },
    loadPosts(e){

      console.log('Start loadPosts().')

      // only call this function from 'load more' button click
      // do not call from created for initial posts load, do that seperately (at least for now to simplify coding)

      if(this.allPostsLoaded[this.filterCat]){

        console.log('All posts are already loaded for this catagory.')

        this.posts = this.filteredPosts[this.filterCat]

      } else {

        console.log('All posts are NOT already loaded for this catagory.')

        let postQuery = projectFirestore.collection('userPosts').where("processed", "==", true).where('postCat', '==', this.filterCat).orderBy('score').limit(this.postQueryLimit)

        let lastQueryLocation = this.lastQueryLocations[this.filterCat]

        if(this.filterCat === 'all'){

          postQuery = projectFirestore.collection('userPosts').where("processed", "==", true).orderBy('score').limit(this.postQueryLimit)

        }

        if(lastQueryLocation !== null){

          // this is not initial load of this post catagory, so change post query

          console.log('This is not the initial load of comments from this catagory, add startAfter to doc query.')

          console.log('This is the lastQueryLocation for all: ', this.lastQueryLocations.all)

          if(this.filterCat === 'all'){

            console.log('The filter cat is all.')

            postQuery =  projectFirestore.collection('userPosts').where("processed", "==", true).orderBy('score').startAfter(this.lastQueryLocations.all).limit(this.postQueryLimit)

          } else {

            console.log('The filter cat is not all.')

            postQuery = projectFirestore.collection('userPosts').where("processed", "==", true).where('postCat', '==', this.filterCat).orderBy('score').startAfter(this.lastQueryLocations[this.filterCat]).limit(this.postQueryLimit)

          }

        }
          
        postQuery.get().then(postDocs => {

          console.log('these are the posts from filtered query: ', postDocs.docs)

          if(postDocs.docs.length >= 1){

            if(postDocs.docs.length < this.postQueryLimit){
          
              this.allPostsLoaded[this.filterCat] = true

              console.log('We got less results than limit allows, so we have all docs now.')
            
            }

            this.lastQueryLocations[this.filterCat] = postDocs.docs[postDocs.docs.length - 1]

            // reset this.posts

            this.posts = []

            // add any already queried posts back into this.posts

            this.posts = this.filteredPosts[this.filterCat]

            postDocs.docs.forEach(doc => {
            
              let post = doc.data();

              let isDuplicate = false

              let docId = doc.id//.slice(0, -7);
          
              let preformedURL1 = 'https://firebasestorage.googleapis.com/v0/b/crittool-prod.appspot.com/o/user-images%2F' + post.userId + '%2F' + docId + '%2F' + post.userId + '-post-image-1?alt=media&token=';

              let preformedURL2 = 'https://firebasestorage.googleapis.com/v0/b/crittool-prod.appspot.com/o/user-images%2F' + post.userId + '%2F' + docId + '%2F' + post.userId + '-post-image-2?alt=media&token=';

              const displayPost = () => {

                // remove 'public' from end of doc id

                post.id = doc.id//.slice(0, -7);

                // create array of all unique userIds on page

                if(!this.postAuthorPool.some(author => author.uid === post.userId)){

                  let authorObject = { uid: post.userId };

                  this.postAuthorPool.push(authorObject);

                };

                // add post to begining of this.posts array

                this.posts.push(post); // unshift(post)

              };

              console.log('This is the post id: ', docId);
              console.log('This is the user id: ', post.userId);
              console.log('This is the 1st preformed url: ', preformedURL1);
              console.log('This is the 2nd preformed url: ', preformedURL2);
              console.log('This is the 1st post.imageURLs: ', post.imageURLs[0]);
              console.log('This is the 2nd post.imageURLs: ', post.imageURLs[1]);

              // loop over each property in the filteredPosts object (each properry is a catagorized array of posts)


              for (const postCat in this.filteredPosts) {

                console.log('This is the current filteredPosts catagory: ', postCat)

                // loop over each catagorized post in array

                // this.filteredPosts[postCat].forEach(post => {
                  this.filteredPosts[this.filterCat].forEach(post => {

                  // if post id of a loaded post matches, this is a duplicate

                  if(post.id === docId){

                    // this is a duplicate

                    console.log('This is a duplicate post id: ', post.id)

                    isDuplicate = true

                  }

                })

              }

              if(!isDuplicate && post.imageURLs.length === 1){

                if(post.imageURLs[0].startsWith(preformedURL1)){

                  displayPost();

                };

              } else if(!isDuplicate && post.imageURLs.length ===2){

                if(post.imageURLs[0].startsWith(preformedURL1) && post.imageURLs[1].startsWith(preformedURL2)){

                  displayPost();

                };

              };

            });

            // store all displayed posts into vue data per catagory

            this.filteredPosts[this.filterCat] = this.posts

            // get all displayed users profile image and display name

            this.getAuthorInfo()

          } else {

            console.log('There are no results, so all are displayed or there are none at all.')

            // loading posts returned no results, so we are displaying all results already or there are no results at all

            this.allPostsLoaded[this.filterCat] = true

            if(this.filteredPosts[this.filterCat].length === 0){

              // there are no results to be had for this catagory, set to empty array, 
              // so not undefined, which we check for when filtering posts

              this.filteredPosts[this.filterCat] = []

            }

          }

        }).catch(error => {

          console.log('there was an error getting the posts from userPosts: ', error)

        })

      }

    },
    getAuthorInfo(){

      console.log('Start getAuthorInfo().')

      console.log('This is the postAuthorPool before filtering: ', this.postAuthorPool);
      console.log('This is the existing returnedAuthorRecords: ', this.returnedAuthorRecords)

      // Remove any objects from postAuthorPool that records were already retreived for...

      this.postAuthorPool.forEach(author => {

        console.log('This is the current author uid: ', author.uid)

        if(this.returnedAuthorRecords !== undefined){

          // There are existing returnedAuthorRecords so check for repeat record requests

          this.returnedAuthorRecords.forEach(record => {

            console.log('This is the current existing author record uid: ', record.uid);

            // Filter out any author objects that have their uid already included in returnedAuthorRecords

            this.postAuthorPool = this.postAuthorPool.filter(author => {

              return record.uid !== author.uid;

            });

          });

          console.log('This is the postAuthorPool after filtering: ', this.postAuthorPool);

        };

      });

      const updatePosts = () => {

        // update each post with stored author records

        this.posts.forEach(post => {

          // ISSUE can we use some or for loop here to stop loop once we find match
          
          this.returnedAuthorRecords.forEach(record => {

            if(post.userId === record.uid){

              post.authorImageURL = record.photoURL;

              post.userAlias = record.displayName;

            };

          });

        });

        // Note: Newly loaded posts will not display profile image or alias until next 'load more' click
        // Symptom: If no more posts to load, most recently retreived will not display profile image or alias.
        // Solution: By temp storing the posts elsewhere, reseting the posts to empty array, then reloading them, vue will show profile image and displayname without 'refilter'

        let tempPostHolder = this.posts;

        this.posts = [];           

        this.posts = tempPostHolder;

        this.authorImagesLoaded = true;

      };

      let getAuthorImageURLs = projectFunctions.httpsCallable('getAuthorImageURLs');

      // Check if there are any new uid's to get records for

      if(this.postAuthorPool.length > 0){

        // There are uids that need records

        getAuthorImageURLs({

          userPool: this.postAuthorPool

        }).then(result => {

          console.log('This is the result.data from getAuthorImageURLs: ', result.data);

          // Push each returned record into returnedAuthorRecords

          result.data.forEach(record => {

            console.log('This is the current record: ', record);

            this.returnedAuthorRecords.push(record);

          });

          console.log('This is the returned records: ', this.returnedAuthorRecords)

          updatePosts();

        }).catch(error => {

          console.log('there was an error getting the author image URLs: ', error)

        })

      } else {

        // There are no uids that need records

        updatePosts();

      }


    },
    // NOTE: loadProfile() is only called when a user clicks a profile image
    loadProfile(userId, userAlias){

      console.log('This is the userId for profile display: ', userId)
      console.log('This is the userAlias for profile display: ', userAlias)

      let userIdAndAlias = {

        userId: userId,
        userAlias: userAlias

      }

      // emit event to root, root will populate prop 'profileInfo' with data from 'users' collection doc

      this.$emit('loadProfile', userIdAndAlias)

    },
    getPostDocs(userPostsRef){

      userPostsRef.get().then(userPostsDocs => {

        console.log('this is the userPostsDocs from userPosts standard query: ', userPostsDocs)//.docs)

        if(userPostsDocs.docs.length >= 1){

          this.lastQueryLocations.all = userPostsDocs.docs[userPostsDocs.docs.length - 1]

          console.log('This is the lastQueryLocation for all: ', this.lastQueryLocations.all)

          userPostsDocs.docs.forEach(doc => {

            console.log('This is the current doc.data() returned from userPosts query: ', doc.data())

            let post = doc.data();      

            let docId = doc.id//.slice(0, -7);
            
            let preformedURL1 = 'https://firebasestorage.googleapis.com/v0/b/crittool-prod.appspot.com/o/user-images%2F' + post.userId + '%2F' + docId + '%2F' + post.userId + '-post-image-1?alt=media&token=';

            let preformedURL2 = 'https://firebasestorage.googleapis.com/v0/b/crittool-prod.appspot.com/o/user-images%2F' + post.userId + '%2F' + docId + '%2F' + post.userId + '-post-image-2?alt=media&token=';

            const displayPost = () => {

              // remove 'public' from end of doc id

              post.id = doc.id//.slice(0, -7);

              // create array of all unique userIds on page

              if(!this.postAuthorPool.some(author => author.uid === post.userId)){

                let authorObject = { uid: post.userId };

                this.postAuthorPool.push(authorObject);

              };

              // add post to begining of this.posts array

              this.posts.push(post); // unshift(post)

            };

            console.log('This is the post id: ', docId);
            console.log('This is the user id: ', post.userId);
            console.log('This is the 1st preformed url: ', preformedURL1);
            console.log('This is the 2nd preformed url: ', preformedURL2);
            console.log('This is the 1st post.imageURLs: ', post.imageURLs[0]);
            console.log('This is the 2nd post.imageURLs: ', post.imageURLs[1]);

            if(post.imageURLs.length === 1){

              if(post.imageURLs[0].startsWith(preformedURL1)){

                displayPost();

              };

            } else if(post.imageURLs.length ===2){

              if(post.imageURLs[0].startsWith(preformedURL1) && post.imageURLs[1].startsWith(preformedURL2)){

                displayPost();

              };

            };

          });

          // store all initally displayed posts into vue data, for recall if user resets filter to 'all' after filtering

          this.filteredPosts.all = this.posts;

          // get all displayed users profile image and display name

          this.getAuthorInfo();

        }

      }).catch(error => {

        console.log('there was an error getting the posts from userPosts: ', error);

      });

    }
    
  },
  
  created(){

    console.log('Start created() at MGallery.vue()')

    console.log('This is the route params: ', this.$route.params.displayPostId)  

    console.log('This is the user prop passed from root component: ', this.user)

    // if(this.user === null) {

    //   this.user = { readOnly: true } // cant mutate prop here, need to emit event to have root set this value up 

    // }

    // get user posts from firestore database and storage
    let userPostsRef = projectFirestore.collection('userPosts').where("processed", "==", true).orderBy('score').limit(this.postQueryLimit)

    if(this.postModalOn){

      // if this.postModalOn is true, route is going to DisplayPost

      console.log('this.postModalOn is true, turn modal on, and display this post: ', this.$route.params.displayPostId)

      this.openModal(this.$route.params.displayPostId)

      // even though we are visiting post directly with url, 
      // still need to populateMGallery with normal posts,
      // so when post is closed, MGallery isn't empty.
      
      this.getPostDocs(userPostsRef);

    } else {

      this.getPostDocs(userPostsRef);

    }

    // DONT get snapshot because data and docs will be changing as other users interact with site
    // instead reload the post that was just edited to get new fb total, or refresh the home page occassionally

  },
  // unmounted(){
  //   this.modalOn = false;
  // }

}
</script>

<style>




/* ---------- START LOAD MORE BUTTON STYLING ---------- */
#MGallery #loadMoreButtonContainer {
  display: flex;
  justify-content: center;
}
/* ---------- END LOAD MORE BUTTON STYLING ---------- */




/* ---------- START PROFILE HEADER STYLING ---------- */
/* #MGallery #profileHeader {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
  padding: 0 1em;
}
#MGallery #profileHeader #profilePicture {
  margin-right: 1em;
}
#MGallery #profileHeader #profilePicture img {
  clip-path: circle(42%);
  height: 80px;
  position: absolute;
  width: auto;
}
#MGallery #profileHeader #profilePicture i {
  color: #aaa;
  font-size: 80px;
}
#MGallery #profileHeader #profileInfo {
  text-align: center;
}
#MGallery #profileHeader #profileInfo #profileName {
  font-size: 1.5em;
  font-weight: 500;
}
#MGallery #profileHeader #profileInfo #profileBio {
  margin: 0 0 .5em;
}
#MGallery #profileHeader #profileInfo #profileLocation {
  align-items: center;
  color: #aaa;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 601px) {
  #MGallery #profileHeader {
    margin-top: 2em;
  }
}


@media screen and (min-width: 901px) {
  #MGallery #profileHeader {
    flex-direction: row;
  }
  #MGallery #profileHeader #profilePicture img {
    height: 120px;
  }
  #MGallery #profileHeader #profilePicture i {
  font-size: 120px;
}
  #MGallery #profileHeader #profileInfo {
    max-width: 33%;
    text-align: left;
  }
  #MGallery #profileHeader #profileInfo #profileLocation {
    justify-content: flex-start;
  }
} */
/* ---------- END PROFILE HEADER STYLING ---------- */

</style>