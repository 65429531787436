<template>

  <div v-if="profileInfo" id="Profile">

    <!-- profile header -->
    <div id="profileHeader">

      <!-- ISSUE 1. if user has no posts then their profile image will not display -->
      <!-- need to get image differently... -->

      <!-- ISSUE 2. if there are no posts to display, show message "Upload some work and get some great feedback!" -->

      <div v-if="postsToDisplay[0]" id="profilePicture">
        <img v-if="postsToDisplay[0].authorImageURL" :src="postsToDisplay[0].authorImageURL" alt="" class="profile-photo">
        <i class="material-icons">account_circle</i>
      </div>

      <div id="profileInfo">
        <div id="profileName">{{ profileInfoToDisplay.name }}</div>
        <div id="profileBio">{{ profileInfoToDisplay.bio }}</div>
        <div id="profileLocation">
          <i class="material-icons">location_on</i>{{ profileInfoToDisplay.location }}
        </div>
      </div>

    </div>

    <!-- posts display -->
    <div class="index container">

      <!-- post card(s) -->
      <div class="postCard" v-for="post in postsToDisplay" :key="post.id">

        <!-- post card content -->
        <div class="postCardContent">

          <!-- post image container -->
          <div class="postCardImageContainer">
            <img v-bind:id="post.id" :src="post.imageURLs[0]">
          </div>

          <div>
            <router-link :to="{ name: 'DisplayProfilePost', params: { displayProfilePostId: post.id}}" class="postCardOverlay">
                <span class="postTitle">{{ post.postTitle }}</span>
            </router-link>
          </div>

        </div>

        <!-- post info -->
        <div class="postCardInfo">


          <!-- author info -->
          <div class="author">
            <img v-if="post.authorImageURL" :src="post.authorImageURL" alt="" class="profile-photo">
            <i class="material-icons">account_circle</i><span class="authorAlias">{{ post.userAlias }}</span>
          </div>

          <!-- data info -->
          <div class="data">
            <!-- view info -->
            <span class="views"><i class="material-icons">visibility</i><span>{{ post.views }}</span></span>        
            <!-- critique info -->
            <span class="votes"><i class="material-icons">check_box</i><span v-if="!post.feedbackTotal">0</span><span v-if="post.feedbackTotal">{{ post.feedbackTotal }}</span></span><!-- change name of span to feedback-total -->
          </div>

        </div>

      </div>

    </div>

    <div id="loadMoreButtonContainer">
      <button v-bind:class="{ disabled: this.allPostsLoaded }" class="btn" @click="loadMorePosts">Load More</button>
    </div>

    <div v-if="modalOn" id="post-modal" v-bind:class="{ modalOn: modalOn }" class="post-modal" @click.self="closeProfilePostModal">
      <i @click.self="closeProfilePostModal" class="material-icons close-modal">close</i>
      <div class="post-modal-content">
        <router-view :postDocToOpen="postDocToOpen" :user="user" @closePostModal="closeProfilePostModal"></router-view>
      </div>
    </div>

  </div>

</template>

<script>

import { projectFunctions } from '@/firebase/config'
import { projectFirestore } from '@/firebase/config'

export default {
  name: 'Profile',
  props: {
    user: Object,
    usersDoc: Object,
    profileInfo: Object,
    profilePostModalOn: Boolean
  },
  watch: {

    profilePostModalOn: {

      handler: function(newVal, oldVal) {

        console.log('The profilePostModalOn prop value has changed to: ', newVal)

        if(newVal === true){

          console.log('Call openProfilePostModal() with post Id.')

          // profilePostModalOn is true so call openProfilePostModal()

          this.openProfilePostModal(this.$route.params.displayProfilePostId)  // add post obj or id to this.postDoc prop and send to DisplayPost component, set modalOn to true

        }

      }, 
      
      deep: true

    },

    // NOTE: if user visits thru url, then profile.vue created hook will emit event with displayName only to root to loadProfile 
    // root will then retreive the profile info and send it out as the prop profileInfo
    // when the prop is populated with all the data we need call getPostsForProfile() with correct doc ref

    profileInfo: {

      handler: function(newVal, oldVal) {

        console.log('The profileInfo prop value has changed to: ', newVal)
        console.log('The profileInfo prop value was: ', oldVal)

        // check if we have all needed data

        if(newVal !== null){

          console.log('The newVal is not null.')

          if(oldVal !== null){

            console.log('The oldVal is not null.')

            if(newVal.id !== oldVal.id){

              console.log('The newVal is not same as oldVal.')

              // need to erase previous posts

              this.posts = []

              this.profileInfoToDisplay = newVal;

              this.allPostsLoaded = false;

              let profilePostsRef = projectFirestore.collection('userPosts').where("processed", "==", true).where('userId', '==', this.profileInfoToDisplay.id).limit(this.postQueryLimit)

              this.getPostsForProfile(profilePostsRef)

            } else {

              console.log('The newVal for profileInfo is same as old value.')

            }

          } else {

            // the old val is null, so this is first change we have watched druing this session

            this.profileInfoToDisplay = newVal;

            this.posts = []

            let profilePostsRef = projectFirestore.collection('userPosts').where("processed", "==", true).where('userId', '==', this.profileInfoToDisplay.id).limit(this.postQueryLimit)

            this.getPostsForProfile(profilePostsRef)

          }

        } else {

          console.log('newVal is null')

        }
        
      }

    }

  },
  computed: {

    // filter out any posts that do not have imageURLS

    postsToDisplay: function (){

      return this.posts.filter(post => {

        return post.imageURLs != undefined

      })

    }

  },
  data(){
    return {
      postAuthorPool: [],       // NOTE: this will always only be 1 object because there is only 1 author per profile page
      returnedAuthorRecords: [],
      posts: [],                // all posts to be filtered into postsToDisplay in computed properties
      storedPosts: [],          // sister of 'filteredPosts' from MGallery.vue
      postQueryLimit: 9,        // ISSUE change to 9 later
      lastQueryLocation: null,
      allPostsLoaded: false,
      postDocToOpen: null,
      modalOn: false,
      profileInfoToDisplay: null
    }
  },
  methods: {
    getAuthorInfo(){

      console.log('Start getAuthorInfo() at Profile.vue.')

      console.log('This is the postAuthorPool before filtering: ', this.postAuthorPool);

      this.postAuthorPool.forEach(author => {

        console.log('This is the current author uid: ', author.uid)

      });

      const updatePosts = () => {

        // update each post with stored author records

        this.posts.forEach(post => {
          
          this.returnedAuthorRecords.forEach(record => {

            if(post.userId === record.uid){

              post.authorImageURL = record.photoURL;

              post.userAlias = record.displayName;

            };

          });

        });

        // Note: Newly loaded posts will not display profile image or alias until next 'load more' click
        // Symptom: If no more posts to load, most recently retreived will not display profile image or alias.
        // Solution: By temp storing the posts elsewhere, reseting the posts to empty array, then reloading them, vue will show profile image and displayname without 'refilter'

        let tempPostHolder = this.posts;

        this.posts = [];           

        this.posts = tempPostHolder;

      };

      let getAuthorImageURLs = projectFunctions.httpsCallable('getAuthorImageURLs');

      // Check if there are any new uid's to get records for

      if(this.postAuthorPool.length > 0){

        // There are uids that need records

        getAuthorImageURLs({

          userPool: this.postAuthorPool

        }).then(result => {

          console.log('This is the result.data from getAuthorImageURLs: ', result.data);

          // Push each returned record into returnedAuthorRecords

          result.data.forEach(record => {

            console.log('This is the current record: ', record);

            this.returnedAuthorRecords.push(record);

          });

          console.log('This is the returned records: ', this.returnedAuthorRecords)

          updatePosts();

        }).catch(error => {

          console.log('there was an error getting the author image URLs: ', error)

        })

      } 

    },
    getPostsForProfile(profilePostsRef){

      console.log('Start getPostsForProfile().')

      // run query for profile post docs

      profilePostsRef.get().then(profilePosts => {

        console.log('This is the profilePosts from userPosts standard query: ', profilePosts)

        if(profilePosts.docs.length >= 1){

          // ISSUE rework code below to store location for profile docs

          this.lastQueryLocation = profilePosts.docs[profilePosts.docs.length - 1]      

          console.log('This is the lastQueryLocation for profile: ', this.lastQueryLocation)

          profilePosts.docs.forEach(doc => {

            console.log('This is the current doc.data() returned from profilePostsRef query: ', doc.data())

            let post = doc.data();      

            post.id = doc.id//.slice(0, -7);    // ISSUE change this to post.id = , then change occurences of docId to post.Id
            
            let preformedURL1 = 'https://firebasestorage.googleapis.com/v0/b/crittool-prod.appspot.com/o/user-images%2F' + post.userId + '%2F' + post.id + '%2F' + post.userId + '-post-image-1?alt=media&token=';

            let preformedURL2 = 'https://firebasestorage.googleapis.com/v0/b/crittool-prod.appspot.com/o/user-images%2F' + post.userId + '%2F' + post.id + '%2F' + post.userId + '-post-image-2?alt=media&token=';

            const displayPost = () => {

              // create array of all unique userIds on page

              if(!this.postAuthorPool.some(author => author.uid === post.userId)){

                let authorObject = { uid: post.userId };

                this.postAuthorPool.push(authorObject);

              };

              // add post to begining of this.posts array

              this.posts.push(post); // unshift(post)

            };

            // console.log('This is the post id: ', docId);
            // console.log('This is the user id: ', post.userId);
            // console.log('This is the 1st preformed url: ', preformedURL1);
            // console.log('This is the 2nd preformed url: ', preformedURL2);
            // console.log('This is the 1st post.imageURLs: ', post.imageURLs[0]);
            // console.log('This is the 2nd post.imageURLs: ', post.imageURLs[1]);

            if(post.imageURLs.length === 1){

              if(post.imageURLs[0].startsWith(preformedURL1)){

                displayPost();

              };

            } else if(post.imageURLs.length ===2){

              if(post.imageURLs[0].startsWith(preformedURL1) && post.imageURLs[1].startsWith(preformedURL2)){

                displayPost();

              };

            };

          });

          // NOTE: store all initally displayed posts into vue data,
          // when user clicks 'load more' button posts are temp stored into this.storedPosts
          // because this.posts value must be reset to empty array 
          // in order to display newly loaded posts correctly with previously loaded posts 

          this.storedPosts = this.posts;

          // get all displayed users profile image and display name

          this.getAuthorInfo();

        }

      }).catch(error => {

        console.log('there was an error getting the posts from userPosts: ', error);

      });

    },
    loadMorePosts(e){

      console.log('Start loadPosts().')

      // only call this function from 'load more' button click

      if(this.allPostsLoaded){

        console.log('All posts are already loaded for this profile.')

      } else {

        console.log('All posts are NOT already loaded for this profile.')

        let postQuery = projectFirestore.collection('userPosts').where("processed", "==", true).where('userId', '==', this.profileInfoToDisplay.id).limit(this.postQueryLimit)

        if(this.lastQueryLocation !== null){

          // this is not initial load of this post catagory, so change post query

          console.log('This is not the initial load of comments from this profile, add startAfter to doc query.')

          console.log('This is the lastQueryLocation for profile: ', this.lastQueryLocation)

          postQuery =  projectFirestore.collection('userPosts').where("processed", "==", true).where('userId', '==', this.profileInfoToDisplay.id).startAfter(this.lastQueryLocation).limit(this.postQueryLimit)

        }
          
        postQuery.get().then(postDocs => {

          console.log('these are the posts from load more click at profile: ', postDocs.docs)

          if(postDocs.docs.length >= 1){

            if(postDocs.docs.length < this.postQueryLimit){
          
              this.allPostsLoaded = true

              console.log('We got less results than limit allows, so we have all docs now.')
            
            }

            this.lastQueryLocation = postDocs.docs[postDocs.docs.length - 1]

            // reset this.posts

            this.posts = []

            // add any already queried posts back into this.posts

            this.posts = this.storedPosts

            postDocs.docs.forEach(doc => {
            
              let post = doc.data();

              let isDuplicate = false

              let docId = doc.id//.slice(0, -7);
          
              let preformedURL1 = 'https://firebasestorage.googleapis.com/v0/b/crittool-prod.appspot.com/o/user-images%2F' + post.userId + '%2F' + docId + '%2F' + post.userId + '-post-image-1?alt=media&token=';

              let preformedURL2 = 'https://firebasestorage.googleapis.com/v0/b/crittool-prod.appspot.com/o/user-images%2F' + post.userId + '%2F' + docId + '%2F' + post.userId + '-post-image-2?alt=media&token=';

              const displayPost = () => {

                // remove 'public' from end of doc id

                post.id = doc.id//.slice(0, -7);

                // create array of all unique userIds on page

                if(!this.postAuthorPool.some(author => author.uid === post.userId)){

                  let authorObject = { uid: post.userId };

                  this.postAuthorPool.push(authorObject);

                };

                // add post to begining of this.posts array

                this.posts.push(post); // unshift(post)

              };

              console.log('This is the post id: ', docId);
              console.log('This is the user id: ', post.userId);
              console.log('This is the 1st preformed url: ', preformedURL1);
              console.log('This is the 2nd preformed url: ', preformedURL2);
              console.log('This is the 1st post.imageURLs: ', post.imageURLs[0]);
              console.log('This is the 2nd post.imageURLs: ', post.imageURLs[1]);

              if(post.imageURLs.length === 1){

                if(post.imageURLs[0].startsWith(preformedURL1)){

                  displayPost();

                };

              } else if(post.imageURLs.length ===2){

                if(post.imageURLs[0].startsWith(preformedURL1) && post.imageURLs[1].startsWith(preformedURL2)){

                  displayPost();

                };

              };

            });

            // store all displayed posts into vue data per catagory

            this.storedPosts = this.posts

            // get all displayed users profile image and display name

            this.getAuthorInfo()

          } else {

            console.log('There are no results, so all are displayed or there are none at all.')

            // loading posts returned no results, so we are displaying all results already or there are no results at all

            this.allPostsLoaded = true

            if(this.storedPosts.length === 0){

              // there are no results to be had for this catagory, set to empty array, 
              // so not undefined, which we check for when filtering posts

              this.storedPosts = []

            }

          }

        }).catch(error => {

          console.log('there was an error getting the posts from userPosts: ', error)

        })

      }

    },
    openProfilePostModal(postId){ 

      console.log('Start openProfilePostModal().')

      console.log('We are opening profile post modal with this post id: ', postId)

      // filter postsToDisplay to see if this postId matches any displayed posts
      // we will find match if user has opened post from MGallery
      // when we find a match, set postDoc equal to that post

      let fullPostObject = this.postsToDisplay.filter(post => {

        return post.id === postId

      })

      if(fullPostObject.length > 0){

        // user is visiting post from profile page

        console.log('We have a full post object that matches the post id.')

        console.log('This is the filtered full post object: ', fullPostObject[0])

        this.postDocToOpen = fullPostObject[0]

      } else {

        // user is visiting post from direct url

        console.log('We do NOT have a full post object that matches the post id.')

        this.postDocToOpen = {

          id: postId

        }

      }

      console.log('This is the postDocToOpen object to send to DisplayProfilePost as prop: ', this.postDocToOpen)

      const body = document.getElementsByTagName("BODY")[0]

      body.classList.add("noscroll")

      this.modalOn = true

      this.$emit('notice', '')

    },
    closeProfilePostModal(options){  

      console.log('Start closeProfilePostModal().');

      // ISSUE future feature: add options.updatePost
      // so that when a user edits their post, the edits show without refresh

      if(options){

        console.log('This is the closeModal options: ', options)

        if(options.deletePost){

          console.log('This is the closeModal options.deletePost: ', options.deletePost)

          // filter out deleted post from this.posts

          this.posts = this.posts.filter(post => {

            return post.id !== options.deletePost

          })

        }

      }
      

      const body = document.getElementsByTagName("BODY")[0];

      body.classList.remove("noscroll");

      // turn modal off
      this.modalOn = false;

      // reset post images
      // document.getElementById('postImageStage').innerHTML = [];

      // reset doc data
      this.openPost = [];

      // this.narrowMode = false;

      console.log('Route back to ProfilePage.')

      if(options === '404'){

        this.$router.push({ name: 'NotFound', params: { catchAll: '404' }});

      } else {

        // reset post images
        document.getElementById('postImageStage').innerHTML = [];

        console.log('Route back to Profile.');

        this.$router.push({ name: 'Profile', params: { displayName: this.$route.params.displayName } })

      };

    },
  },
  created(){

    console.log('Start created() at Profile.vue')

    console.log('This is the current user passed from root: ', this.user)

    console.log('This is the current user doc passed from root: ', this.usersDoc)

    console.log('This is the profileInfo object sent from root: ', this.profileInfo)

    console.log('This is the current route at Profile.vue created hook: ', this.$route)

    // set value of profileInfo prop to local variable so we can change it

    this.profileInfoToDisplay = this.profileInfo
    
    if(!this.profileInfoToDisplay){

      console.log('There is no profileInfoToDisplay: ', this.profileInfoToDisplay)

      // there is no profileInfo yet because this is a direct visit from URL

      let userAliasOnly = {
        
        userAlias: this.$route.params.displayName

      }

      this.$emit('loadProfile', userAliasOnly)

      // now check if this is direct profile POST visit via url (if so this.profilePostModalOn will be true)

      if(this.profilePostModalOn){

        this.openProfilePostModal(this.$route.params.displayProfilePostId)

      }

    } else {

      console.log('There is profileInfoToDisplay: ', this.profileInfoToDisplay)

      if(this.profilePostModalOn){

        this.openProfilePostModal(this.$route.params.displayProfilePostId)

      }

      // there is profileInfo because this is a visit from MGallery
      
      // set query ref for all docs by this user

      let profilePostsRef = projectFirestore.collection('userPosts').where("processed", "==", true).where('userId', '==', this.profileInfoToDisplay.id).limit(this.postQueryLimit)

      // call getPostsForProfile

      this.getPostsForProfile(profilePostsRef)

    }

  },
  unmounted(){

    console.log('Start unmounted() at Profile.vue')

  }
}

</script>


<style>
  
  /* ---------- START LOAD MORE BUTTON STYLING ---------- */
  #Profile #loadMoreButtonContainer {
    display: flex;
    justify-content: center;
  }
  /* ---------- END LOAD MORE BUTTON STYLING ---------- */

  /* ---------- START PROFILE HEADER STYLING ---------- */
  #Profile #profileHeader {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1em;
    padding: 0 1em;
  }
  #Profile #profileHeader #profilePicture {
    margin-right: 1em;
  }
  #Profile #profileHeader #profilePicture img {
    clip-path: circle(42%);
    height: 80px;
    position: absolute;
    width: auto;
  }
  #Profile #profileHeader #profilePicture i {
    color: #aaa;
    font-size: 80px;
  }
  #Profile #profileHeader #profileInfo {
    text-align: center;
  }
  #Profile #profileHeader #profileInfo #profileName {
    font-size: 1.5em;
    font-weight: 500;
  }
  #Profile #profileHeader #profileInfo #profileBio {
    margin: 0 0 .5em;
  }
  #Profile #profileHeader #profileInfo #profileLocation {
    align-items: center;
    color: #aaa;
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: 601px) {
    #Profile #profileHeader {
      margin-top: 2em;
    }
  }

  @media screen and (min-width: 901px) {
    #Profile #profileHeader {
      flex-direction: row;
    }
    #Profile #profileHeader #profilePicture img {
      height: 120px;
    }
    #Profile #profileHeader #profilePicture i {
    font-size: 120px;
  }
    #Profile #profileHeader #profileInfo {
      max-width: 33%;
      text-align: left;
    }
    #Profile #profileHeader #profileInfo #profileLocation {
      justify-content: flex-start;
    }
  }
  /* ---------- END PROFILE HEADER STYLING ---------- */

  /* ---------- START POST CARD STYLING ---------- */

  #Profile .postCard .postCardInfo .author {
    cursor:default;
  }

/* ---------- END POST CARD STYLING ---------- */

</style>