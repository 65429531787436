<template>

  <div id="about">

    <!-- <div id="filterBar" class="container">

      <ul>

        <li class="selected">About</li>
        <li>Help</li>

      </ul>

    </div> -->

    <div>

      <div id="headlineAndHero">

        <div class="container">

          <div id="headline">
            <h1>Created with&nbsp;good critiques in&nbsp;mind.</h1>
            <h2>Crittools mission is to make online feedback more helpful for artists and designers.</h2>
          </div>

          <div id="hero">
            <img src="../../assets/brain-feedback.png" alt="Not My Work, Placeholder.">
          </div>

        </div>

      </div>

      <div id="whatIsCrittool">

        <div class="container">

          <div id="itIs">
            <img src="../../assets/test1.png" alt="Not My Work, Placeholder.">
            <h3>What is Crittool?</h3>
            <p>It’s a critique tool for artists and designers.</p>
            <p>Critiques are structured around the elements 
              and principles of art and design. The feedback is tabulated.</p>
            <p> Get insight into what's working!</p>
          </div>

          <div id="itIsNot">
            <img src="../../assets/test7.png" alt="Not My Work, Placeholder.">
            <h3>Why it's Different?</h3>
            <p>It’s about skill development, not popularity.</p>
            <p>Posts move further down the page as they receive more feedback, while posts in need
              float to the top.</p><p> No more getting lost in the crowd!</p>
          </div>

          <div id="itIsNot">
            <img src="../../assets/test3.png" alt="Not My Work, Placeholder.">
            <h3>Get Started Today!</h3>
            <p><router-link :to="{ name: 'Signup' }">Sign&nbsp;up</router-link> and start getting good feedback now.</p>
            <p>Crittool is the fast and easy way to get online critiques with actually helpful feedback. Plus it's free, so why wait?</p><p> Your work can only get better!</p>
          </div>

        </div>

      </div>

      <div id="howDoesItWork">
        <div class="header container">
          <div>
            <img src="../../assets/test5.png" alt="Not My Work, Placeholder.">
          </div>
          <div>
            <h3>How Does Crittool&nbsp;Work?</h3>
            <p class="subheader">Like magic! But first you need to <router-link :to="{ name: 'Signup' }">sign&nbsp;up</router-link> for an account. After that it's just a few simple steps...</p>
          </div>
          
        </div>
        <div class="body container">
          <div id="critiqueSeekers" class="card">
            <h4>Critique Seekers</h4>
            <p>Looking for some good feedback?</p>
            <ol>
              <li><span>Images</span>: <br>Select 1 or 2 images of your work for critique and comparison.</li>
              <li><span>Title</span>: <br>Add a title that describes your work. </li>
              <li><span>Critique Brief</span>: <br>Describe your work, your concept, and what you’re looking for feedback on.</li>
              <li><span>Category</span>: <br>Select 1 of the following categories: art, graphic design, illustration, ui/ux, web design.</li>
              <li><span>Critique Mode</span>: <br>Choose <span class="modeBadge">Broad</span> mode to receive feedback on all elements and principles or choose <span class="modeBadge">Focused</span> mode to limit feedback to a customized selection of elements and principles.</li>
              <li><span>Upload</span>: <br>Submit your work to receive valuable feedback. During the upload you'll be asked to critique someone else’s work too.</li>
            </ol>
          </div>

          <div id="critiqueGivers" class="card">
            <h4>Critique Givers</h4>
            <p>How about helping someone out?</p>
            <ol>
              <li><span>Which do  you like best?</span> <br>Select which version of the work you like best.</li>
              <li><span>What’s working?</span> <br>Select up to 3 elements and principles that you think are working. You can leave a note with each selection to explain your choice.</li>
              <li><span>What’s not working?</span> <br>Select up to 3 elements and principles that you think are not working. You can leave a note with each selection to explain your choice.</li>
              <li><span>Add comments:</span> <br>Describe what you think about the work as it relates to the elements and principles you selected. What do you like? What do you not like? How might it be improved?</li>
            </ol>
          </div>
        </div>
          

      </div>

    </div>

  </div>

</template>

<script>

// import { projectAuth } from '@/firebase/config'
// import { projectFunctions } from '@/firebase/config'

// import Spinner from '@/components/layout/Spinner'

export default {
  name: 'About',
  // components: {
  //   Spinner
  // },
  props: {
    // notice: String
  },
  data(){
    return {
        
    }
  },
  methods: {
      
  }

}
</script>

<style>
#about {

  padding-top: 2em;

}

#about a {

  color: #D97AEB;

}

#about #headlineAndHero /*#about #whatIsCrittool*/ {

  /* align-items: center; */
  /* background: linear-gradient(0deg, rgba(232,236,255,1) 0%, rgba(255,255,255,1) 66%); */
  /* display: flex;
  justify-content: space-around;
  margin-bottom: 2em; */

}

#about #headlineAndHero .container {

  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2em;

}

#about #headline {

  width: 44%;

}

#about #hero {

  width: 40%;

}

#about #headline h1 {

  color: rgba(66, 54, 94, 1);
  font-size: 3.5em;
  line-height: 1.2em;

}

#about #headline h2 {

  color: #D97AEB;
  line-height: 1.5em;

}

#about #whatIsCrittool {
  /* background-color: rgba(159,208,243,.3); */
  /* margin-bottom: 2em; */
  /* background: rgb(159,208,243); */
  /* background: linear-gradient(180deg, rgba(159,208,243,0.4) 0%, rgba(217,122,235,.4) 66%); */
  /* background: rgba(159,208,243,.3); */
  background: rgba(232,236,255);
  padding: 75px 0;
}

#about #whatIsCrittool p:first-of-type {

  /* color: #333; */
  color: rgba(66, 54, 94, 1);
  font-size: 1.4em;
  font-weight: 500;

}

#about #whatIsCrittool p:nth-of-type(2), #about #whatIsCrittool p:nth-of-type(3) {

  color: rgba(66, 54, 94, .7);
  /* color: #777; */
  font-size: 1.1em;
  font-weight: 500;
  line-height: 1.7em;

}

#about #whatIsCrittool p:nth-of-type(3) {

  color: rgba(66, 54, 94, .95);

}

#about #whatIsCrittool div.container {

  display: flex;
  justify-content: space-between;

}

#about #whatIsCrittool div.container img {

  display: block;
  margin: 0 auto;
  margin-bottom: 2em;
  /* width:80%; */
}

#about #whatIsCrittool div.container h3 {

  text-decoration: underline;
  text-decoration-color: #D97AEB;
  -webkit-text-decoration-style: dashed;
  text-decoration-style: dashed;
  -webkit-text-underline-position: under;
  text-underline-position: under;
  color: rgba(66, 54, 94, 1);
  /* color: #9B7EDE; */
  font-size: 1.8em;
  font-weight: 700;
  line-height: 1.6em;

}

#about #whatIsCrittool div.container :nth-of-type(2) h3{

  -webkit-text-decoration-style: wavy;
  text-decoration-style: wavy;

}

#about #whatIsCrittool #itIs, #about #whatIsCrittool #itIsNot {

  width: 28%;

}
#about #howDoesItWork {

  /* background-color: rgba(13,12,34,0.05); */
  /* background: linear-gradient(180deg, rgba(217,122,235,0.4) 0%, rgba(255,255,255,1) 66%); */
  background: rgba(155,126,222,.3);
  padding: 75px 0;

}

#about #howDoesItWork ol {

  padding-inline-start: 1.5em;
  padding-inline-end: 1.5em;

}

 #about #howDoesItWork div.container {

   display: flex;
   justify-content: space-around;
   /* padding: 75px 0; */

 }

#about #howDoesItWork .header{  

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

}

#about #howDoesItWork .header div{  

  /* width: 50% */

}

#about #howDoesItWork .card {
  /* background-color: rgba(66, 54, 94, .1); */
  background-color: rgba(255, 255, 255, .66);
  padding: 0 1em 1em;
  border-radius: 8px;
  width: 45%;
  max-width: 400px;
}

#about #howDoesItWork .card p {

  font-style: italic;
  text-align: center;
  color: rgba(66, 54, 94, .7);
  margin-top: .3em;

}

#about #howDoesItWork h3 {

  text-align: center;

  color: rgba(66, 54, 94, 1);
  font-size: 2.25em;

  /* padding-top: 3em; */
  margin-bottom: 1em;

  text-decoration: underline;
  -webkit-text-decoration-color: #D97AEB;
  text-decoration-color: #D97AEB;
  -webkit-text-decoration-style: dashed;
  text-decoration-style: dashed;
  -webkit-text-underline-position: under;
  text-underline-position: under;
  color: rgba(66, 54, 94, 1);
  /* font-size: 1.8em; */
  font-weight: 700;
  line-height: 1.6em;

}

#about #howDoesItWork h4 {
  /* color: rgba(66, 54, 94, 1); */
  color: #D97AEB;
  text-align: center;
  font-size: 1.5em;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 2em;
}

#about #howDoesItWork p.subheader {

  text-align: center;
  color: rgba(66, 54, 94, .7);
  font-size: 1.4em;
  font-weight: 500;
  font-style: normal;
  max-width: 540px;
  margin: 0 auto;
  margin-bottom: 2em;

}

/* #about #howDoesItWork p {

  font-style: italic;
  text-align: center;
  color: rgba(66, 54, 94, .7);

} */

#about #howDoesItWork ol span {

  color: rgba(66, 54, 94, 1);
  font-weight: 700;

}

#about #howDoesItWork ol li {

  margin-bottom: 1em;
  color: rgba(66, 54, 94, .7);
  /* color: #777; */
  font-size: 1.1em;
  font-weight: 500;
  line-height: 1.7em;

}

#about #howDoesItWork span.modeBadge {

  background-color: #939598;
    color: #fff;
    padding: 1px 3px;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: 400;

}

@media screen and (max-width: 800px) {
  
  #about #howDoesItWork .header{  

    flex-direction: column;

  }



}

@media screen and (max-width: 700px) {

  #about #headline h1 {

    font-size: 3.25em;

  }

  #about #whatIsCrittool, #about #howDoesItWork {

    padding: 25px 0;

  }

  #about #whatIsCrittool div.container {

    flex-direction: column;

  }

  #about #whatIsCrittool div.container h3{  

    text-align: center;
    font-size: 2.25em;

  }

  #about #whatIsCrittool #itIs, #about #whatIsCrittool #itIsNot {

    width: 100%;

  }

  #about #whatIsCrittool div.container img {

    max-width: 297px;
    width: 66%;

  }

  #about #howDoesItWork div.body {

    flex-direction: column;

  }

  #about #howDoesItWork .card {

    width: auto;
    margin: 0 auto;

  }

  #about #howDoesItWork .card:nth-of-type(1) {

    margin-bottom: 2em;

  }

}

@media screen and (max-width: 600px) {

  #about #headline h1 {

    font-size: 2.9em;

  }

}

@media screen and (max-width: 500px) {

  #about #headlineAndHero .container {
    flex-direction: column-reverse;
  }

  #about #headline {

    text-align: center;
    width: 100%;

  }

}

</style>  