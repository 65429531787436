<template>

  <div id="signup" class="container">

    <div id="signupLogo">
        Crittool
    </div>


    <h2>Verify Your Email</h2>
    <p>A verifcation email was just sent to you. Please click the link inside it to finish signing up.</p>
    <p> The link will expire in 24 hours. </p>
    <button @click="resendVerificationEmail" v-bind:class="{ disabled: this.unvalidated }" class="btn">Resend Verification Email</button>

    <div v-if="showSpinner" id="spinner-wrapper">
      <Spinner />  
    </div>
    
  </div>

</template>

<script>

// import { projectAuth } from '@/firebase/config'
// import { projectFunctions } from '@/firebase/config'

import Spinner from '@/components/layout/Spinner'

export default {
  name: 'VerifyEmail',
  components: {
    Spinner
  },
  props: {
    user: Object,
    notice: String
  },
  data(){
      return {
          showSpinner: false,
          signupInfo: {
              name: '',
              alias: '',
              email: '',
              password: ''
          },
          unvalidated: false
      }
  },

  // ISSUE: can we setup watcher or somehow otherwise route user to MGallery if their email verification status changes...

  methods: {
      
    resendVerificationEmail() {

      var actionCodeSettings = {
        url: 'https://crittool.com', // change this to correct url for prod
        handleCodeInApp: false
      };

      this.user.sendEmailVerification(actionCodeSettings).then(() => {

        this.unvalidated = true

        console.log('The verification email has been sent.')

        this.$emit('notice', 'The verification email has been resent. Please check your email.');

      }).catch(error => {

        console.log('There was an error resending the verification email: ', error)

      })
    }

  },
  created() {

    console.log('Start created hook inside VerifyEmail.vue')

    console.log('This is the user prop sent from root: ', this.user)

    // this.user.emailVerified = true

    if(this.user === null){

      this.$router.push({ name: 'Login' })

    } else if(this.user.emailVerified){

      this.$router.push({ name: 'MGallery' })

    }

  }

}
</script>

<style>

</style>