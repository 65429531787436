<template>

  <div id="signup" class="container">

    <router-link :to="{ name: 'About' }" id="signupLogo">
      Crittool
    </router-link>

    <form @submit.prevent="signup" class="card-panel">

      <h2>Sign up to Crittool</h2>

      <div class="field">

        <label for="name">Name</label>
        <input type="text" name="name" maxlength="50" v-model="signupInfo.name" v-on:keyup="validateDuringInput" v-on:blur="validateAfterInput">

      </div>

      <div class="field">

        <label for="alias">Username</label>
        <input type="text" name="alias" v-model="signupInfo.alias" v-on:keyup="validateDuringInput" v-on:blur="validateAfterInput">

      </div>

      <div class="field">

        <label for="email">Email</label>
        <input type="email" name="email" v-model="signupInfo.email" v-on:keyup="validateDuringInput" v-on:blur="validateAfterInput">

      </div>

      <div class="field">

        <label for="password">Password</label>
        <input type="password" name="password" v-model="signupInfo.password" v-on:keyup="validateDuringInput" v-on:blur="validateAfterInput">

      </div>

      <div id="termsAgreement" class="field">
      
        <label for="agreeToTerms">

          <input type="checkbox" id="agreeToTerms" @click="validateDuringInput">
          <span class="checkmark"></span>

        </label>

        <div>You're okay with our Terms of Service and Privacy Policy.</div>
        
      </div>

      <div class="field center">

        <button v-bind:class="{ disabled: this.unvalidated }" class="btn">Sign Up</button>

      </div>

      <div class="field">

        <p>Already a member?
          <router-link :to="{ name: 'Login' }">
            Sign In
          </router-link>
        </p>
        
      </div>

    </form>

    <div v-if="showSpinner" id="spinner-wrapper">
      <Spinner />  
    </div>
    
  </div>

</template>

<script>

import { projectAuth } from '@/firebase/config'
import { projectFunctions } from '@/firebase/config'

import Spinner from '@/components/layout/Spinner'

export default {
    name: 'Signup',
    components: {
        Spinner
    },
    props: {
    notice: String
  },
    data(){
        return {
            showSpinner: false,
            signupInfo: {
                name: '',
                alias: '',
                email: '',
                password: ''
            },
            unvalidated: true
            // feedback: null,
        }
    },
    methods: {
        signup(){
            
            this.showSpinner = true

            if(this.signupInfo.name && this.signupInfo.alias && this.signupInfo.email && this.signupInfo.password 
              && document.getElementById('agreeToTerms').checked){

                // call cloud function to sign up user and create user record
                
                let signupUser = projectFunctions.httpsCallable('signupUser')

                console.log('Call cloud function signupUser.')

                    signupUser({ 
                      
                      signupInfo: this.signupInfo 

                    }).then(result => { 
                      
                    console.log('Result from cloud function signupUser, result: ', result)

                    console.log('Result from cloud function signupUser, result.data: ', result.data)

                    if(result.data.error){

                      console.log('There was an error with the user sign up: ', result.data.error);

                      this.$emit('notice', result.data.error);

                      this.showSpinner = false;

                    } else {

                      // log new user in
                      if(result.data.newUserSetupComplete === true){

                        var actionCodeSettings = {
                          url: 'https://crittool.com/',
                          handleCodeInApp: false
                        };

                        projectAuth.signInWithEmailAndPassword(this.signupInfo.email, this.signupInfo.password)
                        .then(() => {

                          let user = projectAuth.currentUser;

                          user.sendEmailVerification(actionCodeSettings).then(() => {

                            console.log('The verification email has been sent.')

                            this.showSpinner = false;

                            // ISSUE should we route to VerifyEmail instead?

                            this.$router.push({ name: 'MGallery' });

                          }).catch(error => {

                            console.log('There was an error sending the verification email: ', error)

                          })

                        })
                        .catch(error => {

                          console.log('There was an error during sign in: ', error);

                          this.$emit('notice', error);

                          this.showSpinner = false;

                        });

                      } else if(result.data.uniqueAlias === false){

                        this.$emit('notice', 'This user alias is already taken by another account.');

                        this.showSpinner = false;

                      };

                    };
                    
                })
                .catch(error => {

                    console.log('There was an error with signupUser cloud function: ', error);

                    this.$emit('notice', error);

                    this.showSpinner = false;

                });

            } else {

                this.$emit('notice', 'You must fill out all fields.');

                this.showSpinner = false;
                
            };
        },
        validateDuringInput() {

          console.log('Start validateDuringInput() in Signup.')

          const validateAliasAndEmailChars = () => {

            if(this.signupInfo.alias.match(/^[a-zA-Z0-9]+$/g)){

              console.log('The alias chars are valid.')

              if(this.notice === 'Username may not contain special characters.'){

                this.$emit('notice', '')

              }

              if(this.signupInfo.email.match(/^[A-Z0-9@._%+-]+$/gi)){

                console.log('The email chars are valid.')

                if(this.notice === 'Email may not contain special characters.'){

                  this.$emit('notice', '')

                }

              } else {

                this.unvalidated = true // this.proUnvalidated = true // ISSUE what is proUnvalidated for? is the parent component using this?

                if(this.signupInfo.email !== null && this.signupInfo.email !== ''){

                  console.log('The email chars are invalid.')

                  this.$emit('notice', 'Email may not contain special characters.')

                } 

              }

            } else {

              this.unvalidated = true

              if(this.signupInfo.alias !== null && this.signupInfo.alias !== ''){

                console.log('The alias chars are invalid.')

                this.$emit('notice', 'Username may not contain special characters.')

              }

            }

          }

          // validate input characters

          if(this.signupInfo.name.match(/^[a-zA-Z0-9 ]+$/g)){

            console.log('The name chars are valid.')

            if(this.notice === 'Name may not contain special characters.'){

              this.$emit('notice', '')

            }

            validateAliasAndEmailChars();

            // validate input lengths

            if(this.signupInfo.name.length >= 3 && this.signupInfo.name.length <= 24){

              console.log('The name length is valid.')

              if(this.notice === 'Name must be longer.' || this.notice === 'Name must be shorter.'){

                this.$emit('notice', '')

              }

              if(this.signupInfo.alias.length >= 3 && this.signupInfo.alias.length <= 24){

                console.log('The alias length is valid.')

                if(this.notice === 'Username must be longer.' || this.notice === 'Username must be shorter.'){

                  this.$emit('notice', '')

                }

                if(
                  this.signupInfo.email.length >= 6 && 
                  this.signupInfo.email.length <= 254 && 
                  this.signupInfo.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gi)
                ){

                  console.log('The email length/format is valid.')

                  if(
                    this.notice === 'Email must be longer.' || 
                    this.notice === 'Email must be shorter.' ||
                    this.notice === 'Email is not formatted correctly.'
                  ){

                    this.$emit('notice', '')

                  }

                  if(this.signupInfo.password.length >= 6){

                    console.log('The password is valid.')

                    if(
                      this.notice === 'Password must be longer.' ||
                      this.notice === '' ||
                      this.notice === null
                    ){

                      this.$emit('notice', '')

                    }

                    if(this.signupInfo.name && this.signupInfo.alias && this.signupInfo.email && this.signupInfo.password 
                      && document.getElementById('agreeToTerms').checked){

                        console.log('All fields have been filled out.')

                        if(
                          this.notice === 'You must fill out all fields.' ||
                          this.notice === 'The email address is already in use by another account.' ||
                          this.notice === 'This user alias is already taken by another account.' ||
                          this.notice === 'Email may not contain special characters.' ||
                          this.notice === 'Email is not formatted correctly.' ||
                          this.notice === '' ||
                          this.notice === null
                        ){

                          this.$emit('notice', '')

                          if(document.getElementById('agreeToTerms').checked){
                            
                            this.unvalidated = false

                          } else {

                            this.unvalidated = true

                          }

                        }

                    } else {

                      console.log('The field are not all filled out.')

                      this.unvalidated = true  

                    }

                  } else {

                    console.log('The password length is invalid.')

                    this.unvalidated = true

                  }

                } else {

                  console.log('The email length or format is invalid.')

                  this.unvalidated = true

                }

              } else {

                console.log('The alias length is invalid.')

                this.unvalidated = true

              }

            } else {

              console.log('The name length is invalid.')

              this.unvalidated = true

            }

          } else {

            this.unvalidated = true

            if(this.signupInfo.name !== null && this.signupInfo.name !== ''){

              console.log('The name chars are invalid.')

              this.$emit('notice', 'Name may not contain special characters.')

            }

            validateAliasAndEmailChars();

          }

        },
        validateAfterInput(){

          console.log('Start validationAfterInput() in Signup.')

          console.log('This is the notice: ', this.notice)

          if(this.signupInfo.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gi)){

            if(this.signupInfo.name.length < 3){

              console.log('The name is too short.')

              this.$emit('notice', 'Name must be longer.')

            } else if(this.signupInfo.name.length > 24){

              console.log('The name is too long.')

              this.$emit('notice', 'Name must be shorter.')

            } else if(this.signupInfo.alias.length < 3){

              console.log('The alias is too short.')

              this.$emit('notice', 'Username must be longer.')

            } else if(this.signupInfo.alias.length > 24){

              console.log('The alias is too long.')

              this.$emit('notice', 'Username must be shorter.')

            } else if(this.signupInfo.email.length < 6){

              console.log('The email is too short.')

              this.$emit('notice', 'Email must be longer.')

            } else if(this.signupInfo.email.length > 254){

              console.log('The email is too long.')

              this.$emit('notice', 'Email must be shorter.')

            } else if(this.signupInfo.password !== '' && this.signupInfo.password.length < 6){

              console.log('The password is too short.')

              this.$emit('notice', 'Password must be longer.')

            }

          } else {

            this.unvalidated = true

            if(this.signupInfo.email !== null && this.signupInfo.email !== ''){

              console.log('The email chars are invalid.')

              this.$emit('notice', 'Email is not formatted correctly.')

            } 

          }

        }
    }

}
</script>

<style>


div#signup #spinner-wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
#signup {
    max-width: 400px;
    margin-top: 60px;
}
#signup a {
  color: #D97AEB;
}
#signup .btn {
  width: 100%;
}
#signup h2 {
    font-size: 1.5rem;
}
#signup .field {
    margin-bottom: 16px;
}
#signup p {
  text-align: center;
}
#termsAgreement {
  color: #aaa;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  /* cursor: pointer; */
  /* font-size: 22px; */
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#termsAgreement input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0%;
  width: 0%;
}
.checkmark {
  cursor: pointer;
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 2px;
}
/* On mouse-over, add a grey background color */
#termsAgreement:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
#termsAgreement input:checked ~ .checkmark {
  background-color: #9B7EDE;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
#termsAgreement input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
#termsAgreement .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


/* ---------- START LOGO STYLING ---------- */
#signupLogo {
  color: rgba(66, 54, 94, 1);
  display: inline-block;
  font-size: 2.1rem;
  font-weight: 700;
  font-style: normal;
  padding: 0;
}
#signupLogo:hover {
  color: rgba(66, 54, 94, .8);
}
/* ---------- END LOGO STYLING ---------- */
</style>