import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { projectAuth } from '@/firebase/config'
// import firebase from 'firebase'

let app = null;

// wait for firebase auth to init before creating the app
projectAuth.onAuthStateChanged(() => {

  // init app if not already initiated
  if(!app){

    app = createApp(App).use(router).mount('#app')

  }

})
