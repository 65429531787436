<template>
  <div id="notFound" class="container">
    <h2>404</h2>
    <h3>Okay, see what happend is... we're lost!</h3>
    <router-link :to="{ name: 'MGallery' }">
      <button class="btn">Go Home</button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style>

  #notFound {
    text-align: center;
  }

  #notFound h2 {
    color: #d97aeb;
    font-size: 6em;
  }

  #notFound button {
    margin-top: 4em;
  }

</style>