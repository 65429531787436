<template>
  <div id="footer" class="container">
    <div id="footerContent">
      <div id="footerAbout">
        <div id="footerLogo">
          <router-link :to="{ name: 'MGallery' }">
            Crittool
          </router-link>
        </div>
        <div id="tagline">
          <!-- Crittool is the best place in the Milky Way Galaxy for creatives get online critiques. -->
          Crittool is the galaxy's premiere place for creatives to get online critiques with great feedback. 
        </div>
        <div id="socialLinks">
          <a href="https://www.instagram.com/crittool">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
            <!-- <style type="text/css">
              .st0{fill:#58595B;}
            </style> -->
            <g>
              <path class="socialLogo" d="M16,2.9c4.3,0,4.8,0,6.5,0.1c1.6,0.1,2.4,0.3,3,0.6c0.7,0.3,1.3,0.6,1.8,1.2c0.6,0.6,0.9,1.1,1.2,1.8
                c0.2,0.6,0.5,1.4,0.6,3c0.1,1.7,0.1,2.2,0.1,6.5s0,4.8-0.1,6.5c-0.1,1.6-0.3,2.4-0.6,3c-0.3,0.7-0.6,1.3-1.2,1.8
                c-0.6,0.6-1.1,0.9-1.8,1.2c-0.6,0.2-1.4,0.5-3,0.6c-1.7,0.1-2.2,0.1-6.5,0.1s-4.8,0-6.5-0.1c-1.6-0.1-2.4-0.3-3-0.6
                c-0.7-0.3-1.3-0.6-1.8-1.2c-0.6-0.6-0.9-1.1-1.2-1.8C3.3,24.8,3,24,3,22.4c-0.1-1.7-0.1-2.2-0.1-6.5s0-4.8,0.1-6.5
                C3,8,3.3,7.1,3.5,6.6c0.3-0.7,0.6-1.3,1.2-1.8s1.1-0.9,1.8-1.2C7.1,3.3,8,3,9.5,3C11.2,2.9,11.7,2.9,16,2.9 M16,0
                c-4.3,0-4.9,0-6.6,0.1C7.7,0.2,6.5,0.4,5.5,0.8c-1.1,0.4-1.9,1-2.8,1.8S1.2,4.5,0.8,5.5c-0.4,1-0.7,2.2-0.7,3.9C0,11.1,0,11.6,0,16
                s0,4.9,0.1,6.6c0.1,1.7,0.3,2.9,0.7,3.9c0.4,1.1,1,1.9,1.8,2.8c0.9,0.9,1.8,1.4,2.8,1.8c1,0.4,2.2,0.7,3.9,0.7
                c1.7,0.1,2.2,0.1,6.6,0.1s4.9,0,6.6-0.1c1.7-0.1,2.9-0.3,3.9-0.7c1.1-0.4,1.9-1,2.8-1.8c0.9-0.9,1.4-1.8,1.8-2.8
                c0.4-1,0.7-2.2,0.7-3.9c0.1-1.7,0.1-2.2,0.1-6.6s0-4.9-0.1-6.6c-0.1-1.7-0.3-2.9-0.7-3.9c-0.4-1.1-1-1.9-1.8-2.8
                c-0.9-0.9-1.8-1.4-2.8-1.8c-1-0.4-2.2-0.7-3.9-0.7C20.9,0,20.3,0,16,0z M16,7.8c-4.5,0-8.2,3.7-8.2,8.2s3.7,8.2,8.2,8.2
                s8.2-3.7,8.2-8.2S20.5,7.8,16,7.8z M16,21.3c-2.9,0-5.3-2.4-5.3-5.3s2.4-5.3,5.3-5.3s5.3,2.4,5.3,5.3S18.9,21.3,16,21.3z M26.4,7.4
                c0,1.1-0.9,1.9-1.9,1.9s-1.9-0.9-1.9-1.9s0.9-1.9,1.9-1.9S26.4,6.4,26.4,7.4z"/>
            </g>
            </svg>
          </a>
          <a href="https://www.twitter.com/crittool">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
            <!-- <style type="text/css">
              .st0{fill:#58595B;}
            </style> -->
            <g>
              <path class="socialLogo" d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.8,3.8,24,3,22.2,3
                c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5
                c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.2-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1
                c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29c12.1,0,18.7-10,18.7-18.7
                c0-0.3,0-0.6,0-0.8C30,8.6,31.1,7.4,32,6.1"/>
            </g>
            </svg>
          </a>
        </div>
      </div>

      <div id="footerMenu">
        <div id="footerMenuHeader">Company</div>
        <ul>
          <router-link :to="{ name: 'About' }"><li class="footerMenuItem">About</li></router-link>
          <a href="mailto:help@crittool.com"><li class="footerMenuItem">Support</li></a>
          <router-link :to="{ name: 'Terms' }"><li class="footerMenuItem">Terms of Service</li></router-link>
          <router-link :to="{ name: 'Privacy' }"><li class="footerMenuItem">Privacy Policy</li></router-link>
        </ul>
      </div>
    </div>
    

    <div id="copyright">
      © 2021 Crittool. All rights reserved.
    </div>

  </div>
</template>

<script>
export default {
  name: 'Footer',
  data(){
    return {

    }
  },
  methods: {

  },
  created(){

  }
}
</script>

<style>

#footer {
  /* align-items: flex-start; */
  /* display: flex; */
  /* flex-direction: column; */
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  padding: 32px 0;
}
#footerContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
/* #footerAbout {
  max-width: 250px;
} */
#footerLogo a {
  color: rgba(66, 54, 94, 1);
  display: inline-block;
  font-size: 1.75rem;
  font-weight: 700;
  font-style: normal;
  padding: 0;
}
#tagline {
  margin: 20px 0;
  max-width: 250px;
}
#socialLinks {
  margin-bottom: 20px;
}
#socialLinks svg {
  height: 24px;
  margin-right: 12px;
}
#socialLinks svg path{
  fill: #58595b;
}
#footerMenu {
  /* margin: 20px 0; */
  padding-top: 4px;
}
#footerMenu a {
  color: #42365e;
}
#footerMenu a:hover {
  color: #d97aeb;
}
#footerMenuHeader {
  font-weight: 600;
}
#copyright {
  color: #6e6d7a;
}
/* #socialLinks svg {
  height: 24px;
} */


</style>