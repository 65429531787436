<template>
  <div id="settings" class="container">

    <!-- settings header -->
    <div id="settingsHeaderContainer">
      <div id="settingsHeader">

        <!-- profile photo -->
        <img v-if="photoURL" :src="photoURL"/>            
        <i v-if="!photoURL" class="material-icons">account_circle</i>

        <!-- author and current settings -->
        <span>
          <h1>{{ currentUserAlias }}</h1>:
          <h2>{{ currentContent }}</h2>
        </span>

      </div>
    </div>

    <!-- settings body -->
    <div id="settingsBody">

      <!-- settings menu -->
      <div id="settingsMenu">
        <ul @click="openSettingsMenu">
          <li  id="editProfile" class="settingsMenuItem active"><span @click="toggleSettings">Edit Profile</span></li>
          <li  id="editAccount" class="settingsMenuItem"><span @click="toggleSettings">Account Settings</span></li>
          <li  id="changePassword" class="settingsMenuItem"><span @click="toggleSettings">Change Password</span></li>
        </ul>
      </div>

      <!-- settings stage -->
      <div id="settingsStage">

        <!-- edit profile -->
        <div v-if="editProfile" id="editProfileContent" class="settingsContent active">

          <!-- edit profile image -->
          <div id="editProfileImage">

            <!-- profile image stage -->
            <div id="profileImageStage">
              <div id="profileImage">
                <!-- where the saved picture will display -->
                <img v-if="photoURL" :src="photoURL"/> 
              </div>
              <!-- preview for new profile picture -->
              <output id="profilePreview"></output>
              <!-- placeholder for the profile picture -->
              <i class="material-icons">account_circle</i>
            </div>

            <div id="editProfileImageButtons">
              <!-- this is the upload button, will open file finder -->
              <label v-if="!profilePhotoFile" for="fileUpload" id="fileBrowseLink" class="btn">Update Picture</label>
              <!-- this is the save button, will display after file has been selected -->
              <button v-if="profilePhotoFile" id="saveProfileImage" @click="uploadProfilePhoto" class="btn">Save Picture</button>
              <!-- this is the cancel button, will display when picture is previewed -->
              <button v-if="profilePhotoFile" id="cancelProfileImage" @click="clearProfilePhotoPreview" class="gray btn">Cancel</button>
              <!-- this is the delete button, will display when picture is saved -->
              <button v-if="photoURL && !profilePhotoFile" id="deleteProfileImage" @click="callDeleteProfileImage" class="gray btn">Delete</button>
            </div>

            <!-- file input form, it is hidden, label is used for button to open -->
            <form>
              <div class="field ">
                <input id="fileUpload" type="file" @change="getFilesToPost" accept="image/*">
              </div>
            </form>

          </div>

          <!-- this is the edit profile info section -->
          <div id="editProfileInformation">
            <form id="editProfileForm">
              <div class="inputField">
                <fieldset>
                  <label for="editProfileName" class="active">Name:</label>
                  <input v-model="newProfileName" type="text" id="editProfileName" v-on:keyup="validateDuringInput('profile')" v-on:blur="validateAfterInput('profile')">
                </fieldset>
              </div>
              <div class="inputField">
                <fieldset>
                  <label for="editProfileLocation" class="active">Location:</label>
                  <input v-model="newProfileLocation" type="text" id="editProfileLocation" v-on:keyup="validateDuringInput('profile')" v-on:blur="validateAfterInput('profile')">
                </fieldset>
              </div>
              <div class="inputField">
                <fieldset>
                  <label for="editProfileBio" class="active">Bio:</label>
                  <textarea v-model="newProfileBio" id="editProfileBio" v-on:keyup="validateDuringInput('profile')" v-on:blur="validateAfterInput('profile')"></textarea>
                </fieldset>
              </div>
              <button @click.prevent="saveProfileEdits" id="saveProfileEdits" v-bind:class="{ disabled: this.proUnvalidated }" class="btn saveButton">Save</button>
            </form>
          </div>

        </div>

        <!-- edit account  -->
        <div v-if="accountSettings" id="editAccountContent" class="settingsContent">

          <div id="editAccountSettings">

            <form id="editAccountForm">

              <div class="inputField">
                <fieldset>
                  <label for="editAccountUsername" class="active">Username:</label>
                  <input v-model="newAlias" type="text" id="editAccountUsername" v-on:keyup="validateDuringInput('settings')" v-on:blur="validateAfterInput('settings')">
                </fieldset>
              </div>

              <div class="inputField">
                <fieldset>
                  <label for="editAccountEmail" class="active">Email:</label>
                  <input v-model="newEmail" type="text" id="editAccountEmail" v-on:keyup="validateDuringInput('settings')" v-on:blur="validateAfterInput('settings')">
              </fieldset>
              </div>

              <button @click.prevent="saveAccountSettings" id="saveAccountEdits" v-bind:class="{ disabled: this.asUnvalidated }" class="btn saveButton">Save</button>
              <button @click.prevent="closeAccountPartOne(false)" id="closeAccount" class="gray btn">Close Account</button>

            </form>

          </div>

        </div>

        <!-- edit password -->
        <div v-if="changePassword" id="editPasswordContent" class="settingsContent">

          <div id="editChangePassword">

            <form id="editPasswordForm">

              <div class="inputField">

                <fieldset>
                  <label for="editNewPassword" class="active">New Password:</label>
                  <input v-model="newPassword" type="password" id="editNewPassword" v-on:keyup="validateDuringInput('password')" v-on:blur="validateAfterInput('password')">
                </fieldset>

              </div>

              <button @click.prevent="changeUserPassword" id="changePassword" v-bind:class="{ disabled: this.pwUnvalidated }" class="btn saveButton">Save</button>

            </form>

          </div>

        </div>

      </div>

    </div>

    <!-- <Footer /> -->

    <!-- login modal for user authentication -->
    <div v-if="loginModal" id="loginModal" v-bind:class="{ loginModalOn: loginModal }" @click.self="closeLoginModal">
      <i @click.self="closeLoginModal" class="material-icons close-modal">close</i>

      <div id="loginModalContent">
        <form @submit.prevent="login">
          <p class="">Please enter your existing password to continue making changes.</p>
          <div class="inputField">
            <label for="password">Password</label>
            <input type="password" name="password" v-model="password">
          </div>
          <p v-if="feedback">{{ feedback }}</p>
          <button class="btn">submit</button>
        </form>
      </div>

    </div>

    <div v-if="showSpinner" id="spinner-wrapper">
      <Spinner />  
    </div> 

  </div>

</template>

<script>
// import db from '@/firebase/config'
// import firebase from 'firebase'
import { projectAuth } from '@/firebase/config'
import { projectFunctions } from '@/firebase/config'
import { projectStorage } from '@/firebase/config'

import Spinner from '@/components/layout/Spinner'

export default {
  name: 'Settings',
  components: {
    // Footer,
    Spinner
  },
  props: {
    user: Object,
    usersDoc: Object,
    notice: String
  },
  data(){
      return {
        showSpinner: false,
        // user: null,
        currentUserAlias: null,

        newAlias: null,
        newEmail: null,
        
        editProfile: true,
        accountSettings: true,
        asUnvalidated: false,
        pwUnvalidated: true,
        proUnvalidated: false,
        changePassword: true,
        currentContent: 'Edit Profile',
        
        currentProfileName: null,
        newProfileName: null,

        currentProfileLocation: null,
        newProfileLocation: null,

        currentProfileBio: null,
        newProfileBio: null,

        oldPassword: null,
        newPassword: '',

        loginModal: false,
        email: null,        // what is this used for?
        password: null,
        feedback: null,
        currentAction: null,
        profilePhotoFile: null,
        // hasProfilePicture: false,
        hasProfilePreview: false,
        files: [],
        fileNames: [],
        photoURL: null
      }
  },
  methods: {
    openSettingsMenu(){
      if(window.innerWidth < 901){
        console.log('the width is less than 901')
        let menu = document.getElementById('settingsMenu')
        let menuItems = document.getElementsByClassName('settingsMenuItem')

        if(!menu.classList.contains('open')){
          menu.classList.add('open')
        } else {
          menu.classList.remove('open')
        }
      } else {
        console.log('the width is more than 901')
      }
      
    },
    toggleSettings(e){
      let menuItems = document.getElementsByClassName('settingsMenuItem')
      let settingSelectionMenu = null
      let settingSelectionContent = null

      this.newProfileName = this.currentProfileName
      this.newProfileLocation = this.currentProfileLocation
      this.newProfileBio = this.currentProfileBio
      this.proUnvalidated = false

      this.newAlias = this.currentUserAlias
      this.newEmail = this.currentUserEmail
      this.asUnvalidated = false

      this.newPassword = ''
      this.pwUnvalidated = true

      if(e.currentTarget.parentNode.id === 'editProfile'){
        this.currentContent = 'Edit Profile'
        settingSelectionMenu = 'editProfile'
        settingSelectionContent = 'editProfileContent'
      } else if(e.currentTarget.parentNode.id === 'editAccount'){
        this.currentContent = 'Account Settings'
        settingSelectionMenu = 'editAccount'
        settingSelectionContent = 'editAccountContent'
      } else if(e.currentTarget.parentNode.id === 'changePassword'){
        this.currentContent = 'Change Password'
        settingSelectionMenu = 'changePassword'
        settingSelectionContent = 'editPasswordContent'
      }

      // remove active class from unclicked items
      for (var i = 0; i < menuItems.length; i ++) {
        if(menuItems[i].id != settingSelectionMenu && menuItems[i].classList.contains('active')){
          menuItems[i].classList.remove('active')
        }
      }
      // add active class to clicked item if not present
      if(!e.currentTarget.parentNode.classList.contains('active')){
        e.currentTarget.parentNode.classList.toggle('active')
      }
      // turn off/on content
      let settingsContent = document.getElementsByClassName('settingsContent')
      for (var i = 0; i < settingsContent.length; i ++) {
        if(settingsContent[i].id != settingSelectionContent){
          settingsContent[i].style.display = "none"
        }
        if(settingsContent[i].id === settingSelectionContent){
          settingsContent[i].style.display = "block"
        }
      }
    },
    saveProfileEdits(){

      this.showSpinner = true

      console.log('this is the currentUserAlias before profile update')

      if(this.newProfileName != this.currentProfileName || 
      this.newProfileLocation != this.currentProfileLocation ||
      this.newProfileBio != this.currentProfileBio){

        let updateProfileEdits = projectFunctions.httpsCallable('updateProfileEdits')

        updateProfileEdits({ 
          profileName: this.newProfileName,
          profileLocation: this.newProfileLocation,
          profileBio: this.newProfileBio
          }).then(result => {

          console.log('cloud updateProfileEdits function is complete: ', result)

          this.currentProfileName = this.newProfileName

          this.currentProfileLocation = this.newProfileLocation

          this.currentProfileBio = this.newProfileBio

          // ISSUE make this work without time delay
          setTimeout( pause => {

            this.showSpinner = false

            //do something here to give confirmation of updates
            this.$emit('notice', 'Your profile information has been updated.')

            // emit event to update userDoc, so new profile information will display on profile page
            this.$emit('updateUsersDoc')     

          }, 500)
          }).catch(error => {
            console.log('error with cloud update function ', error)
            this.$emit('notice', 'There was an error updating your profile.')
          })

      } else {

        this.showSpinner = false
        this.newProfileName = this.currentProfileName
        this.newProfileLocation = this.currentProfileLocation
        this.newProfileBio = this.currentProfileBio
        this.$emit('notice', 'Your profile information was not changed.')

      }

    },
    openLoginModal(){
      if(!this.loginModal){
        this.loginModal = true
      } else {
        this.loginModal = false
      }
    },
    closeLoginModal(){
      this.loginModal = false
    },
    login(){ // ISSUE to change name to reauthorize
      if(this.user.email && this.password){
        projectAuth.signInWithEmailAndPassword(this.user.email, this.password)
        .then(cred => {
          console.log(cred.user)
          this.loginModal = false
          // continue users action befor prompting for password
          if(this.currentAction === 'saveAccountSettings'){
            this.saveAccountSettings()
          } else if(this.currentAction === 'changeUserPassword'){
            this.changeUserPassword()
          } else if(this.currentAction === 'closeAccount'){
            this.closeAccountPartOne(true)
          } else if(this.currentAction === 'closeAccountPartTwo'){
            this.closeAccountPartTwo()
          }
        }).catch(err => {
          this.feedback = err.message
        })
        this.feedback = null
      } else {
        this.feedback = 'Please enter your email and password.'
      }
    },
    saveAccountSettings(){

      console.log('Start saveAccountSettings().')

      // turn on loader animation
      this.showSpinner = true

      console.log('This is the current alias: ' + this.currentUserAlias + '. And this is the new alias: ' + this.newAlias)
      console.log('This is the current email: ' + this.currentUserEmail + '. And this is the new email: ' + this.newEmail)

      if(this.newAlias != this.currentUserAlias){ 
        
        console.log('The alias has been changed, call cloud updateAccountEdits()')

        let updateAccountEdits = projectFunctions.httpsCallable('updateAccountEdits')

        updateAccountEdits({
          newAlias: this.newAlias
        }).then(result => {

          // // turn off loader animation
          // this.showSpinner = false

          // check if error was returned
          if(result.data.error){

            // turn off loader animation
            this.showSpinner = false

            console.log('The returned result.data is: ', result.data)

            this.$emit('notice', result.data.error)

            // if error, reset inputs to existing data

            this.newAlias = this.currentUserAlias
            this.newEmail = this.currentUserEmail

          } else if(result.data === 'account-alias-updated') {

            this.currentUserAlias = this.newAlias // reseting value of alias here stops alias from being updated again if 'currentaction' calls saveAccountSettings

            if(this.newEmail != this.currentUserEmail){

              console.log('The email has been changed, update with auth().')

              this.user.updateEmail(this.newEmail).then(() => {

                var actionCodeSettings = {
                  url: 'https://crittool-dev.web.app/',
                  handleCodeInApp: false
                };

                this.user.sendEmailVerification(actionCodeSettings).then(() => {

                  this.unvalidated = true

                  console.log('The verification email has been sent.')

                }).catch(error => {

                  console.log('There was an error resending the verification email: ', error)

                })

                // turn off loader animation
                this.showSpinner = false

                // unsubscribe from noti listener
                // this.$parent.notifDocRef()

                // emit event to update this.user
                this.$emit('updateUser', 'settings-update')
                  
                this.currentUserAlias = this.newAlias
                this.currentUserEmail = this.newEmail // added in 1/30
                this.currentAction = null

                this.$emit('notice', 'Your username and email have been updated. A verification email has been sent. Please check your email.')

              }).catch(error => {

                console.log('There was an error updating the users email: ', error)

                if(error.code === 'auth/requires-recent-login'){

                  // turn off loader animation
                  this.showSpinner = false

                  // user needs to login again
                  console.log('please enter your password to continue')

                  this.currentAction = 'saveAccountSettings'

                  this.openLoginModal()

                } else {

                  this.newAlias = this.currentUserAlias
                  this.newEmail = this.currentUserEmail

                }

              })

            } else {

              // turn off loader animation
              this.showSpinner = false

              console.log('The email has not changed.')

              // unsubscribe from noti listener
              // this.$parent.notifDocRef()

              // emit event to update this.user
              this.$emit('updateUser', 'settings-update')
                
              this.currentUserAlias = this.newAlias
              // this.currentUserEmail = this.newEmail // added in 1/30
              this.currentAction = null

              this.$emit('notice', 'Your username has been updated.')
              

            }

          }

        }).catch(error => {

          console.log('There was an error with cloud updateAccountEdits function: ', error)

          this.showSpinner = false

          this.newAlias = this.currentUserAlias

          this.$emit('notice', error)

        })

      } else if(this.newEmail != this.currentUserEmail){

        console.log('The email has been changed, update with auth().')

        this.user.updateEmail(this.newEmail).then(() => {

          var actionCodeSettings = {
            url: 'https://crittool-dev.web.app/',
            handleCodeInApp: false
          };

          this.user.sendEmailVerification(actionCodeSettings).then(() => {

            this.unvalidated = true

            console.log('The verification email has been sent.')

          }).catch(error => {

            console.log('There was an error resending the verification email: ', error)

          })

          // turn off loader animation
          this.showSpinner = false

          // emit event to update this.user
          this.$emit('updateUser', 'settings-update')
            
          // this.currentUserAlias = this.newAlias
          this.currentUserEmail = this.newEmail // added in 1/30
          this.currentAction = null

          this.$emit('notice', 'Your email has been updated. A verification email has been sent. Please check your email.')

        }).catch(error => {

          console.log('There was an error updating the users email: ', error)

        // turn off loader animation
          this.showSpinner = false

          if(error.code === 'auth/requires-recent-login'){

            // user needs to login again
            console.log('please enter your password to continue')

            this.currentAction = 'saveAccountSettings'

            this.openLoginModal()

          } else {

            this.newAlias = this.currentUserAlias
            this.newEmail = this.currentUserEmail

          }

        })

      } else {
        // nothing has been changed, but user clicked save
        this.showSpinner = false
        this.newAlias = this.currentUserAlias
        this.newEmail = this.currentUserEmail
        this.$emit('notice', 'Your account settings were not changed.')
      }

    },
    changeUserPassword(){ // NOTE: keep this running on client side (designed to only work on current user)... safer? but maybe better reasons to have on cloud... need to think about this more

      // turn on loader animation
      this.showSpinner = true

      // let user = firebase.auth().currentUser; //removed this line and added this. to suer below
      // console.log('this is the new password: ', this.newPassword)
      
      // ISSUE to see if we can change settings in firestore auth for different types of validation on password
      // in otherwords can we validate req charactersm, req length, etc somewhere off client?

      this.user.updatePassword(this.newPassword).then( () => {
        // Update successful.
        // turn off loader animation
        this.showSpinner = false
        console.log('the password had been changed successfully')
        this.$emit('notice', 'Your password has been changed.')
        this.currentAction = null
      }).catch(error => {
        // An error happened.
        
        console.log('there was an error updating the users password: ', error)
        if(error.code === 'auth/requires-recent-login'){
          // turn off loader animation
          this.showSpinner = false
          // user needs to login again
          console.log('please enter your password to continue')
          this.currentAction = 'changeUserPassword'
          this.openLoginModal()
        } else {
          // turn off loader animation
          this.showSpinner = false
          console.log('there was an error ')
          this.$emit('notice', error.message)
        }
      });
    },
    closeAccountPartOne(reAuthd){

      if(!reAuthd){
        console.log('please enter your password to continue')
        this.currentAction = 'closeAccount'
        // need to reauthenticate the user first
        this.openLoginModal()
      } else {
      
        // turn on loader animation
        this.showSpinner = true

        let deleteAllUserDocs = projectFunctions.httpsCallable('deleteAllUserDocs')
        deleteAllUserDocs({
        }).then(result => {

          console.log('all user docs have been deleted, and user has been disabled: ', result)

          this.showSpinner = false

          // logout the current user, disable doesnt take effect until user has logged out...

          projectAuth.signOut().then(() => {

            console.log('Your account has been deleted.');

            this.$router.push({ name: 'Signup' })

            .then(() => {

              this.$emit('notice', 'Your account has been deleted.')

            })
            .catch(error => {

              console.log('There was an error routing to login after sign out: ', error);

            });

          }).catch(error => {

            console.log('There was an error during sign out: ', error);

          });        
          
        }).catch(error => {
          console.log('there was an error with deleteAllUserDoc() cloud function: ', error)
        })
      }
    },
    clearProfilePhotoPreview(){
      this.files.length = 0
      this.profilePhotoFile = null
      document.getElementById('fileUpload').value = null
      document.getElementById('profilePreview').innerHTML = ''
      // setup and reset image preview
      // let profilePreview = document.getElementById('profilePreview');
      // if (profilePreview.hasChildNodes()){
      //   profilePreview.innerHTML = '';
      // }
    },
    getFilesToPost(e) {
      // reset form input on for repeat input events
      this.files.length = 0
      // this.fileNames.length = 0
      this.profilePhotoFile = e.target.files
      console.log('file names after user selects file but before they are pushed to array ' + this.files + '. And length: ' + this.files.length + '.')
      if(this.profilePhotoFile.length === 1){
        // get file list from input area
        const fileList = this.profilePhotoFile
        // make array from file list and add each file to this.file array with forEach
        Array.from(fileList).forEach((file) => {
          this.files.push(file)
          // this.fileNames.push(file.name)
          console.log('This file was added to the files array: ' + file.name)
          console.log('Now the files array contains: ' + this.files)
        })
        /////////////////////////////////////////
        // validate files here?
        
        // create image previews from file list
        for (var i = 0, f; f = fileList[i]; i++) {
          // Only process image files.
          if (!f.type.match('image.*')) {
            console.log('the file is an image ')
            continue;
          }
          var reader = new FileReader();
          // Closure to capture the file information.
          reader.onload = (function(theFile) {
            return function(e) {
              // Render thumbnail.
              var span = document.createElement('span');
              span.setAttribute("id", "profileImagePreview");
              span.innerHTML = ['<img src="', e.target.result,
                                '" title="', escape(theFile.name), '"/>'].join('');
              document.getElementById('profilePreview').insertBefore(span, null);
            };
          })(f);
          // Read in the image file as a data URL.
          reader.readAsDataURL(f);
          this.feedback = null
          this.hasProfilePreview = true
        } 
      } else {
        
        this.feedback = 'Please select 1 file'
      }
      
    },
    // NOTE if we can rework code to save cleaned images into default firebase storage folder we can save money
    // because free tier quotas to not apply to non-default buckets...
    uploadProfilePhoto(){
      this.showSpinner = true
      if(this.files.length > 0){
       
        let metadata = {
          customMetadata: {
            'user': this.user.uid
          }
        }
        // let storageRef = firebase.app().storage("gs://crittool-raw-profile-images").ref()
        let storageRef = projectStorage.ref()
        // let fileUploadRef = storageRef.child('profile-images/' + this.user.uid + '-profile')
        let fileUploadRef = storageRef.child('raw-profile-images/' + this.user.uid + '-profile')
        const uploadTask = fileUploadRef.put(this.files[0], metadata)
        uploadTask.on('state_changed', (snapshot) => {
          
        }, (error) => {
          // handle errors during file upload
          this.showSpinner = false
          console.log(' .on(state_changed) Error uploading file: ', error)
          this.$emit('notice', 'There was an error uploading your profile picture.')

          // NEED to clear photo preview after unsuccessful profile image upload/change

        }, () => {
          // handle successful uploads on complete
          console.log('upload completed for: ' + this.files[0].name)

          let processProfileImage = projectFunctions.httpsCallable('processProfileImage')
          
          processProfileImage().then(result => {

            console.log('This is the returned value from processProfileImage: ', result)

            // get download url for profile photo

            // firebase.app().storage("gs://crittool-profile-images").ref()
            projectStorage.ref()
            .child('profile-images/' + this.user.uid + '-profile').getDownloadURL()
            .then(url => {
              
              console.log('This is the download url: ', url)

              this.photoURL = url

              // save photo url to auth

              this.user.updateProfile({ 
                photoURL: url
              }).then(() => {

                console.log('this is the users profile image url from user.photoURL: ', this.user.photoURL)

                this.showSpinner = false

                this.$emit('notice', 'Your profile picture has been updated.')

                this.clearProfilePhotoPreview()

                console.log('Emit updateUser event from Settings.vue uploadProfilePhoto().')

                this.$emit('updateUser', 'settings-profile-saved') // ISSUE is this needed here?

              }).catch(error => {

                console.log('There was an error updating the auth profile: ', error)

                this.$emit('notice', 'There was an error updating your profile picture record.')

              })

            }).catch(error => {

              console.log('There was an error getting the download URL: ', error)

              this.$emit('notice', 'There was an error updating your profile picture record.')

            })

          }).catch(error => {

            console.log('There was an error with processProfileImage: ', error)

            this.$emit('notice', 'There was an error updating your profile picture record.')

          })

        })
      }
    }, 
    // ISSUE delete this be done on cloud? should it be? security vs cost? 
    // mal user will know they can manipulate auth profile on client...
    callDeleteProfileImage(){

      this.showSpinner = true
     
        let deleteProfileImage = projectFunctions.httpsCallable('deleteProfileImage')

        deleteProfileImage()
        .then(() => {

          this.user.updateProfile({
        
            photoURL: null
          
          }).then(() => {

            this.photoURL = null

            this.clearProfilePhotoPreview()

            this.showSpinner = false

            this.$emit('notice', 'Your profile picture has been deleted.')

            // console.log('the existing profile photo was deleted successfully')

          }).catch(error => {

            // console.log('There was an error updating the the user photoURL: ', error)

            this.showSpinner = false

          })
          
        }).catch(error => {

          // console.log('There was an error updating profile picture record on user doc: ', error)

          this.showSpinner = false

          this.$emit('notice', 'There was an error deleting your profile picture.')

        })
      
    },
    validateDuringInput(content){

      console.log('Start validate() in Settings.')

      if(content === 'password'){

        console.log('Start validation on password edits.')

        let password = document.getElementById('editNewPassword').value

        if(password.length >= 6){

          console.log('The password is valid.')

          if(this.notice === 'Password must be longer.'){

            this.$emit('notice', '')

          }
          
          this.pwUnvalidated = false

        } else {

          console.log('The password is no valid yet.')

          this.pwUnvalidated = true

        }

      } else if(content === 'settings'){

        console.log('Start validation on account settings edits.')

        let alias = document.getElementById('editAccountUsername').value

        let email = document.getElementById('editAccountEmail').value

        // check if userName contains any special characters

        if(alias.match(/^[a-zA-Z0-9]+$/g)){

          console.log('The alias chars are valid.')

          if(this.notice === 'Username may not contain special characters.'){

            this.$emit('notice', '')

          }

          // check if email contains any special characters

          if(email.match(/^[A-Z0-9@._%+-]+$/gi)){

            console.log('The email chars are valid.')

            if(this.notice === 'Email may not contain special characters.'){

              this.$emit('notice', '')

            }

          } else {

            this.asUnvalidated = true

            if(email !== null && email !== ''){

              console.log('The email chars are invalid.')

              this.$emit('notice', 'Email may not contain special characters.')

            } 

          }

          // check lengths as needed here.

          if(alias.length >= 3 && alias.length <= 24){

            console.log('The username is valid.')

            if(this.notice === 'Username must be longer.' || this.notice === 'Username must be shorter.'){

              this.$emit('notice', '')

            }

            if(email.length >= 6 && email.length <= 254 && email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gi)){

              console.log('The email is valid.')

              // ISSUE: where do we add this notice: 'Your profile information has been updated.'

              if(
                this.notice === 'Email must be longer.' || 
                this.notice === 'Email must be shorter.' ||
                this.notice === 'Email is not formatted correctly.' ||
                this.notice === '' ||
                this.notice === null
              ){

                this.$emit('notice', '')

                this.asUnvalidated = false

              }

            } else {

              console.log('The email length and or format is invalid.')

              this.asUnvalidated = true

            }

          } else {

            console.log('The username length is invalid.')

            this.asUnvalidated = true

          }

          
        } else {

          this.asUnvalidated = true

          if(alias !== null && alias !== ''){

            console.log('The alias chars are invalid.')

            this.$emit('notice', 'Username may not contain special characters.')

          } 

        }

        // if(alias.length >= 1 && email.length >= 1){

        //   this.asUnvalidated = false

        // } else {

        //   this.asUnvalidated = true

        // }

      } else if(content === 'profile'){

        console.log('Start validation on profile edits.')

        let name = document.getElementById('editProfileName').value

        let location = document.getElementById('editProfileLocation').value

        let bio = document.getElementById('editProfileBio').value

        const checkProfileInputLengths = () => {

          if(
            name.length >= 3 && 
            name.length <= 24
          ){

            console.log('The name length is valid.');

            if(this.notice === 'Name must be longer.' || this.notice === 'Name must be shorter.'){

              this.$emit('notice', '');

            };

            if(
              location.length >= 3 &&                        // location must be min 3 chars
              location.length <= 36                          // location must be max 36 chars
            ){

              console.log('The location length is valid.');

              if(this.notice === 'Location must be longer.' || this.notice === 'Location must be shorter.'){

                this.$emit('notice', '');

              };

              if(
                bio.length >= 3 &&
                bio.length <= 150
              ){

                console.log('The bio length is valid.');

                if(
                  // this.notice !== 'Location may not contain special characters.' ||
                  this.notice === 'Bio must be longer.' || 
                  this.notice === 'Bio must be shorter.' ||
                  this.notice === '' ||
                  this.notice === null
                ){

                  this.$emit('notice', '');

                  // set profile form unvalidated to false, now validated

                  this.proUnvalidated = false;

                };

              } else {

                console.log('The bio length is invalid.');

                this.proUnvalidated = true;

              }

            } else {

              console.log('The location length is invalid.');

              this.proUnvalidated = true;

            };

          } else {

            console.log('The name length is invalid.');

            this.proUnvalidated = true;

          };

        };

        // clear length notices if they have been resolved

        if(this.notice === 'Name must be longer.'){

          if(
            name.length >= 3 &&                        // name must be min 3 chars
            name.length <= 24                          // name must be max 24 chars
          ){

            console.log('The name length is valid.')

            if(this.notice === 'Name must be longer.' || this.notice === 'Name must be shorter.'){

              this.$emit('notice', '')

            }

          }

        } else if(this.notice === 'Location must be longer.'){

          if(
            location.length >= 3 &&                        // location must be min 3 chars
            location.length <= 36                          // location must be max 36 chars
          ){

            console.log('The location length is valid.')

            if(this.notice === 'Location must be longer.' || this.notice === 'Location must be shorter.'){

              this.$emit('notice', '')

            }

          }

        } else if(this.notice === 'Bio must be longer.'){

          if(
            bio.length >= 3 &&                        // bio must be min 3 chars
            bio.length <= 150                         // bio must be max 150 chars
          ){

            console.log('The bio length is valid.')

            if(this.notice === 'Bio must be longer.' || this.notice === 'Bio must be shorter.'){

              this.$emit('notice', '')

            }

          }

        }

        // check if name contains any special characters

        if(name.match(/^[a-zA-Z0-9 ]+$/g)){

          console.log('The name chars are valid.')

          if(this.notice === 'Name may not contain special characters.'){

            this.$emit('notice', '')

            console.log('This is the notice now: ', this.notice)

          }

          // check if location contains any special characters

          if(location.match(/^[a-zA-Z0-9 \,.-]+$/g)){

            console.log('The location chars are valid.')

            if(this.notice === 'Location may not contain special characters.'){

              this.$emit('notice', '')

            }

            // check if bio contains any special characters

            if(bio.match(/^[a-zA-Z0-9 \?!,.%-]+$/g)){

              console.log('The bio chars are valid.')

              if(this.notice === 'Bio may not contain special characters.'){

                this.$emit('notice', '')

              }

              // all inputs have been checked for special characters, now check lengths

              checkProfileInputLengths();

            } else {

              this.proUnvalidated = true

              if(bio !== null && bio !== ''){

                console.log('The bio chars are invalid.')

                this.$emit('notice', 'Bio may not contain special characters.')

              } 

            }

          } else {

            this.proUnvalidated = true

            if(location !== null && location !== ''){

              console.log('The location chars are invalid.')

              this.$emit('notice', 'Location may not contain special characters.')

            } 

          }
          
          console.log('This is the current notice: ', this.notice)

        } else {

          this.proUnvalidated = true

          if(name !== null && name !== ''){

            console.log('The name chars are invalid.')

            this.$emit('notice', 'Name may not contain special characters.')

          } 

        }

      }

    },
    validateAfterInput(content){

      console.log('Start validation on profile edits after input.')

      console.log('This is the notice: ', this.notice)
      
      if(content === 'profile'){

        let name = document.getElementById('editProfileName').value

        let location = document.getElementById('editProfileLocation').value

        let bio = document.getElementById('editProfileBio').value
        
        if(name.length < 3){

          console.log('The name is too short.')

          this.$emit('notice', 'Name must be longer.')

        } else if(name.length > 24){

          console.log('The name is too long.')

          this.$emit('notice', 'Name must be shorter.')

        } else if(location.length < 3){

          console.log('The location is too short.')

          this.$emit('notice', 'Location must be longer.')

        } else if(location.length > 36){

          console.log('The location is too long.')

          this.$emit('notice', 'Location must be shorter.')

        } else if(bio.length < 3){

          console.log('The bio is too short.')

          this.$emit('notice', 'Bio must be longer.')

        } else if(bio.length > 150){

          console.log('The bio is too long.')

          this.$emit('notice', 'Bio must be shorter.')

        }

      } else if(content === 'settings'){

        console.log('Start validation on account settings edits after input.')

        let alias = document.getElementById('editAccountUsername').value

        let email = document.getElementById('editAccountEmail').value

        if(email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gi)){

          console.log('The email chars are valid.')

          if(alias.length < 3){

            console.log('The alias is too short.')

            this.$emit('notice', 'Username must be longer.')

          } else if(alias.length > 24){

            console.log('The alias is too long.')

            this.$emit('notice', 'Username must be shorter.')

          } else if(email.length < 6){

            console.log('The email is too short.')

            this.$emit('notice', 'Email must be longer.')

          } else if(email.length > 254){

            console.log('The email is too long.')

            this.$emit('notice', 'Email must be shorter.')

          }

        } else {

          this.asUnvalidated = true

          if(email !== null && email !== ''){

            console.log('The email chars are invalid.')

            this.$emit('notice', 'Email is not formatted correctly.')

          } 

        }

      } else if(content === 'password'){

        console.log('Start validation on password edits after input.')

        let password = document.getElementById('editNewPassword').value

        if(password.length < 6){

          console.log('The password is too short.')

          this.$emit('notice', 'Password must be longer.')

        } 

      }

    }

  },
  created(){

      console.log('Start created() in Settings.vue')

      // reset currentAction, used to continue email/password updates after reauth of user

      this.currentAction = null

      console.log('Emit updateUser event from Settings.vue created hook.')

      this.$emit('updateUser', 'settings-created')

      console.log('This is the user prop passed from root component: ', this.user)

      console.log('This is the userDoc prop passed from root component: ', this.usersDoc)

      console.log('this is the user displayName: ', this.user.displayName)

      this.currentUserAlias = this.user.displayName
      this.currentUserEmail = this.user.email

      this.newAlias = this.currentUserAlias
      this.newEmail = this.currentUserEmail

      if(this.user.photoURL){
        this.photoURL = this.user.photoURL
      }
  
      this.currentProfileName = this.usersDoc.name
      this.currentProfileLocation = this.usersDoc.location
      this.currentProfileBio = this.usersDoc.bio

      this.newProfileName = this.currentProfileName
      this.newProfileLocation = this.currentProfileLocation
      this.newProfileBio = this.currentProfileBio

      console.log('this is usersDoc passed as prop from root: ', this.usersDoc)

    }, 
    unmounted() {

      console.log('Start unmounted() in Settings.vue')

    }
}
</script>

<style>


/* ---------- START SETTINGS HEADER STYLING ---------- */
#settings #settingsHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  margin-top: 1em;
}
#settings #settingsHeaderContainer #settingsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#settings #settingsHeaderContainer #settingsHeader h1 {
  display: inline;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
#settings #settingsHeaderContainer #settingsHeader h2 {
  display: inline;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
#settings #settingsHeaderContainer #settingsHeader i {
  color: #aaa;
  font-size: 48px;
}
#settings #settingsHeaderContainer #settingsHeader img {
  clip-path: circle(42%);
  height: 48px;
  margin-right: 10px;
  width: auto;
}
/* ---------- END SETTINGS HEADER STYLING ---------- */


/* ---------- START SETTINGS BODY STYLING ---------- */
#settings #settingsBody {
  display: flex;
  flex-direction: column;
}
/* ---------- END SETTINGS BODY STYLING ---------- */


/* ---------- START SETTINGS MENU STYLING ---------- */
#settings #settingsMenu {
  width: 100%;
}
#settings #settingsMenu ul {
  border: 1px solid #ddd;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 0;
}
#settings #settingsMenu ul li.settingsMenuItem {
  border-bottom: 1px solid #ddd;
  display: none;
  padding: 10px 16px;
}
#settings #settingsMenu.open ul li.settingsMenuItem {
  display: block;
}
#settings #settingsMenu ul li.settingsMenuItem.active {
  border-bottom: none;
  display: block;
  font-weight: 700;
}
#settings #settingsMenu.open ul li.settingsMenuItem.active {
  border-bottom: 1px solid #ddd;
}
#settings #settingsMenu ul li.settingsMenuItem:last-of-type {
  border-bottom: none;
}
#settings #settingsMenu ul li span {
  display: block;
  width: 100%;
}
/* ---------- END SETTINGS MENU STYLING ---------- */


/* ---------- START SETTINGS STAGE STYLING ---------- */
#settings #settingsBody #settingsStage {
  margin-bottom: 3em;
  width: 100%;
}
#settings #settingsBody #settingsStage .settingsContent {
  display: none;
}
#settings #settingsBody #settingsStage .settingsContent.active {
  display: block;
}
/* ---------- END SETTINGS STAGE STYLING ---------- */


/* ---------- START EDIT PROFILE IMAGE STYLING ---------- */
#settings #settingsBody #settingsStage #editProfileContent #editProfileImage {
  display: flex;
  align-items: center;
}
#settings #settingsBody #settingsStage #editProfileContent #editProfileImage #profileImageStage {
  width: 90px;
  height: 80px;
}
#settings #settingsBody #settingsStage #editProfileContent #editProfileImage #profileImageStage #profilePreview {
  z-index: 3333;
  position: absolute;
}
#settings #settingsBody #settingsStage #editProfileContent #editProfileImage #profileImageStage #profilePreview #profileImagePreview img {
  clip-path: circle(42%);
  height: 80px;
  margin-right: 10px;
  width: auto;
}
#settings #settingsBody #settingsStage #editProfileContent #editProfileImage #profileImageStage i {
  color: #aaa;
  font-size: 80px;
  margin-right: 10px;
  position: absolute;
}
#settings #settingsBody #settingsStage #editProfileContent #editProfileImage #profileImageStage #profileImage {
  position: absolute;
  z-index: 2222;
}
#settings #settingsBody #settingsStage #editProfileContent #editProfileImage #profileImageStage #profileImage img {
  clip-path: circle(42%);
  height: 80px;
  margin-right: 10px;
  width: auto;
}
#settings #settingsBody #settingsStage #editProfileContent #editProfileImage #editProfileImageButtons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100px;
  justify-content: space-between;
  max-width: 250px;
  width: 66%;
}
#settings #settingsBody #settingsStage #editProfileContent #editProfileImage #editProfileImageButtons #fileBrowseLink {
  font-weight: 400;
  margin-right: 1em;
}
#settings #settingsBody #settingsStage #editProfileContent #editProfileImage #fileUpload {
  display: none;
}
/* ---------- END EDIT PROFILE IMAGE STYLING ---------- */


/* ---------- START ACCOUNT SETTINGS STYLING ---------- */
#settings #settingsBody #settingsStage #editAccountContent #editAccountSettings #closeAccount {
  display: block;
  margin: 6em auto 0;
}
/* ---------- END ACCOUNT SETTINGS STYLING ---------- */


/* ---------- START LOGIN MODAL STYLING ---------- */
#settings #loginModal {
  overflow-y: scroll;
  position: fixed;
  opacity: 0;
  background-color: rgba(25, 27, 64, .97);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  transition: .3s ease-out;
  visibility: hidden;
}
#settings #loginModal.loginModalOn {
  opacity: 1;
  transition: .3s ease-out;
  visibility: visible;
}
#settings #loginModal #loginModalContent {
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: .5rem 0 1rem 0;
  max-width: 300px;
  padding: 1.75rem;
  width: 100%;
}
#settings #loginModal #loginModalContent .btn {
  width: 100%;
}
/* ---------- END LOGIN MODAL STYLING ---------- */


@media only screen and (min-width: 901px){
  #settings #settingsMenu {
    width: 20%;
  }
  #settings #settingsMenu ul {
    border: none;
    border-radius: 0;
  }
  #settings #settingsMenu ul li.settingsMenuItem {
    border: none;
    border-bottom: none;
    display: block;
  }
  #settings #settingsMenu.open ul li.settingsMenuItem.active {
    border-bottom: none
  }
  #settings #settingsBody {
    flex-direction: row;
  }
  #settings #settingsBody #settingsStage {
    width: 80%;
  }
  #settings #settingsBody #settingsStage #editAccountContent #editAccountSettings #closeAccount {
    float: right;
    margin: 0;
  }
}


</style>